import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import BloomPageStyle from "./BloomPageStyle";

// sections for this page
import SectionsHeader from "components/SectionsHeader";
import CustomFooter from "components/CustomFooter/CustomFooter";

// images
import bgImg from "assets/img/Calisto-320.jpg";
import womanImg from "assets/img/ARYinv.png";
import bloomLogo from "assets/img/BloomLogo.png";
import womanImg2 from "assets/img/DSC00167.jpg";

const useStyles = makeStyles(BloomPageStyle);

const BloomPage = (props) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [serverResponse, setServerResponse] = useState({
    status: "success",
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  useEffect(() => {
    if (props.history.location.state) {
      setServerResponse({
        status: "success",
        message: props.history.location.state.feedback,
      });
      setOpenSnack(true);
    }
  }, [props.history.location.state]);

  const classes = useStyles();
  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      <SectionsHeader whiteText={true} />
      <div className={classes.headerImgContainer}>
        <GridContainer
          justify="center"
          className={classes.pageHeader}
          style={{ backgroundImage: `url("${bgImg}")` }}
        >
          <GridItem className={classes.buttonContainer} xs={12} sm={12} md={5}>
            <Button className={classes.signUpButton} size="lg">
              <Link to="/calendar/2">
                <strong>INSCRIPCIÓN</strong>
              </Link>
            </Button>
            <img alt="" className={classes.womanImg} src={womanImg} />
            <span className={classes.letterB}>b</span>
          </GridItem>
        </GridContainer>
        <GridContainer
          className={classes.bloomTextLogoContainer}
          justify="center"
        >
          <GridItem
            xs={12}
            sm={12}
            md={5}
            className={classes.bloomLogoContainer}
          >
            <img
              alt="bloom logo"
              className={classes.bloomLogo}
              src={bloomLogo}
            />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={5}
            className={classes.bloomTextcontainer}
          >
            <p className={classes.bloomText}>
              Bloom es un estudio fitness con un alto compromiso social, ya que
              estamos convencidos de los beneficios que aporta a la comunidad la
              práctica de actividades de ejercicio y de reacreación, ambas
              teniendo gran impacto para mejorar el desarrollo personal y de
              salud.
            </p>
          </GridItem>
        </GridContainer>
      </div>

      <div className={classes.img2Container}>
        <GridContainer
          justify="center"
          className={classes.image2}
          style={{ backgroundImage: `url("${womanImg2}")` }}
        >
          <div className={classes.mission}>
            <p className={classes.bloomSubtitle}>misión</p>
            <p className={classes.missionVisionText}>
              Brindar clases fitness innovadoras y de excelencia que ayuden a
              motivar a las personas que buscan una mejora integral, a lograr
              sus objetivos.
            </p>
          </div>
          <div className={classes.vision}>
            <p className={classes.bloomSubtitle}>visión</p>
            <p className={classes.missionVisionText}>
              Ser un estudio líder en el área fitness en Querétaro, brindando
              bienestar y métodos de calidad a nuestros clientes.
            </p>
          </div>
        </GridContainer>
      </div>

      <CustomFooter />
    </>
  );
};

BloomPage.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        feedback: PropTypes.string,
      }),
    }),
  }),
};

export default BloomPage;
