import React, { useContext } from "react";
import { Card, CardContent, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import {
  secondaryColor,
  bloomColor,
  calistoColor,
} from "assets/jss/material-kit-pro-react";
import Button from "components/CustomButtons/Button.js";
import SessionContext from "contexts/SessionContext";
import { Link } from "react-router-dom";
import { isLoggedIn } from "utils/session-utils";
import CustomCurrency from "components/CustomCurrency/CustomCurrency";

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(120),
    borderStyle: "solid",
    borderColor: "black",
    background: "transparent",
    borderRadius: 0,
    width: theme.spacing(60),
    display: "flex",
    justifyContent: "center",
    overflow: "visible",
    position: "relative",
    "@media (max-width: 450px)": {
      width: theme.spacing(50),
    },
  },
  title: {
    fontFamily: "HugolersStylishRegular",
    fontSize: "2.5rem",
    letterSpacing: `${theme.spacing(1.5)}px !important`,
    forntWeight: "900",
  },
  image: {
    width: theme.spacing(70),
    maxHeight: theme.spacing(48),
    objectFit: "cover",
    objectPosition: "50% 20%",
    position: "absolute",
    right: `${theme.spacing(3)}px`,
    top: `${theme.spacing(13)}px`,
    "@media (max-width: 450px)": {
      width: theme.spacing(60),
      right: theme.spacing(-3),
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  priceContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: theme.spacing(26),
  },
  price: {
    fontFamily: "HugolersStylishRegular",
    fontSize: "5rem",
    position: "absolute",

    fontWeight: 900,
    zIndex: -1,
    "@media (max-width: 450px)": {
      letterSpacing: `${theme.spacing(2)}px !important`,
    },
  },
  priceSpacing: {
    letterSpacing: `${theme.spacing(4.5)}px !important`,
  },
  calistoPrice: {
    color: calistoColor,
  },
  bloomPrice: {
    color: bloomColor,
  },
  text: {
    fontFamily: "HugolersStylishRegular",
    fontSize: "2rem",
    marginBottom: theme.spacing(10),
  },
  button: {
    fontFamily: "AvenirNextCondensed",
    fontWeight: 400,
    fontSize: "1.2rem",
    letterSpacing: `${theme.spacing(2)}px !important`,
    color: secondaryColor[0],
    width: "10rem",
    padding: `${theme.spacing(1)}px`,
    backgroundColor: "#f5f5f5",
    // zIndex: "40",
    "& a": {
      color: secondaryColor[0],
    },
    "&:hover": {
      "& a": {
        color: "white",
      },
    },
  },
}));

const MembershipCard = ({
  title,
  image,
  price,
  lessonNumber,
  packageId,
  brand,
}) => {
  const classes = useStyles();
  const { currentUser } = useContext(SessionContext);
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.contentContainer}>
        <p className={classes.title}>{title}</p>
        <img alt="" src={image} className={classes.image} />
        <div className={classes.priceContainer}>
          {lessonNumber === "Ilimitado" && <p className={classes.text}></p>}
          {lessonNumber !== "Ilimitado" && (
            <p className={classes.text}>{`${lessonNumber} ${
              lessonNumber === "1" ? "clase" : "clases"
            }`}</p>
          )}
          <p
            className={`${classes.price} ${
              brand.toLowerCase() === "calisto"
                ? classes.calistoPrice
                : classes.bloomPrice
            } `}
          >
            <CustomCurrency quantity={price} className={classes.priceSpacing} />
          </p>
          {brand.toLowerCase() === "bloom" && (
            <Button className={classes.button} size="lg">
              <Link
                to={{
                  pathname: isLoggedIn(currentUser)
                    ? `/pago/${brand.toLowerCase()}/${packageId}`
                    : `/signup`,
                  query: { price },
                }}
              >
                <strong>INSCRIPCIÓN</strong>
              </Link>
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

MembershipCard.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.string,
  lessonNumber: PropTypes.string,
  packageId: PropTypes.number,
  brand: PropTypes.string,
};

export default MembershipCard;
