import React, { useEffect, useState, useContext } from "react";
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import Page from "components/Page";
import MonthlyClasses from "./MonthlyClasses";
import CustomersByDay from "./CustomersByDay";
import InscriptionsByInstructor from "./InscriptionsByInstructor";
import AttendancePercentage from "./AttendancePercentage";
import TotalCustomers from "./TotalCustomers";
import CustomersBySex from "./CustomersBySex";
import TotalInstructors from "./TotalInstructors";
import CustomersByAge from "./CustomersByAge";
import reportsService from "services/reports.service";
import LoadingContext from "contexts/LoadingContext";
import Snackbar from "@material-ui/core/Snackbar";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { Alert, AlertTitle } from "@material-ui/lab";
import ActionButton from "components/CustomButtons/ActionButton";
import fileSaver from "file-saver";
import b64toBlob from "utils/b64toBlob";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  exportButton: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
}));

const ReportsPage = () => {
  const classes = useStyles();
  const { setLoading } = useContext(LoadingContext);
  const [openSnack, setOpenSnack] = useState(false);
  const [serverResponse, setServerResponse] = useState({
    status: "success",
    message: "",
  });

  const [reportsData, setReportsData] = useState({
    lessons: {},
    customersCount: {},
    instructorsCount: 0,
    attendancePercentage: 0,
    sexesCount: {},
    agesCount: {},
    customersAvgDay: {},
    instructorsInscriptions: [],
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const exportReports = async () => {
    const { data: fileData } = await reportsService.exportReports();
    const blob = b64toBlob(fileData);
    fileSaver.saveAs(blob, "Reports.xlsx");
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const [
          { data: lessonsRes },
          { data: customersCountRes },
          { data: instructorsCountRes },
          { data: attendancePercentageRes },
          { data: sexesCountRes },
          { data: agesCountRes },
          { data: customersAvgDayRes },
          { data: instructorsInscriptionsRes },
        ] = await Promise.all([
          reportsService.getClassesByPeriod(),
          reportsService.getCustomersCount(),
          reportsService.getInstructorsCount(),
          reportsService.getAttendancePercentage(),
          reportsService.getSexesCount(),
          reportsService.getAgesCount(),
          reportsService.getCustomersAvgPerDay(),
          reportsService.getInstructorsInscriptions(),
        ]);
        setReportsData({
          lessons: lessonsRes,
          customersCount: customersCountRes,
          instructorsCount: instructorsCountRes,
          attendancePercentage: Number(attendancePercentageRes),
          sexesCount: sexesCountRes,
          agesCount: agesCountRes,
          customersAvgDay: customersAvgDayRes,
          instructorsInscriptions: instructorsInscriptionsRes,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setServerResponse({
          status: "error",
          message: "Ocurrió un error al generar los reportes",
        });
        setOpenSnack(true);
      }
    }
    fetchData();
  }, [setLoading]);

  return (
    <Page className={classes.root} title="Dashboard">
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      <Box
        display="flex"
        justifyContent="flex-end"
        className={classes.exportButton}
      >
        <ActionButton
          action="trigger"
          icon={<CloudDownloadIcon />}
          func={exportReports}
        />
      </Box>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <MonthlyClasses lessons={reportsData.lessons} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers customersCount={reportsData.customersCount} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalInstructors instructorsCount={reportsData.instructorsCount} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <AttendancePercentage
              attendancePercentage={reportsData.attendancePercentage}
            />
          </Grid>
          <Grid item lg={8} md={12} xl={9} xs={12}>
            <CustomersByDay customersAvgDay={reportsData.customersAvgDay} />
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <CustomersBySex sexesCount={reportsData.sexesCount} />
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <CustomersByAge agesCount={reportsData.agesCount} />
          </Grid>
          <Grid item lg={8} md={12} xl={9} xs={12}>
            <InscriptionsByInstructor
              instructorsInscriptions={reportsData.instructorsInscriptions}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ReportsPage;
