import React, { useState, useContext } from "react";
import { Container, makeStyles } from "@material-ui/core";
import Page from "components/Page";
import { Card, CardContent, CardHeader } from "@material-ui/core";

import UserForm from "../Form";
import userService from "services/user.service";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import LoadingContext from "contexts/LoadingContext";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  selectGridItem: {
    marginTop: "10px",
  },
  dateGridItem: { marginTop: "20px" },
}));

function NewUserView() {
  const { setLoading } = useContext(LoadingContext);
  const history = useHistory();
  const classes = useStyles();
  const [user, setUser] = useState({
    name: "",
    lastName: "",
    sex: "",
    birthday: moment(),
    email: "",
    phone: "",
    roleId: 1,
    active: true,
    observations: "",
    password: "",
    passwordConfirm: "",
  });

  const [openSnack, setOpenSnack] = React.useState(false);

  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await userService.createUser(user);
      setLoading(false);
      history.push({
        pathname: "/admin/users",
        state: { feedback: "Usuario creado correctamente" },
      });
    } catch (error) {
      const {
        response: {
          data: { error: errorData },
        },
      } = error;
      setLoading(false);
      setServerResponse({
        status: "error",
        message: `Ocurrió un error al crear el usuario: ${errorData}`,
      });
      setOpenSnack(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Page className={classes.root} title="Usuarios">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={serverResponse.status}>
            <AlertTitle>
              {serverResponse.status === "success" ? "Éxito" : "Error"}
            </AlertTitle>
            {serverResponse.message}
          </Alert>
        </Snackbar>
        <Card>
          <CardHeader title="Crear Usuario"></CardHeader>
          <CardContent>
            <UserForm
              user={user}
              setUser={setUser}
              submitForm={submitForm}
              mode="create"
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default NewUserView;
