import React from "react";
import CurrencyFormat from "react-currency-format";
import PropTypes from "prop-types";

function CustomCurrency({ quantity, className }) {
  // const parsedQuantity =
  // const decimals[ num % 1 != 0
  return (
    <CurrencyFormat
      value={Number(quantity)}
      displayType="text"
      thousandSeparator={true}
      prefix={"$"}
      decimalScale={2}
      renderText={(formattedValue) => (
        <span className={className}>{formattedValue}</span>
      )}
    />
  );
}

CustomCurrency.propTypes = {
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.any,
};

export default CustomCurrency;
