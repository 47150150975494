import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({}));

export default function CustomSelect(props) {
  const { labelText, id, inputProps, options } = props;
  const classStyles = useStyles();
  return (
    <FormControl fullWidth className={classStyles.selectFormControl}>
      <InputLabel htmlFor={`${id}-select`} className={classStyles.selectLabel}>
        {labelText}
      </InputLabel>
      <Select
        id={id}
        MenuProps={{
          className: classStyles.selectMenu,
        }}
        classes={{
          select: classStyles.select,
        }}
        inputProps={inputProps}
      >
        <MenuItem
          disabled
          classes={{
            root: classStyles.selectMenuItem,
          }}
        >
          {labelText}
        </MenuItem>
        {options &&
          options.map((option, index) => (
            <MenuItem
              classes={{
                root: classStyles.selectMenuItem,
                selected: classStyles.selectMenuItemSelected,
              }}
              key={index}
              value={option.value}
            >
              {option.text}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

CustomSelect.propTypes = {
  labelText: PropTypes.string,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  options: PropTypes.array,
};
