import axios from "axios";

class UserService {
  getUsers() {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get("/api/users", { headers: { Authorization: token } });
  }

  getAdmins(page, size, search) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(
      `/api/users/admins?page=${page}&size=${size}&search=${search}`,
      { headers: { Authorization: token } }
    );
  }

  getClients(page, size, search) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(
      `/api/users/clients?page=${page}&size=${size}&search=${search}`,
      { headers: { Authorization: token } }
    );
  }

  createUser(userParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.post("/api/users/create", userParams, {
      headers: { Authorization: token },
    });
  }

  registerUser(userParams) {
    return axios.post("/api/users/register", userParams);
  }

  updateUser(id, userParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.put(`/api/users/${id}`, userParams, {
      headers: { Authorization: token },
    });
  }

  getUser(id) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/users/${id}`, {
      headers: { Authorization: token },
    });
  }

  deleteUser(id) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.delete(`/api/users/${id}`, {
      headers: { Authorization: token },
    });
  }

  verifyUser(id, verificationToken) {
    return axios.put(`/api/users/verify/${id}`, { verificationToken });
  }

  sendRecoverEmail(email) {
    return axios.get(`/api/users/send-recover/${email}`);
  }

  resetPassword(email, password, resetToken) {
    return axios.put(`/api/users/reset-password/${email}`, {
      password,
      resetToken,
    });
  }

  updatePassword(id, password) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.put(
      `/api/users/update-password/${id}`,
      { password },
      {
        headers: { Authorization: token },
      }
    );
  }

  getUserClasses(id, classType, limit, page) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(
      `/api/users/${id}/classes?type=${classType}&limit=${limit}&page=${page}`,
      { headers: { Authorization: token } }
    );
  }

  updateAvailableClasses(id, brands) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.put(
      `/api/users/update-available-classes/${id}`,
      { brands },
      {
        headers: { Authorization: token },
      }
    );
  }
}

export default new UserService();
