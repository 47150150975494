import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { redChartColor } from "assets/jss/material-kit-pro-react.js";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: redChartColor,
    height: 56,
    width: 56,
  },
  differenceIcon: {
    color: redChartColor,
  },
  differenceValue: {
    color: redChartColor,
    marginRight: theme.spacing(1),
  },
}));

const MonthlyClasses = ({ className, lessons, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={2}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              CLASES DE ESTE MES
            </Typography>
            <br></br>
            {Object.keys(lessons).map((key) => (
              <Typography color="textPrimary" variant="body1" key={key}>
                {key}: {lessons[key].length}
              </Typography>
            ))}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <CalendarTodayIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

MonthlyClasses.propTypes = {
  className: PropTypes.string,
  lessons: PropTypes.object,
};

export default MonthlyClasses;
