/*eslint-disable*/
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
// react component for creating beautiful carousel
// @material-ui/core components

// @material-ui/icons
// core components

import homePageStyle from "./homePageStyles";

// sections for this page
import SectionsHeader from "components/SectionsHeader";
import CustomFooter from "components/CustomFooter/CustomFooter";

// images
import bgImg from "assets/img/Calisto-293.jpg";
import instructorsImg from "assets/img/Calisto-263.jpg";
import calistoLogo from "assets/img/CalistoLogo.png";
import womanImage from "assets/img/mujer-01.png";
import calistoBrandImg from "assets/img/Calisto-230.jpg";
import communityLogo from "assets/img/CommunityLogoCropped.png";
import bloomBrandImg from "assets/img/bloomBrandImg.jpg";
import bloomLogo from "assets/img/BloomLogo.png";
import greenBrandImg from "assets/img/greenBrand.jpg";
import greenLogo from "assets/img/GreenLogo.png";
import footerImg from "assets/img/Calisto-279.jpg";

const useStyles = makeStyles(homePageStyle);

export default function HomePage(props) {
  const [openSnack, setOpenSnack] = useState(false);
  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });

  const snackbarPosition = () => {
    return window.innerWidth < 960 ? "bottom" : "top";
  };

  useEffect(() => {
    if (props.history.location.state) {
      setServerResponse({
        status: "success",
        message: props.history.location.state.feedback,
      });
      setOpenSnack(true);
    }
  }, [props.history.location.state]);

  const classes = useStyles();
  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSnack(false);
        }}
        anchorOrigin={{ vertical: snackbarPosition(), horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      <SectionsHeader />
      <div
        className={classes.pageHeader}
        style={{ backgroundImage: `url("${bgImg}")` }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} style={{ textAlign: "center" }}>
              <h1 className={`${classes.title} ${classes.pageTitle}`}>
                bienvenid@
              </h1>
              <h4>
                <i>dale inicio a una vida en movimiento</i>
              </h4>
              <br />
              <Link to="/brands">
                <Button className={classes.moveButton} size="lg" rel="">
                  <strong>RESERVA</strong>
                </Button>
              </Link>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.yellowShapeGradient}></div>
        <div className={classes.yellowShape}></div>
      </div>
      <div className={classes.instructorsImgContainer}>
        <img className={classes.instructorsImg} src={instructorsImg} />
        <img className={classes.floatingLogo} src={communityLogo} />
      </div>
      <div className={classes.womanContainer}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={7}>
            <img className={classes.womanImg} src={womanImage} />
            <span className={classes.letterC}>c</span>
          </GridItem>

          <GridItem
            xs={12}
            sm={12}
            md={5}
            className={classes.womanTextContainer}
          >
            <p className={classes.womanText}></p>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.brandsContainer}>
        <GridContainer alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <img className={classes.brandsImg} src={calistoBrandImg} />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
            <img className={classes.brandsLogo} src={calistoLogo} />
            <br />
            <Link to="/signup">
              <Button color="primary" className={classes.joinButton}>
                <strong>ÚNETE</strong>
              </Button>
            </Link>
          </GridItem>
        </GridContainer>
      </div>

      <div className={classes.brandsContainer}>
        <GridContainer alignItems="center" justify="center">
          <Box clone order={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
            <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
              <img className={classes.brandsLogo} src={bloomLogo} />
              <br />
              <Link to="/signup">
                <Button color="primary" className={classes.joinButton}>
                  <strong>ÚNETE</strong>
                </Button>
              </Link>
            </GridItem>
          </Box>
          <Box clone order={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
            <GridItem xs={12} sm={12} md={6}>
              <img
                className={`${classes.brandsImg} ${classes.brandsImgBloom}`}
                src={bloomBrandImg}
              />
            </GridItem>
          </Box>
        </GridContainer>
      </div>

      <div className={classes.brandsContainer}>
        <GridContainer alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <img className={classes.brandsImg} src={greenBrandImg} />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
            <img className={classes.brandsLogo} src={greenLogo} />
            <br />
            <Link to="/signup">
              <Button color="primary" className={classes.joinButton}>
                <strong>COMPRA</strong>
              </Button>
            </Link>
            <span className={classes.letterG}>g</span>
          </GridItem>
        </GridContainer>
      </div>

      <div className={classes.footerImgContainer}>
        <img className={classes.footerImg} src={footerImg} />
      </div>

      <div className={classNames(classes.main, classes.mainRaised)}></div>
      <CustomFooter />
    </>
  );
}
