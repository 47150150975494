/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import image from "assets/img/Calisto-163.jpg";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import LoadingContext from "contexts/LoadingContext";
import userService from "services/user.service";
import CustomFooter from "components/CustomFooter/CustomFooter";
import SectionsHeader from "components/SectionsHeader";

export default function RecoverAccountPage() {
  const useStyles = makeStyles(loginPageStyle);
  const history = useHistory();
  const { setLoading } = useContext(LoadingContext);
  const [openSnack, setOpenSnack] = useState(false);
  const [email, setEmail] = useState("");
  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await userService.sendRecoverEmail(email);
      setLoading(false);
      history.push({
        pathname: `/email-forgot/${email}`,
      });
    } catch (error) {
      setLoading(false);
      const {
        response: {
          data: { error: errorData },
        },
      } = error;
      let message = "";
      if (errorData.includes("no existe")) {
        message = "No existe un usuario registrado con este correo";
      } else {
        message =
          "Ocurrió un error al enviar el correo de recuperación. Por favor inténtalo de nuevo más tarde";
      }
      setServerResponse({
        status: "error",
        message,
      });
      setOpenSnack(true);
    }
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      <SectionsHeader whiteText />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <form className={classes.form} onSubmit={onFormSubmit}>
                  <CardHeader
                    color="primary"
                    signup
                    className={classes.cardHeader}
                  >
                    <h3 className={classes.cardTitle}>Contraseña olvidada</h3>
                  </CardHeader>
                  <CardBody signup style={{ textAlign: "center" }}>
                    <br></br>
                    <h4>
                      Ingresa el correo electrónico con el que te registraste:
                    </h4>
                    <CustomInput
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Email",
                        type: "email",
                        required: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        value: email,
                        name: "email",
                        onChange: handleInputChange,
                      }}
                    />
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button simple color="primary" size="lg" type="submit">
                      Enviar
                    </Button>
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
