import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { Switch, BrowserRouter } from "react-router-dom";
import UsersIndex from "views/Users/UsersIndex";
import NewUserView from "views/Users/NewUser";
import EditUserView from "views/Users/EditUser";
import ClassesIndex from "views/Classes/ClassesIndex";
import NewClassView from "views/Classes/NewClass";
import ProfilePage from "views/Users/ProfilePage/ProfilePage";
import EditClassView from "views/Classes/EditClass";
import EditPassword from "views/Users/EditPassword/EditPassword";
import ClassDetails from "views/Classes/ClassDetails";
import ReportsPage from "views/ReportsPage";
import InstructorsIndex from "views/Instructors/InstructorsIndex";
import NewInstructorView from "views/Instructors/NewInstructor";
import EditInstructorView from "views/Instructors/EditInstructor";
import ClassTypesIndex from "views/ClassTypes/ClassTypesIndex";
import NewClassTypeView from "views/ClassTypes/NewClassType";
import EditClassTypeView from "views/ClassTypes/EditClassType";
import ClassTypeDetails from "views/ClassTypes/ClassTypeDetails";
import PackagesIndex from "views/Packages/PackagesIndex";
import NewPackageView from "views/Packages/NewPackage";
import EditPackageView from "views/Packages/EditPackage";
import PaymentsIndex from "views/Payments/PaymentsIndex";

import AddSchedulesView from "views/ClassTypes/Schedules";
import SessionContext from "contexts/SessionContext";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import { isAdmin } from "utils/session-utils";
import NewPaymentView from "views/Payments/NewPayment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    // backgroundImage: `url(${image})`,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { currentUser } = useContext(SessionContext);

  const requireAdmin = (to, from, next) => {
    if (isAdmin(currentUser)) {
      next();
    } else {
      next.redirect("/");
      // window.location.href = "/";
    }
  };

  return (
    <BrowserRouter>
      <div className={classes.root}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <GuardProvider guards={[requireAdmin]}>
                <Switch>
                  <GuardedRoute
                    path="/admin/users/new"
                    component={NewUserView}
                  />
                  <GuardedRoute
                    path="/admin/users/:id/edit-password"
                    component={EditPassword}
                  />
                  <GuardedRoute
                    path="/admin/users/:id/edit"
                    component={EditUserView}
                  />

                  <GuardedRoute
                    path="/admin/users/:id"
                    component={ProfilePage}
                  />
                  <GuardedRoute path="/admin/users" component={UsersIndex} />
                  <GuardedRoute
                    path="/admin/classes/new"
                    component={NewClassView}
                  />
                  <GuardedRoute
                    path="/admin/classes/edit/:id"
                    component={EditClassView}
                  />
                  <GuardedRoute
                    path="/admin/classes/:id"
                    component={ClassDetails}
                  />
                  <GuardedRoute
                    path="/admin/classes"
                    component={ClassesIndex}
                  />
                  <GuardedRoute path="/admin/reports" component={ReportsPage} />
                  <GuardedRoute
                    path="/admin/instructors/new"
                    component={NewInstructorView}
                  />
                  <GuardedRoute
                    path="/admin/instructors/edit/:id"
                    component={EditInstructorView}
                  />
                  <GuardedRoute
                    path="/admin/instructors"
                    component={InstructorsIndex}
                  />
                  <GuardedRoute
                    path="/admin/brands/new"
                    component={NewClassTypeView}
                  />
                  <GuardedRoute
                    path="/admin/brands/edit/:id"
                    component={EditClassTypeView}
                  />
                  <GuardedRoute
                    path="/admin/brands/:id"
                    component={ClassTypeDetails}
                  />
                  <GuardedRoute
                    path="/admin/brands"
                    component={ClassTypesIndex}
                  />
                  <GuardedRoute
                    path="/admin/packages/new"
                    component={NewPackageView}
                  />
                  <GuardedRoute
                    path="/admin/packages/edit/:id"
                    component={EditPackageView}
                  />
                  <GuardedRoute
                    path="/admin/packages"
                    component={PackagesIndex}
                  />
                  <GuardedRoute
                    path="/admin/schedules/:id"
                    component={AddSchedulesView}
                  />
                  <GuardedRoute
                    path="/admin/payments/new"
                    component={NewPaymentView}
                  />
                  <GuardedRoute
                    path="/admin/payments"
                    component={PaymentsIndex}
                  />
                </Switch>
              </GuardProvider>
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default DashboardLayout;
