import cardStyles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";
import {
  secondaryColor,
  blackColor,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

const calendarStyle = () => ({
  ...cardStyles,
  pageHeader: {
    paddingBottom: "16vh",
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.5)",
    },
    "&:after": {
      background:
        "linear-gradient(60deg,rgba(" +
        hexToRgb(secondaryColor[0]) +
        ",.26),rgba(" +
        hexToRgb(secondaryColor[0]) +
        ",.75))",
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
    },
  },
  calendarContainer: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    zIndex: "2",
    position: "relative",
    paddingTop: "8rem",
    flexGrow: 1,
  },
  gridContainer: {
    backgroundColor: "white",
    "@media (min-width: 1500px)": {
      marginRight: 100,
      marginLeft: 100,
    },
  },
  calendarDaysContainer: {
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
  },
  columnGridItem: {
    "@media (min-width: 960px) and (max-width: 1500px)": {
      width: "1%",
    },
  },
  weekDayContainer: {
    backgroundColor: "black",
    color: "white",
    fontSize: "1.15rem",
    height: "3rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  hoursContainer: {
    maxWidth: "50px",
    backgroundColor: "black",
    color: "white",
    fontSize: "1.15rem",
    "@media (max-width: 959px)": {
      display: "none",
    },
  },
  emptyCell: {
    height: "3rem",
  },
  calendarSlot: { padding: 0 },
  eventCard: {
    margin: 0,
    height: 210,
    "& > div": {
      "@media (min-width: 960px) and (max-width: 1280px)": {
        padding: "0.9375rem 0 !important",
      },
      "(min-width: 1281px)": {
        padding: "0.9375rem 1rem !important",
      },
    },
  },
  emptyEventCard: {
    backgroundColor: "rgba(0,0,0,0.1)",
    "@media (max-width: 959px)": {
      display: "none",
    },
  },
  hourContainer: {
    height: 210,
    backgroundColor: "black",
    color: "white",
    writingMode: "vertical-rl",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
  inputDate: {
    border: "0px !important",
    backgroundColor: "rgba(0, 0, 0, 0) !important",
    transition: "background 0s ease-out !important",
    float: "none !important",
    boxShadow: "none !important",
    borderRadius: 0,
    fontWeight: 400,
    width: "100% !important",
    height: 36,
    padding: "7px 0 !important",
    fontSize: 14,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1.42857,
    color: "#555 !important",
  },
  weekPickerContainer: {
    paddingBottom: "2rem",
    paddingTop: "1.5rem",
    marginTop: "1.5rem",
    backgroundColor: "white",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
  weekPickerItem: {
    paddingLeft: 0,
  },
  inputDateContainer: {
    "&:last-child": {
      display: "none",
    },
  },
  brandsLogo: {
    height: "6rem",
  },
});

export default calendarStyle;
