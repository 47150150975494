import React, { useState, useEffect, useContext } from "react";
import { Container, makeStyles } from "@material-ui/core";
import Page from "components/Page";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import userService from "services/user.service";
import { useParams } from "react-router";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import LoadingContext from "contexts/LoadingContext";
import SessionContext from "contexts/SessionContext";
import UserForm from "../Form";
import { isAdmin } from "utils/session-utils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  selectGridItem: {
    marginTop: "10px",
  },
  dateGridItem: { marginTop: "20px" },
}));

function EditUserView() {
  const { setLoading } = useContext(LoadingContext);
  const history = useHistory();
  const classes = useStyles();
  const { currentUser } = useContext(SessionContext);

  const [user, setUser] = useState({
    name: "",
    lastName: "",
    sex: "",
    birthday: "",
    email: "",
    phone: "",
    roleId: 1,
    active: true,
    observations: "",
  });

  const [openSnack, setOpenSnack] = useState(false);
  const [serverResponse, setServerResponse] = useState({
    status: "success",
    message: "",
  });

  const { id: userId } = useParams();

  useEffect(() => {
    async function fetchData() {
      const { data } = await userService.getUser(userId);
      setUser(data);
    }
    fetchData();
  }, [userId]);

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await userService.updateUser(user.id, user);
      setLoading(false);
      if (isAdmin(currentUser)) {
        history.push({
          pathname: "/admin/users",
          state: { feedback: "Usuario editado correctamente" },
        });
      } else {
        history.push({
          pathname: `/profile/${currentUser?.dataValues?.id}`,
          state: { feedback: "Usuario editado correctamente" },
        });
      }
    } catch (error) {
      const {
        response: {
          data: { error: errorData },
        },
      } = error;
      setLoading(false);
      setServerResponse({
        status: "error",
        message: `Ocurrió un error al editar el usuario: ${errorData}`,
      });
      setOpenSnack(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Page className={classes.root} title="Usuarios">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={serverResponse.status}>
            <AlertTitle>
              {serverResponse.status === "success" ? "Éxito" : "Error"}
            </AlertTitle>
            {serverResponse.message}
          </Alert>
        </Snackbar>
        <Card>
          <CardHeader title="Editar Usuario"></CardHeader>
          <CardContent>
            <UserForm
              user={user}
              setUser={setUser}
              mode="edit"
              submitForm={submitForm}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default EditUserView;
