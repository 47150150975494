import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CustomSelect from "components/CustomSelect/CustomSelect";
import classService from "services/class.service";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import ActionButton from "components/CustomButtons/ActionButton";
import CustomConfirmAlert from "components/CustomConfirmAlert/CustomConfirmAlert";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Results = ({ className, users, fetchData, ...rest }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [deletingInscription, setDeletingInscription] = useState({});

  const paymentStatus = [
    { value: "Pendiente", text: "Pendiente" },
    { value: "Pagado", text: "Pagado" },
    { value: "Parcial", text: "Parcial" },
    { value: "Rechazado", text: "Rechazado" },
  ];
  const [openSnack, setOpenSnack] = useState(false);
  const [severity, setSeverity] = useState("");

  const handleDeleteItem = (inscription) => {
    setDeletingInscription(inscription);
    setOpenDialog(true);
  };

  const handleStatusChange = async (ev, userId, classId) => {
    try {
      await classService.updateStatus(classId, userId, ev.target.value);
      fetchData();
      setSeverity("success");
    } catch (error) {
      setSeverity("error");
    }
    setOpenSnack(true);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      style={{ overflowX: "auto" }}
    >
      <CustomConfirmAlert
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={async () => {
          await classService.dropOut(
            deletingInscription.classId,
            deletingInscription.userId
          );
          await fetchData();
        }}
        title={"Eliminar inscripción"}
        errorMessage={"Error al eliminar inscripción"}
        successMessage={"Inscripción eliminada"}
        warningMessage={
          "¿Estás seguro de que deseas eliminar la inscripción del usuario para esta clase? Esta acción no puede deshacerse."
        }
      />
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setOpenSnack(false);
        }}
      >
        <Alert severity={severity}>
          <AlertTitle>{severity === "success" ? "Éxito" : "Error"}</AlertTitle>
          {severity === "success"
            ? "Estatus de pago actualizado"
            : "Error al actualizar estatus de pago"}
        </Alert>
      </Snackbar>
      <Box minWidth={1050}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Asistencia</TableCell>
              <TableCell>Pago</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow hover key={user.id}>
                <TableCell>
                  <Box alignItems="center" display="flex">
                    <Typography color="textPrimary" variant="body1">
                      <Link to={`/admin/users/${user.id}`}>
                        {user.name} {user.lastName}
                      </Link>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{user.email || "-"}</TableCell>
                <TableCell>{user.phone || "-"}</TableCell>
                <TableCell>
                  {user.ClassUser.attendance === true
                    ? "Sí"
                    : user.ClassUser.attendance
                    ? "No"
                    : "-"}
                </TableCell>
                <TableCell>
                  <CustomSelect
                    labelText={""}
                    id={"status"}
                    options={paymentStatus}
                    inputProps={{
                      name: "status",
                      id: "status-select",
                      onChange: (ev) => {
                        handleStatusChange(ev, user.id, user.ClassUser.classId);
                      },
                      value: user.ClassUser.status,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <ActionButton
                    action="delete"
                    func={() => handleDeleteItem(user.ClassUser)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array,
  fetchData: PropTypes.func,
};

export default Results;
