import React, { useState, useContext, useEffect, useCallback } from "react";
import { Container, makeStyles, Box, Divider } from "@material-ui/core";
import Page from "components/Page";
import { Card, CardContent } from "@material-ui/core";
import ActionButton from "components/CustomButtons/ActionButton";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useParams } from "react-router";
import LoadingContext from "contexts/LoadingContext";
import classService from "services/class.service";
import QrScanner from "components/QrScanner/QrScanner";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Muted from "components/Typography/Muted.js";
import moment from "moment";
import Results from "./Results";
import ScanResultModal from "./ScanResultModal";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
}));

function ClassDetailsView() {
  const { setLoading } = useContext(LoadingContext);
  const classes = useStyles();
  const [lesson, setLesson] = useState(undefined);
  const [scanModal, setScanModal] = useState(false);
  const [reservationStatus, setReservationStatus] = useState("");
  const [scannedUser, setScannedUser] = useState({});
  const [scanSuccesful, setScanSuccesful] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  const [serverResponse, setServerResponse] = useState({
    status: "success",
    message: "",
  });
  const [openReader, setOpenReader] = useState(false);
  const [scanningClassId, setScanningClassId] = useState(undefined);
  const { id: lessonId } = useParams();

  const fetchData = useCallback(
    async function () {
      const { data } = await classService.getClass(lessonId);
      setLesson(data);
    },
    [lessonId]
  );

  useEffect(() => {
    fetchData();
  }, [lessonId, fetchData]);

  const handleClose = (event, reason) => {
    setOpenSnack(false);
    if (reason === "clickaway") {
      return;
    }
  };

  const openScan = async (lesson) => {
    setScanningClassId(lesson.id);
    setOpenReader(true);
  };

  const scanQr = async (result) => {
    if (result !== null) {
      setLoading(true);
      try {
        await fetchData();
        const {
          data: {
            classUser: { status },
            user,
          },
        } = await classService.attendance(scanningClassId, result);

        setScanSuccesful(true);
        setReservationStatus(status);
        setScannedUser(user);
        setLoading(false);
        setOpenReader(false);
        setScanModal(true);
      } catch (e) {
        setScanSuccesful(false);
        setLoading(false);
        setOpenReader(false);
        setScanModal(true);
      }
    }
  };

  const scannerError = (error) => {
    setServerResponse({
      status: "error",
      message: error,
    });
    setOpenReader(false);
    setOpenSnack(true);
  };

  return (
    <Page className={classes.root} title="Detalles de clase">
      <ScanResultModal
        scanSuccessful={scanSuccesful}
        reservationStatus={reservationStatus}
        scanModal={scanModal}
        setScanModal={setScanModal}
        lesson={lesson}
        user={scannedUser}
      />
      <QrScanner
        open={openReader}
        onScan={scanQr}
        onError={scannerError}
        handleClose={() => {
          setOpenReader(false);
        }}
      />
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={serverResponse.status}>
            <AlertTitle>
              {serverResponse.status === "success" ? "Éxito" : "Error"}
            </AlertTitle>
            {serverResponse.message}
          </Alert>
        </Snackbar>
        {lesson !== undefined && (
          <Card>
            <CardContent>
              <Box display="flex" justifyContent="flex-end">
                <ActionButton
                  action="trigger"
                  icon={<CameraAltIcon />}
                  func={() => openScan(lesson)}
                  text="QR"
                />
              </Box>
              <h3>Detalles de clase</h3>
              <GridContainer>
                <GridItem xs={12} md={3}>
                  <Muted>
                    <h4 className={classes.cardCategory}>
                      <strong>Brand</strong>
                    </h4>
                  </Muted>
                  <p className={classes.description}>{lesson.classType.name}</p>
                </GridItem>
                <GridItem xs={12} md={3}>
                  <Muted>
                    <h4 className={classes.cardCategory}>
                      <strong>Instructor</strong>
                    </h4>
                  </Muted>
                  <p className={classes.description}>
                    {lesson.instructor.firstName} {lesson.instructor.lastName}
                  </p>
                </GridItem>
                <GridItem xs={12} md={3}>
                  <Muted>
                    <h4 className={classes.cardCategory}>
                      <strong>Tipo</strong>
                    </h4>
                  </Muted>
                  <p className={classes.description}>
                    {lesson.subtype ? lesson.subtype : "No especificado"}
                  </p>
                </GridItem>
                <GridItem xs={12} md={3}>
                  <Muted>
                    <h4 className={classes.cardCategory}>
                      <strong>Fecha</strong>
                    </h4>
                  </Muted>
                  <p className={classes.description}>
                    {moment(lesson.date).utc().format("DD/MM/YYYY")}
                  </p>
                </GridItem>
                <GridItem xs={12} md={4}>
                  <Muted>
                    <h4 className={classes.cardCategory}>
                      <strong>Horario</strong>
                    </h4>
                  </Muted>
                  <p className={classes.description}>
                    {lesson.start}-{lesson.end}
                  </p>
                </GridItem>
              </GridContainer>
              <Divider variant="middle" className={classes.divider} />
              <h3>Clientes</h3>
              <Results users={lesson.Users} fetchData={fetchData} />
            </CardContent>
          </Card>
        )}
      </Container>
    </Page>
  );
}

export default ClassDetailsView;
