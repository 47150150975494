import React, { useState, useEffect, useContext } from "react";
import { Container, makeStyles } from "@material-ui/core";
import Page from "components/Page";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import PackageService from "services/package.service";
import { useParams } from "react-router";
import ActionButton from "components/CustomButtons/ActionButton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import LoadingContext from "contexts/LoadingContext";
import PackageForm from "../Form";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  selectGridItem: {
    marginTop: theme.spacing(1.25),
  },
  dateGridItem: { marginTop: theme.spacing(2.5) },
}));

function EditPackageView() {
  const { setLoading } = useContext(LoadingContext);
  const history = useHistory();
  const classes = useStyles();
  const [classPackage, setClassPackage] = useState({
    classTypeId: "",
    name: "",
    lessonsNum: "",
    price: "",
    packageType: "",
    visible: true,
  });

  const [openSnack, setOpenSnack] = React.useState(false);
  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });

  const { id: classPackageId } = useParams();

  useEffect(() => {
    async function fetchData() {
      const { data } = await PackageService.getPackage(classPackageId);
      const packageType =
        data.lessonsNum === "Ilimitado" ? "Monthly" : "Classes";
      setClassPackage({ ...data, packageType });
    }
    fetchData();
  }, [classPackageId]);

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await PackageService.updatePackage(classPackage.id, classPackage);
      setLoading(false);
      history.push({
        pathname: "/admin/packages",
        state: { feedback: "Paquete editado correctamente" },
      });
    } catch (error) {
      setLoading(false);
      setServerResponse({
        status: "error",
        message: "Ocurrió un error al editar el paquete",
      });
      setOpenSnack(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Page className={classes.root} title="Editar Paquete">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={serverResponse.status}>
            <AlertTitle>
              {serverResponse.status === "success" ? "Éxito" : "Error"}
            </AlertTitle>
            {serverResponse.message}
          </Alert>
        </Snackbar>
        <Card>
          <CardHeader title="Editar Paquete"></CardHeader>
          <CardContent>
            <PackageForm
              classPackage={classPackage}
              setClassPackage={setClassPackage}
              editing={true}
            />
            <GridContainer justify="center">
              <GridItem xs={12}>
                <div style={{ textAlign: "center" }}>
                  <ActionButton
                    action="cancel"
                    text="Cancelar"
                    href="/admin/packages"
                  ></ActionButton>
                  <ActionButton
                    action="confirm"
                    text="Enviar"
                    func={submitForm}
                  ></ActionButton>
                </div>
              </GridItem>
            </GridContainer>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default EditPackageView;
