/*eslint-disable*/
import React, { useEffect, useState, useContext, useCallback } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from "@material-ui/icons/Person";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Badge from "components/Badge/Badge.js";
import Muted from "components/Typography/Muted.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";

import profilePlaceholder from "assets/img/profilepic.png";
import QRCode from "qrcode.react";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import userService from "services/user.service";
import ClassTypeService from "services/classType.service";
import moment from "moment";

import { NavLink as RouterLink } from "react-router-dom";
import UserClasses from "./UserClasses/index";
import UserPackages from "./UserPackages/index";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import UpdateAvailableDialog from "./UpdateAvailableDialog";
import SectionsHeader from "components/SectionsHeader";
import { isAdmin } from "utils/session-utils";
import SessionContext from "contexts/SessionContext";

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import packageService from "services/package.service";

const useStyles = makeStyles((theme) => ({
  ...profilePageStyle,
  [theme.breakpoints.up("sm")]: {
    qrcontainer: {
      marginTop: theme.spacing(4.25),
    },
  },

  [theme.breakpoints.down("sm")]: {
    qrImage: {
      marginTop: theme.spacing(8),
    },
  },
  availableClasses: {
    fontSize: 14,
    margin: 0,
  },
  updateAvailableButton: {
    marginLeft: theme.spacing(5),
  },
}));

export default function ProfilePage(props) {
  const history = useHistory();
  const { id: userId } = useParams();
  const [user, setUser] = useState({});
  const { currentUser } = useContext(SessionContext);
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const [brands, setBrands] = useState([]);
  const [userPackages, setUserPackages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loadedAvailable, setLoadedAvailable] = useState(false);
  const [availableClasses, setAvailableClasses] = useState({});
  const [openSnack, setOpenSnack] = useState(false);
  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const missingText = "No especificado";

  const fetchData = useCallback(async function () {
    const { data } = await userService.getUser(userId);
    setUser(data);
  }, []);

  useEffect(() => {
    if (!isAdmin(currentUser) && userId != currentUser?.dataValues?.id) {
      history.push({
        pathname: `/profile/${currentUser?.dataValues?.id}`,
      });
    }
  });

  useEffect(() => {
    async function fetchBrands() {
      const {
        data: { classTypes: result },
      } = await ClassTypeService.getClassTypes(100, 0);
      setBrands(result);
    }
    fetchData();
    fetchBrands();
  }, []);

  useEffect(() => {
    async function fetchUserPackages() {
      const {
        data: { userPackages: result },
      } = await packageService.getUserPackages("all", userId);

      setUserPackages(result);
    }
    fetchUserPackages();
  }, []);

  useEffect(() => {
    if (props.history.location.state) {
      setServerResponse({
        status: props.history.location.state.status || "success",
        message: props.history.location.state.feedback,
      });
      setOpenSnack(true);
    }
  }, [props.history.location.state]);

  useEffect(() => {
    if (brands.length > 0) {
      const availableHash = brands.reduce((hash, brand) => {
        hash[brand.id] = 0;
        return hash;
      }, {});
      if (user.ClassTypes) {
        user.ClassTypes.map((brand) => {
          availableHash[brand.id] = brand.ClassTypeUser.available;
        });
        setAvailableClasses(availableHash);
        setLoadedAvailable(true);
      }
    }
  }, [brands, user]);

  const filterUserPackagesByBrand = (brandId) =>
    userPackages?.filter(
      ({ package: { classTypeId } }) => classTypeId === brandId
    );

  const formatDate = (rawDate, format = "DD/MM/YYYY") =>
    moment(rawDate).utc().format(format);

  return (
    <div>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      {!isAdmin(currentUser) && <SectionsHeader whiteText />}
      {loadedAvailable && (
        <UpdateAvailableDialog
          open={openDialog}
          setOpen={setOpenDialog}
          user={user}
          brands={brands}
          fetchData={fetchData}
          availableClasses={availableClasses}
        />
      )}
      <Parallax
        image={require("assets/img/Calisto-293.jpg")}
        filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <div>
                  <img
                    src={user.profilePic ? user.profilePic : profilePlaceholder}
                    alt="..."
                    className={imageClasses}
                  />
                </div>
                <div className={classes.name}>
                  <h3 className={classes.title}>
                    {user.name} {user.lastName}
                  </h3>
                </div>
              </div>
              <div className={classes.follow}>
                <Tooltip
                  id="tooltip-top"
                  title="Editar perfil"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    justIcon
                    round
                    color="primary"
                    className={classes.followButton}
                    onClick={handleClick}
                  >
                    <EditIcon className={classes.followIcon} />
                  </Button>
                </Tooltip>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <PersonIcon color="disabled" />{" "}
                      {isAdmin(currentUser) && (
                        <RouterLink to={`/admin/users/${userId}/edit`}>
                          Editar perfil
                        </RouterLink>
                      )}
                      {!isAdmin(currentUser) && (
                        <RouterLink to={`/profile/${userId}/edit`}>
                          Editar perfil
                        </RouterLink>
                      )}
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <LockIcon color="disabled" />{" "}
                      {isAdmin(currentUser) && (
                        <RouterLink to={`/admin/users/${userId}/edit-password`}>
                          Editar contraseña
                        </RouterLink>
                      )}
                      {!isAdmin(currentUser) && (
                        <RouterLink to={`/profile/${userId}/edit-password`}>
                          Editar contraseña
                        </RouterLink>
                      )}
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </GridItem>
          </GridContainer>

          <div className={classes.profileTabs}>
            <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "Información",
                  tabIcon: AssignmentIndIcon,
                  tabContent: (
                    <div>
                      <GridContainer
                        justify="center"
                        className={classes.profileDataContainer}
                      >
                        <GridItem
                          xs={12}
                          sm={12}
                          md={4}
                          className={classes.gridItem}
                          style={{ textAlign: "left" }}
                        >
                          <div className={classes.profileData}>
                            <Muted>
                              <h4 className={classes.cardCategory}>
                                <strong>Sexo</strong>
                              </h4>
                            </Muted>
                            <p className={classes.description}>
                              {user.sex || missingText}
                            </p>
                          </div>
                          <div className={classes.profileData}>
                            <Muted>
                              <h4 className={classes.cardCategory}>
                                <strong>Fecha de nacimiento</strong>
                              </h4>
                            </Muted>
                            <p className={classes.description}>
                              {user.birthday
                                ? formatDate(user.birthday)
                                : missingText}
                            </p>
                          </div>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={4}
                          className={classes.gridItem}
                          style={{ textAlign: "left" }}
                        >
                          <div className={classes.profileData}>
                            <Muted>
                              <h4 className={classes.cardCategory}>
                                {" "}
                                <strong>Email</strong>
                              </h4>
                            </Muted>
                            <p className={classes.description}>{user.email}</p>
                          </div>
                          <div className={classes.profileData}>
                            <Muted>
                              <h4 className={classes.cardCategory}>
                                {" "}
                                <strong>Teléfono</strong>
                              </h4>
                            </Muted>
                            <p className={classes.description}>
                              {user.phone || missingText}
                            </p>
                          </div>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={4}
                          className={classes.gridItem}
                          style={{ textAlign: "left" }}
                        >
                          <div className={classes.profileData}>
                            <Muted>
                              <h4 className={classes.cardCategory}>
                                {" "}
                                <strong>Observaciones</strong>
                              </h4>
                            </Muted>
                            <p className={classes.description}>
                              {user.observations || missingText}
                            </p>
                          </div>
                        </GridItem>
                      </GridContainer>

                      <GridContainer
                        justify="center"
                        alignItems="center"
                        className={classes.qrcontainer}
                      >
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.gridItem}
                        >
                          <h4 className={classes.title}>Tus Estadísticas</h4>
                          <ul className={classes.listUnstyled}>
                            <li>
                              <b>{user.totalClasses}</b> clases en total
                            </li>
                            <li>
                              <b>{user.classesPerWeek}</b> clases promedio a la
                              semana
                            </li>
                            <li>
                              <b>{user.daysMember}</b> días siendo miembro
                            </li>
                          </ul>
                          <hr />
                          <h4 className={classes.title}>
                            Paquetes contratados
                            {isAdmin(currentUser) && (
                              <Tooltip
                                id="tooltip-top"
                                title="Actualizar paquetes contratados"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  justIcon
                                  round
                                  color="primary"
                                  className={classes.updateAvailableButton}
                                  onClick={() => {}}
                                >
                                  <EditIcon className={classes.followIcon} />
                                </Button>
                              </Tooltip>
                            )}
                          </h4>
                          <GridContainer>
                            {brands.length > 0 &&
                              brands.map((brand) => (
                                <GridItem
                                  xs={12}
                                  md={6}
                                  key={brand.id}
                                  style={{ textAlign: "center" }}
                                >
                                  <h5 style={{ marginTop: 0 }}>{brand.name}</h5>
                                  <List>
                                    {userPackages &&
                                      filterUserPackagesByBrand(brand.id).map(
                                        (brandUserPackage) => (
                                          <ListItem
                                            button
                                            key={brandUserPackage.id}
                                            style={{ padding: 0 }}
                                          >
                                            <ListItemText
                                              primary={
                                                brandUserPackage.package.name
                                              }
                                              secondary={`${formatDate(
                                                brandUserPackage.startDate,
                                                "DD/MM/YY"
                                              )} - ${formatDate(
                                                brandUserPackage.endDate,
                                                "DD/MM/YY"
                                              )}`}
                                            />
                                            <ListItemSecondaryAction>
                                              {brandUserPackage.status}
                                            </ListItemSecondaryAction>
                                          </ListItem>
                                        )
                                      )}
                                  </List>
                                </GridItem>
                              ))}
                          </GridContainer>
                          <hr />
                          <h4 className={classes.title}>
                            Clases disponibles
                            {isAdmin(currentUser) && (
                              <Tooltip
                                id="tooltip-top"
                                title="Actualizar clases disponibles"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button
                                  justIcon
                                  round
                                  color="primary"
                                  className={classes.updateAvailableButton}
                                  onClick={() => {
                                    setOpenDialog(true);
                                  }}
                                >
                                  <EditIcon className={classes.followIcon} />
                                </Button>
                              </Tooltip>
                            )}
                          </h4>
                          {brands.length > 0 &&
                            brands.map((brand) => (
                              <Badge
                                key={brand.id}
                                color={brand.name.toLowerCase()}
                              >
                                <p className={classes.availableClasses}>
                                  {brand.name}: {availableClasses[brand.id]}
                                </p>
                              </Badge>
                            ))}
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.gridItem}
                          style={{ textAlign: "center" }}
                        >
                          <div className={classes.qrcontainer}>
                            <QRCode
                              value={`${user.id}`}
                              size={250}
                              className={classes.qrImage}
                              level="H"
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                    </div>
                  ),
                },
                /* Classes Tab */
                {
                  tabButton: "Clases",
                  tabIcon: CalendarTodayIcon,
                  tabContent: <UserClasses />,
                },
                {
                  tabButton: "Paquetes",
                  tabIcon: ListIcon,
                  tabContent: <UserPackages />,
                },
              ]}
            />
          </div>
          <Clearfix />
        </div>
      </div>
    </div>
  );
}
