import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { FormControl, TextField } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import userService from "services/user.service";

const UpdateAvailableDialog = ({
  open,
  setOpen,
  user,
  brands,
  fetchData,
  availableClasses,
}) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [severity, setSeverity] = useState("");
  const [inputIsValid, setInputIsValid] = useState(false);
  const [available, setAvailable] = useState({ ...availableClasses });

  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    try {
      const availableArr = brands.map((brand) => [
        brand.id,
        available[brand.id],
      ]);
      await userService.updateAvailableClasses(user.id, availableArr);
      setSeverity("success");
      fetchData();
    } catch (error) {
      setSeverity("error");
    }
    setOpenSnack(true);
    setOpen(false);
  };

  const handleChange = async (ev) => {
    const {
      target: { name, value },
    } = ev;

    setInputIsValid(
      value !== "" && (!isNaN(Number(value)) || value === "Ilimitado")
    );

    setAvailable({
      ...available,
      [name]: value,
    });
  };

  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setOpenSnack(false);
        }}
      >
        <Alert severity={severity}>
          <AlertTitle>{severity === "success" ? "Éxito" : "Error"}</AlertTitle>
          {severity === "success"
            ? "Clases disponibles actualizadas"
            : "Error al actualizar clases disponibles"}
        </Alert>
      </Snackbar>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Clases disponibles</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Selecciona el número de clases disponibles por brand:
          </DialogContentText>
          <GridContainer>
            {brands.map((brand) => (
              <GridItem xs={12} key={brand.id}>
                <FormControl key={brand.id}>
                  <TextField
                    label={brand.name}
                    id={`${brand.id}`}
                    name={`${brand.id}`}
                    onChange={handleChange}
                    value={available[brand.id]}
                  />
                </FormControl>
              </GridItem>
            ))}
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancelar</Button>
          <Button
            disabled={!inputIsValid}
            onClick={handleConfirm}
            color="secondary"
            variant="contained"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

UpdateAvailableDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  brands: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  availableClasses: PropTypes.object.isRequired,
};

export default UpdateAvailableDialog;
