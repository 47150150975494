/*eslint-disable*/
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import paymentPageStyle from "./PaymentsPageStyles";
import image from "assets/img/Calisto-169.jpg";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import SectionsHeader from "components/SectionsHeader";
import CustomFooter from "components/CustomFooter/CustomFooter";
import "moment/locale/es";
import CreditCardForm from "components/CreditCardForm/CreditCardForm";

// Images
import creditCardsImage from "assets/img/OpenPay/cards1.png";

// Services
import packageService from "services/package.service";
import CustomCurrency from "components/CustomCurrency/CustomCurrency";
import moment from "moment";

const useStyles = makeStyles(paymentPageStyle);

const PaymentPage = (props) => {
  const [openSnack, setOpenSnack] = useState(false);
  const [classPackage, setClassPackage] = useState(false);
  const [serverResponse, setServerResponse] = useState({
    status: "success",
    message: "",
  });

  const { packageId } = useParams();

  useEffect(() => {
    async function fetchData() {
      const { data } = await packageService.getPackage(packageId);

      setClassPackage(data);
    }
    fetchData();
  }, [packageId]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  useEffect(() => {
    if (props.history.location.state) {
      setServerResponse({
        status: "success",
        message: props.history.location.state.feedback,
      });
      setOpenSnack(true);
    }
  }, [props.history.location.state]);

  const classes = useStyles();
  return (
    <div>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      <SectionsHeader whiteText />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer
            justify="center"
            style={{ marginTop: 90, paddingBottom: 150 }}
          >
            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes.cardSignup}>
                {/* <h2 className={classes.cardTitle}>Realizar Pago</h2> */}
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem
                      xs={12}
                      sm={8}
                      md={8}
                      className={classes.cardItem}
                    >
                      <div className={classes.textCenter}>
                        <h3>Realizar Pago</h3>
                        <img alt="CreditCards" src={creditCardsImage} />
                      </div>

                      <CreditCardForm
                        price={Number(classPackage?.price).toFixed(2)}
                        classPackageId={classPackage?.id}
                        setOpenSnack={setOpenSnack}
                        setServerResponse={setServerResponse}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={4}
                      md={4}
                      className={classes.summaryItem}
                    >
                      <div className={classes.textCenter}>
                        <h3>Resumen de Compra</h3>
                        Vigencia:{" "}
                        {`${moment().format("DD/MM/YYYY")} - ${moment()
                          .add(1, "month")
                          .format("DD/MM/YYYY")}`}
                      </div>
                      <hr />
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        <ListItem>
                          <ListItemText
                            primary={`${
                              classPackage?.lessonsNum === "Ilimitado"
                                ? "Ilimitado"
                                : `${classPackage?.lessonsNum} x`
                            } Clase ${classPackage?.classType?.name}`}
                          />
                          {classPackage?.lessonsNum !== "Ilimitado" && (
                            <ListItemSecondaryAction>
                              <CustomCurrency
                                quantity={
                                  Number(classPackage.price) /
                                  Number(classPackage.lessonsNum)
                                }
                              />
                            </ListItemSecondaryAction>
                          )}
                        </ListItem>
                        <hr />
                        <ListItem>
                          <ListItemText primary={`Total`} />
                          <ListItemSecondaryAction>
                            <CustomCurrency quantity={classPackage.price} />
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
};

// PaymentPage.propTypes = {
//   history: PropTypes.shape({
//     location: PropTypes.shape({
//       state: PropTypes.shape({
//         feedback: PropTypes.string,
//       }),
//     }),
//   }),
// };

export default PaymentPage;
