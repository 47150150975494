import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import ActionButton from "components/CustomButtons/ActionButton";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const Toolbar = ({ className, search, setSearch, setPage, ...rest }) => {
  const classes = useStyles();

  const handleInput = (event) => {
    const { value } = event.target;
    setPage(0);
    setSearch(value);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <ActionButton
          action="create"
          href="/admin/instructors/new"
        ></ActionButton>
      </Box>
      <Box mt={3}>
        <Box maxWidth={500}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Buscar instructor"
            variant="outlined"
            value={search}
            onChange={handleInput}
          />
        </Box>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  setPage: PropTypes.func,
};

export default Toolbar;
