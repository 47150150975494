import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
import cardsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";
import { container } from "assets/jss/material-kit-pro-react";

const chooseBrandPageStyle = (theme) => ({
  ...cardsStyle,
  ...headerLinksStyle(theme),
  ...headersStyle(theme),
  brandImg: {
    height: "30vh",
    objectFit: "cover",
    "@media (min-width: 960px)": {
      height: "50vh",
    },
  },
  brandsLogo: {
    width: "90%",
    height: "6rem",
    objectFit: "contain",
  },
  brandsContainer: {
    ...container,
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    zIndex: "2",
    position: "relative",
    flexGrow: 1,
  },
  brandsHeader: {
    position: "relative",
    height: "100vh",
    maxHeight: "1600px",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    "@media (max-width: 960px)": {
      paddingTop: "35rem",
      paddingBottom: "30rem",
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
    },
  },
});

export default chooseBrandPageStyle;
