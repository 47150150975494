import SectionsHeader from "components/SectionsHeader";
import React from "react";
import Calendar from "./Calendar";

function CalendarPage() {
  return (
    <div>
      <SectionsHeader></SectionsHeader>
      <Calendar></Calendar>
    </div>
  );
}

export default CalendarPage;
