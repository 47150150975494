import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import userService from "services/user.service";
import ActionButton from "components/CustomButtons/ActionButton";
import CustomConfirmAlert from "components/CustomConfirmAlert/CustomConfirmAlert";
import classService from "services/class.service";

const useStyles = makeStyles((theme) => ({
  root: {},
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "white",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  circularProgress: {
    color: theme.palette.secondary.main,
  },
}));

function UserClasses({ classType = 1 }) {
  const styleClasses = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [classes, setClasses] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [totalClasses, setTotalClasses] = useState(0);
  const { id: userId } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [deletingInscription, setDeletingInscription] = useState({});

  const handleDeleteItem = (inscription) => {
    setDeletingInscription(inscription);
    setOpenDialog(true);
  };

  const fetchData = useCallback(
    async function () {
      setLoading(true);
      const {
        data: { userClasses, total },
      } = await userService.getUserClasses(userId, classType, limit, page);

      setClasses(userClasses);
      setTotalClasses(total);
      setLoading(false);
    },
    [userId, classType, limit, page]
  );

  useEffect(() => {
    fetchData();
  }, [userId, classType, limit, page, fetchData]);

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const isBeforeClass = (date, start) => {
    const auxDate = moment(date).utc().format("YYYY-MM-DD");
    const dateTime = moment(`${auxDate} ${start}`);
    return moment().isBefore(dateTime.subtract(20, "minutes"));
  };

  return (
    <>
      <CustomConfirmAlert
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={async () => {
          await classService.dropOut(
            deletingInscription.classId,
            deletingInscription.userId
          );
          await fetchData();
        }}
        title={"Eliminar inscripción"}
        errorMessage={"Error al eliminar inscripción"}
        successMessage={"Inscripción eliminada"}
        warningMessage={
          "¿Estás seguro de que deseas eliminar tu inscripción para esta clase? Esta acción no puede deshacerse."
        }
      />
      <GridContainer>
        <GridItem>
          <Table>
            <TableHead>
              <TableRow>
                {classType === "all" && <TableCell>Tipo</TableCell>}
                <TableCell>Fecha</TableCell>
                <TableCell>Horario</TableCell>
                <TableCell>Instructor</TableCell>
                <TableCell>Asistencia</TableCell>
                <TableCell>Pago</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {classes &&
                classes.map((lesson) => (
                  <TableRow hover key={lesson.id}>
                    {classType === "all" && (
                      <TableCell>
                        {lesson.classType && lesson.classType.name}
                      </TableCell>
                    )}
                    <TableCell>
                      {moment(lesson.date).utc().format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{`${lesson.start} - ${lesson.end}`}</TableCell>
                    <TableCell>
                      {lesson.instructor &&
                        `${lesson.instructor.firstName} ${lesson.instructor.lastName}`}
                    </TableCell>
                    <TableCell>
                      {lesson.ClassUser.attendance
                        ? "Asististe"
                        : "Sin asistencia"}
                    </TableCell>
                    <TableCell>
                      {lesson.ClassUser.status ? lesson.ClassUser.status : "-"}
                    </TableCell>
                    <TableCell>
                      {isBeforeClass(lesson.date, lesson.start) && (
                        <ActionButton
                          action="delete"
                          func={() => handleDeleteItem(lesson.ClassUser)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </GridItem>
      </GridContainer>
      {isLoading && (
        <div className={styleClasses.progressContainer}>
          <CircularProgress
            className={styleClasses.circularProgress}
            size="100px"
          />
        </div>
      )}
      <TablePagination
        component="div"
        count={totalClasses}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </>
  );
}

UserClasses.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.number,
  classType: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default UserClasses;
