import axios from "axios";

class ReportsService {
  getClassesByPeriod() {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/reports/classes-by-period`, {
      headers: { Authorization: token },
    });
  }

  getCustomersCount() {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/reports/customers-count`, {
      headers: { Authorization: token },
    });
  }

  getInstructorsCount() {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/reports/instructors-count`, {
      headers: { Authorization: token },
    });
  }

  getAttendancePercentage() {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/reports/attendance-percentage`, {
      headers: { Authorization: token },
    });
  }

  getSexesCount() {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/reports/sexes-count`, {
      headers: { Authorization: token },
    });
  }

  getAgesCount() {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/reports/ages-count`, {
      headers: { Authorization: token },
    });
  }

  getCustomersAvgPerDay() {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/reports/customers-avg-day`, {
      headers: { Authorization: token },
    });
  }

  getInstructorsInscriptions() {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/reports/instructors-inscriptions`, {
      headers: { Authorization: token },
    });
  }

  exportReports() {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/reports/export`, {
      headers: { Authorization: token },
    });
  }
}

export default new ReportsService();
