import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
import {
  primaryColor,
  secondaryColor,
} from "assets/jss/material-kit-pro-react.js";

const homePageStyle = (theme) => ({
  ...headerLinksStyle(theme),
  ...headersStyle(theme),
  yellowShapeGradient: {
    backgroundColor: primaryColor[0],
    opacity: "50%",
    height: "4rem",
    width: "47%",
    position: "absolute",
    left: 0,
    top: "46%",
  },
  yellowShape: {
    backgroundColor: primaryColor[0],
    height: "3rem",
    width: "40%",
    zIndex: 100,
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  instructorsImgContainer: {
    position: "relative",
    marginBottom: 40,
    "@media (min-width: 961px)": {
      marginTop: 70,
      marginBottom: 60,
      marginRight: 100,
      marginLeft: 100,
    },
  },
  instructorsImg: {
    maxHeight: "80vh",
    width: "100%",
    objectFit: "cover",
  },
  floatingLogo: {
    visibility: "hidden",
    position: "absolute",
    bottom: -32,
    left: 20,
    zIndex: 200,
    width: "42rem",
    "@media (min-width: 960px)": {
      visibility: "visible",
    },
  },
  letterC: {
    position: "absolute",
    left: "40%",
    top: "2rem",
    opacity: "0.8",
    fontFamily: "HugolersStylishRegular",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "15rem",
    color: primaryColor[0],
    "@media (min-width: 960px)": {
      fontSize: "35rem",
      left: "66%",
      top: "100px",
    },
  },
  letterG: {
    position: "absolute",
    right: "10%",
    top: "2rem",
    opacity: "0.6",
    fontFamily: "HugolersStylishRegular",
    fontWeight: 400,
    fontStyle: "normal",
    color: secondaryColor[0],
    visibility: "hidden",
    "@media (min-width: 960px)": {
      visibility: "visible",
      fontSize: "25rem",
      right: "-90px",
      top: "-80px",
    },
  },
  womanImg: {
    height: "10rem",
    position: "absolute",
    left: "40%",
    "@media (min-width: 960px)": {
      height: "25rem",
      left: "65%",
    },
  },
  womanContainer: {
    padding: 0,
    textAlign: "center",
    "@media (min-width: 961px)": {
      marginBottom: "12rem",
      textAlign: "right",
      marginTop: "6rem",
    },
  },
  womanTextContainer: {
    fontFamily: "Avenir",
    fontWeight: 400,
    padding: 20,
    marginTop: "12rem",
    textAlign: "center",
    "@media (min-width: 961px)": {
      paddingLeft: "2rem",
      paddingRight: "15rem",
      marginTop: "5rem",
      textAlign: "left",
      marginBottom: 100,
    },
  },
  womanText: {
    fontFamily: "Avenir",
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: "center",
    fontSize: "1.2rem",
    "@media (min-width: 961px)": {
      fontSize: "1.5rem",
      textAlign: "left",
    },
  },
  brandsImg: {
    width: "100%",
    height: "20vh",
    objectFit: "cover",
    "@media (min-width: 800px)": {
      height: "350px",
    },
  },
  brandsImgBloom: {
    objectPosition: "50% 80%",
  },
  brandsLogo: {
    width: "50%",
    height: "150px",
    objectFit: "contain",
    marginTop: "1rem",
    "@media (min-width: 800px)": {
      marginTop: "4rem",
    },
  },
  joinButton: {
    fontFamily: "Avenir",
    fontWeight: 700,
    fontSize: "1.4rem",
    wordSpacing: "10px",
    marginBottom: "2rem",
    width: "14rem",
    padding: "5px",
    marginTop: "1rem",
    "@media (min-width: 800px)": {
      marginTop: "3rem",
    },
    color: secondaryColor[0],
    "&:hover": {
      "& a": {
        color: "white",
      },
    },
  },
  moveButton: {
    fontFamily: "Avenir",
    fontWeight: 700,
    fontSize: "1.2rem",
    wordSpacing: "10px",
    color: secondaryColor[0],
    width: "10rem",
    padding: "5px",
    backgroundColor: "#f5f5f5",
  },
  brandsContainer: {
    borderColor: primaryColor[0],
    borderStyle: "solid",
    marginBottom: "2.5rem",
    "@media (min-width: 800px)": {
      marginRight: 200,
      marginLeft: 200,
    },
  },
  footerImgContainer: {
    "@media (min-width: 991px)": {
      marginTop: "7rem",
      marginBottom: "15vh",
    },
  },
  footerImg: {
    width: "100%",
    objectFit: "cover",
    maxHeight: "20vh",
    objectPosition: "0px 80%",
    "@media (min-width: 800px)": {
      maxHeight: "350px",
      transform: "scale(1.2)",
      objectPosition: "50px 80%",
    },
  },
});

export default homePageStyle;
