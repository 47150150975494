/* eslint-disable */
import React, { useContext } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import SessionContext from "contexts/SessionContext";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import "./Header.css";

// @material-ui/icons

// core components
import Button from "components/CustomButtons/Button.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import { isAdmin } from "utils/session-utils";
import { isLoggedIn } from "utils/session-utils";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const { whiteText } = props;
  const { currentUser, setCurrentUser } = useContext(SessionContext);
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const onLogout = () => {
    sessionStorage.removeItem("storedUser");
    sessionStorage.removeItem("clusterToken");
    localStorage.removeItem("storedUser");
    localStorage.removeItem("clusterToken");
    setCurrentUser({});
    window.location.href = "/";
  };

  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto + " " + "headerList"}>
      <ListItem className={classes.listItem}>
        <Link
          className={whiteText ? classes.whiteText : classes.blackText}
          to="/"
        >
          <Button color="transparent" className={classes.navLink}>
            Home
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={whiteText ? classes.whiteText : classes.blackText}
          to="/brands"
        >
          <Button color="transparent" className={classes.navLink}>
            Reserva
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          buttonText={
            <>
              <span
                className={whiteText ? classes.whiteText : classes.blackText}
                style={{ marginRight: 8 }}
              >
                Membresía
              </span>
            </>
          }
          buttonProps={{
            color: "transparent",
            className: "headerProfileLink",
          }}
          dropdownList={[
            <Link
              className={`${classes.dropdownLink} ${classes.navLink} ${classes.blackText}`}
              to="/membresia/calisto"
            >
              Calisto
            </Link>,
            <Link
              className={`${classes.dropdownLink} ${classes.navLink} ${classes.blackText}`}
              to="/membresia/bloom"
            >
              Bloom
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={whiteText ? classes.whiteText : classes.blackText}
          to="/calisto"
        >
          <Button color="transparent" className={classes.navLink}>
            Calisto
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          className={whiteText ? classes.whiteText : classes.blackText}
          to="/bloom"
        >
          <Button color="transparent" className={classes.navLink}>
            Bloom Studio
          </Button>
        </Link>
      </ListItem>
      {!isLoggedIn(currentUser) && (
        <ListItem className={classes.listItem}>
          <Link
            className={whiteText ? classes.whiteText : classes.blackText}
            to="/login"
          >
            <Button color="transparent" className={classes.navLink}>
              Inicia Sesión
            </Button>
          </Link>
        </ListItem>
      )}
      {isLoggedIn(currentUser) && (
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            buttonText={
              <>
                <span
                  className={whiteText ? classes.whiteText : classes.blackText}
                  style={{ marginRight: 8 }}
                >
                  {currentUser?.dataValues?.name}{" "}
                </span>
                <AccountCircleIcon
                  className={`${classes.headerSocialIcon} ${
                    whiteText ? classes.whiteFill : classes.blackFill
                  }`}
                />
              </>
            }
            buttonProps={{
              color: "transparent",
              className: "headerProfileLink",
            }}
            dropdownList={[
              <>
                {isAdmin(currentUser) && (
                  <Link
                    className={`${classes.dropdownLink} ${classes.navLink} ${classes.blackText}`}
                    to="/admin/users"
                  >
                    Admin
                  </Link>
                )}
              </>,
              <Link
                className={`${classes.dropdownLink} ${classes.navLink} ${classes.blackText}`}
                to={
                  isAdmin(currentUser)
                    ? `/admin/users/${currentUser?.dataValues?.id}`
                    : `/profile/${currentUser?.dataValues?.id}`
                }
              >
                Mi Perfil
              </Link>,
              <Link
                className={`${classes.dropdownLink} ${classes.navLink} ${classes.blackText}`}
                to="#"
                onClick={onLogout}
              >
                Cerrar Sesión
              </Link>,
            ]}
          />
        </ListItem>
      )}
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "white",
};

HeaderLinks.propTypes = {
  primaryColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
