import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ActionButton from "components/CustomButtons/ActionButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import CustomConfirmAlert from "components/CustomConfirmAlert/CustomConfirmAlert";
import userService from "services/user.service";

const useStyles = makeStyles((theme) => ({
  root: {},
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "white",
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  circularProgress: {
    color: theme.palette.secondary.main,
  },
}));

const Results = ({
  className,
  users,
  fetchUsers,
  totalItems,
  isLoading,
  limit,
  setLimit,
  page,
  setPage,
  search,
  ...rest
}) => {
  const classes = useStyles();
  const [deletingUser, setDeletingUser] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const handleLimitChange = (event) => {
    const { value: newLimit } = event.target;
    setPage(0);
    fetchUsers(0, newLimit, search);
    setLimit(newLimit);
  };

  const handlePageChange = (event, newPage) => {
    fetchUsers(newPage, limit, search);
    setPage(newPage);
  };

  const handleDeleteItem = (user) => {
    setDeletingUser(user);
    setOpenDialog(true);
  };

  useEffect(() => {
    async function fetchData() {
      await fetchUsers(page, limit, search);
    }
    fetchData();
  }, [fetchUsers, page, limit, search]);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      style={{ overflowX: "auto" }}
    >
      <CustomConfirmAlert
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={async () => {
          await userService.deleteUser(deletingUser.id);
          await fetchUsers(0, 50, search);
        }}
        title={"Eliminar usuario"}
        errorMessage={"Error al eliminar usuario"}
        successMessage={"Usuario eliminado exitosamente"}
        warningMessage={`¿Estás seguro de que deseas eliminar el usuario ${deletingUser.name} ${deletingUser.lastName} ? Esta acción no puede deshacerse.`}
      />
      <Box minWidth={1050}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Estatus</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.slice(0, limit).map((user) => (
              <TableRow hover key={user.id}>
                <TableCell>
                  <Box alignItems="center" display="flex">
                    <Typography color="textPrimary" variant="body1">
                      <Link to={`/admin/users/${user.id}`}>
                        {user.name} {user.lastName}
                      </Link>
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>{user.active ? "Activo" : "Inactivo"}</TableCell>
                <TableCell>
                  <ActionButton
                    action="edit"
                    href={`/admin/users/${user.id}/edit`}
                  />
                  <ActionButton
                    action="delete"
                    func={() => handleDeleteItem(user)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      {isLoading && (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.circularProgress} size="100px" />
        </div>
      )}

      <TablePagination
        component="div"
        count={totalItems}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array,
  fetchUsers: PropTypes.func,
  totalItems: PropTypes.number,
  isLoading: PropTypes.bool,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  search: PropTypes.string,
};

export default Results;
