import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import CustomMultiSelect from "components/CustomMultiSelect/CustomMultiSelect.js";
import InstructorService from "services/instructor.service";
import CustomSelect from "components/CustomSelect/CustomSelect";
import moment from "moment";
import "moment/locale/es";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import CustomInput from "components/CustomInput/CustomInput.js";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  dateGridItem: {
    marginTop: theme.spacing(3),
  },
  inputItem: {
    marginTop: theme.spacing(-2),
  },
}));

function Form({ schedule, setSchedule }) {
  const classStyles = useStyles();
  const [instructors, setInstructors] = useState([]);
  const weekdayNames = [
    { value: "Domingo", text: "Domingo" },
    { value: "Lunes", text: "Lunes" },
    { value: "Martes", text: "Martes" },
    { value: "Miércoles", text: "Miércoles" },
    { value: "Jueves", text: "Jueves" },
    { value: "Viernes", text: "Viernes" },
    { value: "Sábado", text: "Sábado" },
  ];

  useEffect(() => {
    async function fetchClassTypes() {
      const {
        data: { instructors: instructorsRes },
      } = await InstructorService.getInstructorsByClassType(
        schedule.classTypeId
      );

      setInstructors(
        instructorsRes.map((instructor) => {
          return {
            value: instructor.id,
            text: `${instructor.firstName} ${instructor.lastName}`,
          };
        })
      );
    }
    if (schedule.classTypeId) {
      fetchClassTypes();
    }
  }, [schedule.classTypeId]);

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    setSchedule({
      ...schedule,
      [name]: value,
    });
  };

  const handleMultiSelectChange = (event) => {
    event.persist();
    const { checked, value } = event.target;
    const weekdays = schedule.weekdays;

    if (checked) {
      weekdays.push(value);
    } else {
      const index = weekdays.indexOf(value);
      if (index > -1) {
        weekdays.splice(index, 1);
      }
    }

    setSchedule({
      ...schedule,
      weekdays,
    });
  };

  const handleTimeChange = (event, isStart) => {
    const time = moment(event).format("HH:mm");
    if (isStart) {
      setSchedule({
        ...schedule,
        start: time,
      });
    } else {
      setSchedule({
        ...schedule,
        end: time,
      });
    }
  };

  return (
    <>
      <h3>Detalles de horario</h3>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3} className={classStyles.selectGridItem}>
          <CustomSelect
            id="instructor"
            labelText="Instructor"
            inputProps={{
              name: "instructorId",
              id: "instructor-select",
              disabled: false,
              onChange: handleInputChange,
              value: schedule.instructorId,
            }}
            options={instructors}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} className={classStyles.inputItem}>
          <CustomInput
            labelText="Tipo"
            id="subtype"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: schedule.subtype,
              onChange: handleInputChange,
              name: "subtype",
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} className={classStyles.dateGridItem}>
          <FormControl fullWidth>
            <Datetime
              locale="es"
              dateFormat={false}
              inputProps={{
                name: "start",
                placeholder: "Inicio",
                value: moment(schedule.start, "HH:mm").format("hh:mm A"),
              }}
              initialViewDate={moment(schedule.end, "HH:mm").format("hh:mm A")}
              timeFormat="hh:mm A"
              onChange={(event) => {
                handleTimeChange(event, true);
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={3} className={classStyles.dateGridItem}>
          <FormControl fullWidth>
            <Datetime
              locale="es"
              dateFormat={false}
              inputProps={{
                name: "end",
                placeholder: "Fin",
                value: moment(schedule.end, "HH:mm").format("hh:mm A"),
              }}
              initialViewDate={moment(schedule.end, "HH:mm").format("hh:mm A")}
              timeFormat="hh:mm A"
              onChange={(event) => {
                handleTimeChange(event, false);
              }}
            />
          </FormControl>
        </GridItem>
      </GridContainer>
      <Divider variant="middle" className={classStyles.divider} />
      <h3>Días de la semana</h3>
      <GridContainer alignContent="flex-end" alignItems="flex-end">
        <GridItem xs={12} sm={12} md={4}>
          <CustomMultiSelect
            labelText="Brands"
            id="weekdays-multiselect"
            value={schedule.weekdays}
            onChange={handleMultiSelectChange}
            options={weekdayNames}
          />
        </GridItem>
      </GridContainer>
      <Divider variant="middle" className={classStyles.divider} />
    </>
  );
}

Form.propTypes = {
  schedule: PropTypes.object,
  setSchedule: PropTypes.func,
};

export default Form;
