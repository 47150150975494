/*eslint-disable*/
import React, { useEffect, useState } from "react";
import userService from "services/user.service";
import { useParams } from "react-router";
// @material-ui/core components
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import image from "assets/img/Calisto-163.jpg";
import { NavLink as RouterLink } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import SocialFooter from "components/SocialFooter/SocialFooter";
import SectionsHeader from "components/SectionsHeader";
import CustomFooter from "components/CustomFooter/CustomFooter";

export default function LoginPage() {
  const useStyles = makeStyles(loginPageStyle);
  const { id: userId, verificationToken } = useParams();

  const [verifyResult, setVerifyResult] = useState("Verificando...");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await userService.verifyUser(
          userId,
          verificationToken
        );
        setVerifyResult(
          `${data.name}, tu cuenta ha sido activada exitosamente`
        );
        setSuccess(true);
      } catch (error) {
        const {
          response: {
            data: { error: errorData },
          },
        } = error;
        setVerifyResult(errorData);
        throw error;
      }
    }
    fetchData();
  }, []);

  const classes = useStyles();

  return (
    <div>
      <SectionsHeader whiteText />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader
                  color="primary"
                  signup
                  className={classes.cardHeader}
                >
                  <h3 className={classes.cardTitle}>Activación de Cuenta</h3>
                </CardHeader>
                <CardBody signup style={{ textAlign: "center" }}>
                  {success && (
                    <CheckIcon
                      style={{ fontSize: "4rem" }}
                      className={classes.checkIcon}
                    ></CheckIcon>
                  )}
                  {!success && (
                    <ErrorIcon
                      style={{ fontSize: "4rem" }}
                      className={classes.checkIcon}
                    ></ErrorIcon>
                  )}
                  <h4>{verifyResult}</h4>
                </CardBody>
                <div className={classes.textCenter}>
                  <Button
                    simple
                    color="primary"
                    size="lg"
                    type="submit"
                    component={RouterLink}
                    to={"/login"}
                  >
                    Ir a Inicio de Sesión
                  </Button>
                </div>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
