import React, { useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import {
  calistoColor,
  bloomColor,
  redChartColor,
} from "assets/jss/material-kit-pro-react.js";

const useStyles = makeStyles(() => ({
  root: {},
}));

const CustomersByDay = ({ className, customersAvgDay, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const brands = customersAvgDay?.brands?.map((brand) => brand.name);

  const datasets = brands?.map((brand) => {
    const data = Object.entries(customersAvgDay)?.map(([, custday]) => {
      return custday[brand]?.avg;
    });
    let color;
    switch (brand) {
      case "Calisto":
        color = calistoColor;
        break;
      case "Bloom":
        color = bloomColor;
        break;
      default:
        color = redChartColor;
        break;
    }
    return {
      backgroundColor: color,
      data,
      label: brand,
    };
  });

  const data = {
    datasets,
    labels: [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
      "Domingo",
    ],
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  useEffect(() => {}, []);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Clientes promedio por día" />
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

CustomersByDay.propTypes = {
  className: PropTypes.string,
  customersAvgDay: PropTypes.object,
};

export default CustomersByDay;
