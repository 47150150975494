/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomCurrency from "components/CustomCurrency/CustomCurrency";
import { Link } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {},
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "white",
  },
  tableResponsive: {
    minHeight: "0.1%",
    overflowX: "auto",
    display: "block",
    whiteSpace: "nowrap",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  circularProgress: {
    color: theme.palette.secondary.main,
  },
}));

const Results = ({
  className,
  classType,
  limit,
  setLimit,
  page,
  setPage,
  payments,
  isLoading,
  fetchData,
  totalPayments,
  ...rest
}) => {
  const stylePayments = useStyles();

  useEffect(() => {
    fetchData(classType);
  }, [classType, limit, page, fetchData]);

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const normalizePaymentStatus = (status) => {
    if (status === "completed") {
      return "Completado";
    }

    if (status === "charge_pending") {
      return "Cargo pendiente";
    }

    return status;
  };

  return (
    <Card
      className={clsx(stylePayments.root, className)}
      {...rest}
      style={{ overflowX: "scroll" }}
    >
      {isLoading && (
        <div className={stylePayments.progressContainer}>
          <CircularProgress
            className={stylePayments.circularProgress}
            size="100px"
          />
        </div>
      )}

      {!isLoading && (
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {classType === "all" && <TableCell>Brand</TableCell>}
                <TableCell>Usuario</TableCell>
                <TableCell>Concepto/Paquete</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Método</TableCell>
                <TableCell>Estatus</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments &&
                payments.map((payment, i) => (
                  <TableRow hover key={`${payment.id}-${i}`}>
                    {classType === "all" && (
                      <TableCell>{payment.classType}</TableCell>
                    )}
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Typography color="textPrimary" variant="body1">
                          {payment.user_name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{payment.concept}</TableCell>
                    <TableCell>
                      <CustomCurrency quantity={payment.amount} />
                    </TableCell>
                    <TableCell>
                      {moment(payment.date).utc().format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {payment.method === "OpenPay" && (
                        <Link
                          to={{ pathname: payment.customer_url }}
                          target="_blank"
                        >
                          {payment.method}
                        </Link>
                      )}

                      {payment.method !== "OpenPay" && payment.method}
                    </TableCell>
                    <TableCell>
                      {normalizePaymentStatus(payment.status)}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      )}

      <TablePagination
        component="div"
        count={totalPayments}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  classType: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  payments: PropTypes.array,
  isLoading: PropTypes.bool,
  fetchData: PropTypes.func,
  totalPayments: PropTypes.number,
};

export default Results;
