/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Check from "@material-ui/icons/Check";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import SocialFooter from "components/SocialFooter/SocialFooter.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import image from "assets/img/Calisto-296.jpg";
import SessionContext from "contexts/SessionContext";
import sessionService from "services/session.service";
import { Link } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import LoadingContext from "contexts/LoadingContext";
import SectionsHeader from "components/SectionsHeader";
import CustomFooter from "components/CustomFooter/CustomFooter";

export default function LoginPage(props) {
  const { setLoading } = useContext(LoadingContext);
  const [openSnack, setOpenSnack] = useState(false);
  const [serverResponse, setServerResponse] = useState({
    status: "success",
    message: "",
  });
  const useStyles = makeStyles(loginPageStyle);
  const { setCurrentUser } = useContext(SessionContext);
  const [rememberMe, setRememberMe] = useState(false);
  const history = useHistory();

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data: loggedUser } = await sessionService.login(loginData);
      setCurrentUser(loggedUser);
      sessionStorage.setItem("storedUser", JSON.stringify(loggedUser));
      sessionStorage.setItem("clusterToken", loggedUser.token);
      if (rememberMe) {
        localStorage.setItem("storedUser", JSON.stringify(loggedUser));
      }
      setLoading(false);
      history.push({
        pathname: "/",
        state: {
          feedback: `Bienvenido(a), ${loggedUser.dataValues.name}. Has iniciado sesión correctamente`,
        },
      });
    } catch (error) {
      setLoading(false);
      const {
        response: {
          data: { error: errorData },
        },
      } = error;
      let message = "";
      if (
        errorData?.includes("no existe") ||
        errorData?.includes("Contraseña incorrecta")
      ) {
        message = "Usario o contraseña incorrectos";
      } else if (errorData?.includes("no ha verificado")) {
        message = "Por favor verifica tu cuenta antes de iniciar sesión";
      } else if (errorData?.includes("no está activo")) {
        message =
          "Este usuario ha sido desactivado. Por favor, ponte en contacto con un administrador";
      } else {
        message =
          "Ocurrió un error al iniciar sesión. Por favor inténtalo de nuevo más tarde";
      }
      setServerResponse({
        status: "error",
        message,
      });
      setOpenSnack(true);
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleToggle = (value) => {
    setRememberMe(value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  useEffect(() => {
    if (props.history.location.state) {
      setServerResponse({
        status: props.history.location.state.status || "success",
        message: props.history.location.state.feedback,
      });
      setOpenSnack(true);
    }
  }, [props.history.location.state]);

  const classes = useStyles();

  return (
    <div>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      <SectionsHeader whiteText></SectionsHeader>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form} onSubmit={onFormSubmit}>
                  <CardHeader
                    color="primary"
                    signup
                    className={classes.cardHeader}
                  >
                    <h3 className={classes.cardTitle}>Inicia Sesión</h3>
                    {/* <div className={classes.socialLine}>
                      <Button
                        justIcon
                        color="transparent"
                        className={classes.iconButtons}
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fab fa-twitter" />
                      </Button>
                      <Button
                        justIcon
                        color="transparent"
                        className={classes.iconButtons}
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fab fa-facebook" />
                      </Button>
                      <Button
                        justIcon
                        color="transparent"
                        className={classes.iconButtons}
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fab fa-google-plus-g" />
                      </Button>
                    </div> */}
                  </CardHeader>
                  <CardBody signup>
                    <CustomInput
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Email",
                        type: "email",
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        value: loginData.email,
                        name: "email",
                        required: true,
                        onChange: handleInputChange,
                      }}
                    />
                    <CustomInput
                      id="pass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Contraseña",
                        type: "password",
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon className={classes.inputIconsColor}>
                              lock_utline
                            </Icon>
                          </InputAdornment>
                        ),
                        value: loginData.password,
                        name: "password",
                        required: true,
                        onChange: handleInputChange,
                      }}
                    />
                    <FormControlLabel
                      classes={{
                        label: classes.label,
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(!rememberMe)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                          checked={rememberMe}
                        />
                      }
                      label={<span>Recuérdame</span>}
                    />
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button simple color="primary" size="lg" type="submit">
                      Ingresar
                    </Button>
                  </div>

                  <div className={classes.registerLinkContainer}>
                    <p>¿Olvidaste tu contraseña?</p>{" "}
                    <Link
                      to="/recover-account"
                      className={classes.registerLink}
                    >
                      Recupérala
                    </Link>
                  </div>
                  <div className={classes.registerLinkContainer}>
                    <p>¿Aún no tienes una cuenta?</p>{" "}
                    <Link to="/signup" className={classes.registerLink}>
                      Regístrate
                    </Link>
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
