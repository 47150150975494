/*eslint-disable*/
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import CalendarIcon from "@material-ui/icons/CalendarToday";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import image from "assets/img/Calisto-153.jpg";
import LoadingContext from "contexts/LoadingContext";
import SectionsHeader from "components/SectionsHeader";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import DatetimeRange from "react-datetime-range-picker";
import moment from "moment";
import "./calendar.css";
import classService from "services/class.service";
import CustomFooter from "components/CustomFooter/CustomFooter";
import calendarStyle from "./calendarPageStyle";
import SessionContext from "contexts/SessionContext";
import calistoLogo from "assets/img/CalistoLogo.png";
import bloomLogo from "assets/img/BloomLogo.png";
import { useParams } from "react-router";
import { isLoggedIn } from "utils/session-utils";

import CustomConfirmAlert from "components/CustomConfirmAlert/CustomConfirmAlert";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(calendarStyle);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Calendar = () => {
  const query = useQuery();
  const start = query.get("start");
  const end = query.get("end");

  const validDates = () => {
    const auxStart = moment(start);
    const auxEnd = moment(end);

    return (
      auxEnd.diff(auxStart, "days") === 6 &&
      auxStart.format("E") === "1" &&
      auxEnd.format("E") === "7"
    );
  };

  const { setLoading } = useContext(LoadingContext);
  const { currentUser } = useContext(SessionContext);
  const classes = useStyles();
  const history = useHistory();
  const [startDate, setStartDate] = useState(
    start && validDates() ? moment(start) : moment().startOf("week")
  );

  const [endDate, setEndDate] = useState(
    end && validDates() ? moment(end) : moment().endOf("week")
  );
  const [lessons, setLessons] = useState([]);
  const { brandId } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [warningMsg, setWarningMsg] = useState(
    "¿Seguro que desea reservar la clase?"
  );
  const [selectedClass, setSelectedClass] = useState();

  const onSignUpClick = async (lesson) => {
    try {
      if (!isLoggedIn(currentUser)) {
        history.push({
          pathname: "/login",
          state: {
            status: "info",
            feedback: `Por favor inicia sesión para reservar una clase`,
          },
        });
      } else {
        setLoading(true);
        setSelectedClass(lesson.id);
        const brand = brandId === "1" ? "Calisto" : "Bloom";
        let msg = ` - Clase: ${moment(lesson.date)
          .utc()
          .format("dddd DD [de] MMMM")} a las ${lesson.start} hrs.`;
        if (lesson.maxUsers && lesson.hasOwnProperty("count")) {
          msg += `\n - Lugares disponibles: ${
            lesson.maxUsers - lesson.count
          } de ${lesson.maxUsers}.`;
        }
        if (lessons[0].hasOwnProperty("available")) {
          const av = lessons[0].available;
          msg += `\n - Clases disponibles para ${brand}: ${av}`;

          if (av === 0) {
            msg += `\n\nSe creará tu reservación con pago pendiente. Favor de pagar el día de la clase en recepción.`;
          } else {
            msg += `\n\nRecuerda que se te descontará una clase disponible al realizar la reservación.`;
          }
        } else {
          msg += `\n - Clases disponibles para ${brand}: 0\n\nSe creará tu reservación con pago pendiente. Favor de pagar el día de la clase en recepción.`;
        }
        setWarningMsg(msg);
        setOpenDialog(true);
        setLoading(false);
      }
    } catch (error) {
      await fetchClasses();
      setLoading(false);
    }
  };

  const hours = [
    "5:00",
    "6:00",
    "7:00",
    "8:00",
    "9:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
  ];

  const classCard = (currentClass, key) => {
    const {
      maxUsers,
      count,
      signedUp,
      start,
      end,
      subtype,
      instructor,
      date,
    } = currentClass;
    const avPlaces =
      maxUsers && currentClass.hasOwnProperty("count") ? maxUsers - count : 1;
    const buttonText = signedUp
      ? "Reservada"
      : avPlaces > 0
      ? "Reservar"
      : "Cupo lleno";

    const dateTime = `${moment(date).utc().format("YYYY/MM/DD")} ${end}`;
    const tooltip =
      moment(dateTime).add(10, "minutes").isBefore(moment()) &&
      buttonText === "Reservar"
        ? "No se pueden reservar clases pasadas"
        : "";
    const buttonDisabled =
      signedUp ||
      avPlaces === 0 ||
      moment(dateTime).add(10, "minutes").isBefore(moment());
    const color = buttonDisabled ? "gray" : "primary";
    return (
      <Card className={classes.eventCard} key={key}>
        <CardBody>
          <h4 className={classes.cardTitle}>{start}</h4>

          <p className={classes.cardDescription}>
            {subtype ? `Tipo: ${subtype}` : "Tipo: Regular"}
          </p>

          <p className={classes.cardDescription}>Instructor: {instructor}</p>
          <div className={classes.textCenter}>
            <Tooltip title={tooltip}>
              <span>
                <Button
                  round
                  color={color}
                  disabled={buttonDisabled}
                  onClick={() => onSignUpClick(currentClass)}
                >
                  {buttonText}
                </Button>
              </span>
            </Tooltip>
          </div>
        </CardBody>
      </Card>
    );
  };

  const emptyCard = (key) => (
    <Card
      key={key}
      className={`${classes.eventCard} ${classes.emptyEventCard}`}
    ></Card>
  );

  const buildCalendar = (weekDay, cls) => {
    const format = "hh:mm";
    const cards = [];

    hours.forEach((hour) => {
      const slotStart = moment(hour, format);
      const slotEnd = moment(slotStart).add(1, "hours");
      const currentClass = cls[0];
      if (currentClass) {
        let start = moment(currentClass.start, format);
        if (start.isBetween(slotStart, slotEnd, null, "[)")) {
          cards.push(classCard(currentClass, weekDay + hour));

          while (start.isBetween(slotStart, slotEnd, null, "[)") && cls[0]) {
            cls.shift();
            start = cls[0] ? moment(cls[0].start, format) : start;
          }
        } else {
          cards.push(emptyCard(weekDay + hour));
        }
      } else {
        cards.push(emptyCard(weekDay + hour));
      }
    });
    return cards;
  };

  const updateUrl = (startParam, endParam) => {
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?start=${startParam.format("MM/DD/YY")}&end=${endParam.format(
      "MM/DD/YY"
    )}`;
    window.history.replaceState({ path: newurl }, "", newurl);
  };

  const handleDateChange = ({ start }) => {
    const auxStart = moment(start).startOf("week");
    const auxEnd = moment(start).endOf("week");

    setStartDate(auxStart);
    setEndDate(auxEnd);

    updateUrl(auxStart, auxEnd);
  };

  const fetchClasses = useCallback(async () => {
    const { data } = await classService.getCalendarClasses(
      startDate.format("DD/MM/YYYY"),
      endDate.format("DD/MM/YYYY"),
      brandId
    );
    setLessons(data);
  }, [startDate, endDate, brandId]);

  useEffect(() => {
    fetchClasses();
  }, [brandId, startDate, endDate, openDialog]);

  useEffect(() => {
    if (!validDates()) {
      updateUrl(moment().startOf("week"), moment().endOf("week"));
    }
  }, []);

  return (
    <div>
      <CustomConfirmAlert
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={async () => {
          setOpenDialog(false);
          await classService.signUp(selectedClass, currentUser.dataValues.id);
          await fetchClasses();
          setLoading(false);
        }}
        onCancel={async () => {
          setOpenDialog(false);
          await fetchClasses();
          setLoading(false);
        }}
        title={"Confirma los datos"}
        errorMessage={
          "Ocurrió un error al reservar la clase. Por favor, inténtalo de nuevo más tarde"
        }
        successMessage={
          "Reservación creada exitosamente. Recibirás un correo de confirmación"
        }
        warningMessage={warningMsg}
      />
      <SectionsHeader whiteText />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.calendarContainer}>
          <GridContainer
            justify="center"
            className={`${classes.gridContainer} ${classes.weekPickerContainer}`}
          >
            <GridItem
              xs={12}
              style={{
                textAlign: "center",
                marginBottom: 20,
                paddingLeft: 0,
              }}
            >
              {" "}
              <img
                className={classes.brandsLogo}
                src={brandId === "1" ? calistoLogo : bloomLogo}
              />
            </GridItem>
            <GridItem
              xs={2}
              sm={1}
              md={1}
              style={{
                textAlign: "right",
                marginTop: 10,
              }}
            >
              <CalendarIcon style={{ marginLeft: "-200px !important" }} />
            </GridItem>
            <GridItem xs={10} sm={8} md={2} className="weekPickerItem">
              <DatetimeRange
                inline={true}
                input={false}
                closeOnSelect={true}
                startDate={startDate}
                endDate={endDate}
                locale="es"
                timeFormat={false}
                onChange={handleDateChange}
                pickerClassName={classes.inputDateContainer}
                inputProps={{
                  className: `${classes.inputDate} form-control`,
                  value: `${moment(startDate).format("DD/MM/YYYY")} - ${moment(
                    endDate
                  ).format("DD/MM/YYYY")}`,
                }}
              />
            </GridItem>
          </GridContainer>

          <GridContainer
            align="center"
            className={`${classes.gridContainer} ${classes.calendarDaysContainer}`}
          >
            <GridItem xs={12} sm={12} md={1} className={classes.hoursContainer}>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.emptyCell}
                ></GridItem>
                {hours.map((hour) => (
                  <GridItem
                    key={hour}
                    className={classes.hourContainer}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    {hour}
                  </GridItem>
                ))}
              </GridContainer>
            </GridItem>
            {lessons.map((day) => (
              <GridItem
                xs={12}
                sm={12}
                md
                key={day.day}
                className={classes.columnGridItem}
              >
                <GridContainer>
                  <GridItem xs={12} className={classes.weekDayContainer}>
                    {day.day}
                  </GridItem>
                  {buildCalendar(day.day, day.classes)}
                </GridContainer>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      </div>
      <CustomFooter></CustomFooter>
    </div>
  );
};

export default Calendar;
