import React, { useEffect, useContext } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  BarChart as BarChartIcon,
  User as UserIcon,
  Users as UsersIcon,
} from "react-feather";
import {
  AttachMoney,
  FitnessCenter,
  ExitToApp,
  CalendarToday,
  DirectionsRun,
  List as ListIcon,
} from "@material-ui/icons";
import NavItem from "./NavItem";
import SessionContext from "contexts/SessionContext";

const items = [
  {
    href: "/admin/users",
    icon: UsersIcon,
    title: "Usuarios",
  },
  {
    href: "/admin/brands",
    icon: FitnessCenter,
    title: "Brands",
  },
  {
    href: "/admin/packages",
    icon: ListIcon,
    title: "Paquetes",
  },
  {
    href: "/admin/payments",
    icon: AttachMoney,
    title: "Pagos",
  },
  {
    href: "/admin/classes",
    icon: CalendarToday,
    title: "Clases",
  },
  {
    href: "/admin/instructors",
    icon: DirectionsRun,
    title: "Instructores",
  },
  {
    href: "/admin/reports",
    icon: BarChartIcon,
    title: "Reportes",
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { currentUser, setCurrentUser } = useContext(SessionContext);

  const onLogout = () => {
    sessionStorage.removeItem("storedUser");
    sessionStorage.removeItem("clusterToken");
    localStorage.removeItem("storedUser");
    localStorage.removeItem("clusterToken");
    setCurrentUser({});
    window.location.href = "/";
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={currentUser.avatar}
          to={`/admin/users/${currentUser.dataValues?.id}`}
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="subtitle1"
        >
          {currentUser.dataValues?.name} {currentUser.dataValues?.lastName}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
          <Hidden lgUp>
            <NavItem
              href={`/admin/users/${currentUser?.dataValues?.id}`}
              title="Perfil"
              icon={UserIcon}
            />
            <NavItem
              href="#"
              onClick={onLogout}
              title="Cerrar Sesión"
              icon={ExitToApp}
            />
          </Hidden>
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
