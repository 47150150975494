import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import "moment/locale/es";
import ScheduleService from "services/schedule.service";
import CustomConfirmAlert from "components/CustomConfirmAlert/CustomConfirmAlert";
import ActionButton from "components/CustomButtons/ActionButton";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Results = ({ className, schedules, fetchData, ...rest }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [deletingSchedule, setDeletingSchedule] = useState({});

  const handleDeleteItem = (schedule) => {
    setDeletingSchedule(schedule);
    setOpenDialog(true);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      style={{ overflowX: "auto" }}
    >
      <CustomConfirmAlert
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={async () => {
          await ScheduleService.deleteSchedules(deletingSchedule.ids);
          await fetchData();
        }}
        title={"Eliminar horario"}
        errorMessage={"Error al eliminar horario"}
        successMessage={"Horario eliminado"}
        warningMessage={
          "¿Estás seguro de que deseas eliminar el horario? Esta acción no puede deshacerse."
        }
      />
      <Box minWidth={1050}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Instructor</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Inicio</TableCell>
              <TableCell>Fin</TableCell>
              <TableCell>Días</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.length > 0 &&
              schedules.map((schedule, i) => (
                <TableRow hover key={i}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Typography color="textPrimary" variant="body1">
                        {`${schedule.firstName} ${schedule.lastName}`}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{schedule.subtype || "-"}</TableCell>
                  <TableCell>{schedule.start || "-"}</TableCell>
                  <TableCell>{schedule.end || "-"}</TableCell>
                  <TableCell>
                    {schedule.weekdays
                      .split(",")
                      .sort((a, b) => {
                        return (
                          moment(a, "ddd dddd").weekday() >
                          moment(b, "ddd dddd").weekday()
                        );
                      })
                      .join(" ")}
                  </TableCell>
                  <TableCell>
                    <ActionButton
                      action="delete"
                      func={() => handleDeleteItem(schedule)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  schedules: PropTypes.array,
  fetchData: PropTypes.func,
};

export default Results;
