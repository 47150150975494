import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ActionButton from "components/CustomButtons/ActionButton";
import ClassTypeService from "services/classType.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomConfirmAlert from "components/CustomConfirmAlert/CustomConfirmAlert";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "white",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  circularProgress: {
    color: theme.palette.secondary.main,
  },
}));

const Results = ({
  className,
  fetchData,
  limit,
  setLimit,
  page,
  setPage,
  classTypes,
  isLoading,
  totalClassTypes,
  ...rest
}) => {
  const styleClasses = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [deletingClassType, setDeletingClassType] = useState({});

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleDeleteItem = (classType) => {
    setDeletingClassType(classType);
    setOpenDialog(true);
  };

  return (
    <Card
      className={clsx(styleClasses.root, className)}
      {...rest}
      style={{ overflowX: "auto" }}
    >
      <CustomConfirmAlert
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={async () => {
          await ClassTypeService.deleteClassType(deletingClassType.id);
          await fetchData();
        }}
        title={"Eliminar brand"}
        errorMessage={"Error al eliminar brand"}
        successMessage={"Brand eliminado"}
        warningMessage={
          "¿Estás seguro de que deseas eliminar brand? Esta acción no puede deshacerse."
        }
      />
      <Box minWidth={1050}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Capacidad</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classTypes &&
              classTypes.map((classType) => (
                <TableRow hover key={classType.id}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Typography color="textPrimary" variant="body1">
                        <Link to={`/admin/brands/${classType.id}`}>
                          {classType.name && classType.name}
                        </Link>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {classType.capacity ? classType.capacity : "-"}
                  </TableCell>
                  <TableCell>
                    <ActionButton
                      action="edit"
                      href={`/admin/brands/edit/${classType.id}`}
                    />
                    <ActionButton
                      action="delete"
                      func={() => handleDeleteItem(classType)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
      {isLoading && (
        <div className={styleClasses.progressContainer}>
          <CircularProgress
            className={styleClasses.circularProgress}
            size="100px"
          />
        </div>
      )}
      <TablePagination
        component="div"
        count={totalClassTypes}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  classTypes: PropTypes.array,
  totalClassTypes: PropTypes.number,
  fetchData: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default Results;
