import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput.js";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
}));

function Form({ classType, setClassType }) {
  const classStyles = useStyles();

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    setClassType({
      ...classType,
      [name]: value,
    });
  };

  return (
    <>
      <h3>Detalles de brand</h3>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Nombre"
            id="name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: classType.name,
              onChange: handleInputChange,
              name: "name",
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Capacidad"
            id="capacity"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: classType.capacity,
              onChange: handleInputChange,
              name: "capacity",
            }}
          />
        </GridItem>
      </GridContainer>
      <Divider variant="middle" className={classStyles.divider} />
    </>
  );
}

Form.propTypes = {
  classType: PropTypes.object,
  setClassType: PropTypes.func,
};

export default Form;
