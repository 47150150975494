import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import "moment/locale/es";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ClassTypeService from "services/classType.service";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  selectGridItem: {
    marginTop: theme.spacing(2),
  },
  dateGridItem: { marginTop: theme.spacing(4.5) },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
}));

function Form({ classPackage, setClassPackage, editing = false }) {
  const classStyles = useStyles();
  const [classTypes, setClassTypes] = useState([]);

  useEffect(() => {
    async function fetchClassTypes() {
      const {
        data: { classTypes: classTypesRes },
      } = await ClassTypeService.getClassTypes(50, 0);

      setClassTypes(
        classTypesRes.map((classType) => {
          return { value: classType.id, text: classType.name };
        })
      );
    }
    fetchClassTypes();
  }, []);

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    setClassPackage({
      ...classPackage,
      [name]: value,
    });
  };

  const handleVisibilityChange = (event) => {
    const { checked } = event.target;

    setClassPackage({
      ...classPackage,
      visible: checked,
    });
  };

  const handlePackageTypeChange = (event) => {
    const { value } = event.target;

    const lessonsNum = value === "Monthly" ? "Ilimitado" : "0";

    setClassPackage({
      ...classPackage,
      lessonsNum,
      packageType: value,
    });
  };

  return (
    <>
      <h3>Detalles de paquete</h3>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3} className={classStyles.selectGridItem}>
          <CustomSelect
            id="classType"
            labelText="Brand"
            inputProps={{
              name: "classTypeId",
              id: "classType-select",
              onChange: handleInputChange,
              value: classPackage.classTypeId,
              required: true,
              disabled: editing,
            }}
            options={classTypes}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            labelText="Nombre"
            id="name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              placeholder: "15 clases",
              value: classPackage.name,
              onChange: handleInputChange,
              name: "name",
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} className={classStyles.selectGridItem}>
          <CustomSelect
            id="packageType"
            labelText="Tipo de Paquete"
            inputProps={{
              name: "packageType",
              id: "packageType-select",
              onChange: handlePackageTypeChange,
              value: classPackage.packageType,
              required: true,
              disabled: editing,
            }}
            options={[
              { value: "Monthly", text: "Mensual" },
              { value: "Classes", text: "No. de Clases" },
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            disabled={classPackage.packageType === "Monthly"}
            labelText="Número de clases"
            id="lessonsNum"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              placeholder: "15",
              value:
                classPackage.packageType === "Monthly"
                  ? "Ilimitado"
                  : classPackage.lessonsNum,
              onChange: handleInputChange,
              name: "lessonsNum",
              required: true,
              disabled: editing,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            labelText="Precio"
            id="price"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              placeholder: "100",
              value: classPackage.price,
              onChange: handleInputChange,
              name: "price",
              type: "number",
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4} style={{ paddingTop: 30 }}>
          <FormControlLabel
            control={
              <Switch
                name="visible"
                checked={classPackage.visible}
                onChange={handleVisibilityChange}
                value={classPackage.visible}
              />
            }
            label="Visible"
          />
        </GridItem>
      </GridContainer>
    </>
  );
}

Form.propTypes = {
  classPackage: PropTypes.object,
  setClassPackage: PropTypes.func,
  editing: PropTypes.bool,
};

export default Form;
