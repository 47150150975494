import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import "moment/locale/es";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ClassTypeService from "services/classType.service";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";

import PackageService from "services/package.service";
import UserService from "services/user.service";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  selectGridItem: {
    marginTop: theme.spacing(2),
  },
  dateGridItem: { marginTop: theme.spacing(4.5) },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
}));

function Form({ payment, setPayment, userPackage, setUserPackage }) {
  const classStyles = useStyles();
  const [classTypes, setClassTypes] = useState([]);
  const [classPackages, setClassPackages] = useState([]);
  const [users, setUsers] = useState([]);

  const [paymentType] = useState("registerPackage");

  useEffect(() => {
    async function fetchClassTypes() {
      const {
        data: { classTypes: classTypesRes },
      } = await ClassTypeService.getClassTypes();

      setClassTypes(
        classTypesRes.map((classType) => {
          return { value: classType.id, text: classType.name };
        })
      );
    }
    fetchClassTypes();
  }, []);

  useEffect(() => {
    async function fetchPackages() {
      const {
        data: { packages: packagesRes },
      } = await PackageService.getPackages(userPackage.classTypeId);

      setClassPackages(
        packagesRes.map((classPackage) => {
          return {
            value: classPackage.id,
            text: classPackage.name,
            price: classPackage.price,
          };
        })
      );
    }
    fetchPackages();
  }, [userPackage.classTypeId]);

  useEffect(() => {
    async function fetchUsers() {
      const { data: usersRes } = await UserService.getUsers();

      console.log("users", usersRes);

      setUsers(
        usersRes.map((user) => {
          return { value: user.id, text: `${user.name} ${user.lastName}` };
        })
      );
    }
    fetchUsers();
  }, []);

  const handlePackageInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    if (name === "classTypeId") {
      setUserPackage({
        ...userPackage,
        classTypeId: value,
        packageId: "",
      });
    } else if (name === "packageId") {
      setUserPackage({
        ...userPackage,
        packageId: value,
      });

      const selectedClassType = classTypes.find(
        ({ value: classTypeId }) => classTypeId === userPackage.classTypeId
      );

      const selectedPackage = classPackages.find(
        ({ value: packageId }) => packageId === value
      );

      setPayment({
        ...payment,
        concept: `Pago ${selectedPackage.text} ${selectedClassType.text}`,
        amount: selectedPackage.price,
      });
    } else {
      setUserPackage({
        ...userPackage,
        [name]: value,
      });
    }
  };

  const handlePaymentInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    setPayment({
      ...payment,
      [name]: value,
    });
  };

  // const handlePaymentTypeChange = (event) => {
  //   const { value } = event.target;

  //   setPaymentType(value);
  // };

  const handleDateChange = (event) => {
    const date = typeof event === "string" ? event : event.format("YYYY/MM/DD");
    setPayment({
      ...payment,
      date,
    });
  };

  return (
    <>
      {/* <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="packageType"
          name="packageType"
          value={paymentType}
          onChange={handlePaymentTypeChange}
        >
          <FormControlLabel
            value="registerPackage"
            control={<Radio color="primary" />}
            label="Registrar paquete"
            labelPlacement="start"
          />
          <FormControlLabel
            value="manual"
            control={<Radio color="primary" />}
            label="Manual"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl> */}
      {paymentType === "registerPackage" && (
        <>
          <h3>Detalles de paquete</h3>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={3}
              className={classStyles.selectGridItem}
            >
              <CustomSelect
                id="classType"
                labelText="Brand"
                inputProps={{
                  name: "classTypeId",
                  id: "classType-select",
                  onChange: handlePackageInputChange,
                  value: userPackage.classTypeId,
                }}
                options={classTypes}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={3}
              className={classStyles.selectGridItem}
            >
              <CustomSelect
                id="classPackage"
                labelText="Paquete"
                inputProps={{
                  name: "packageId",
                  id: "package-select",
                  onChange: handlePackageInputChange,
                  value: userPackage.packageId,
                }}
                options={classPackages}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={3}
              className={classStyles.selectGridItem}
            >
              <CustomSelect
                id="user"
                labelText="Cliente"
                inputProps={{
                  name: "userId",
                  id: "user-select",
                  onChange: handlePackageInputChange,
                  value: userPackage.userId,
                }}
                options={users}
              />
            </GridItem>
          </GridContainer>
        </>
      )}
      <br />
      <h3>Detalles de pago</h3>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            labelText="Concepto"
            id="concept"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: payment.concept,
              onChange: handlePaymentInputChange,
              name: "concept",
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            labelText="Cantidad"
            id="amount"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: payment.amount,
              onChange: handlePaymentInputChange,
              name: "amount",
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} className={classStyles.selectGridItem}>
          <CustomSelect
            id="method"
            labelText="Tipo"
            inputProps={{
              name: "method",
              id: "method-select",
              onChange: handlePaymentInputChange,
              value: payment.method,
            }}
            options={[
              { value: "Tarjeta", text: "Tarjeta" },
              { value: "Efectivo", text: "Efectivo" },
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} className={classStyles.dateGridItem}>
          <FormControl fullWidth required={true}>
            <Datetime
              locale="es"
              timeFormat={false}
              inputProps={{
                value: moment(payment.date).format("DD/MM/YYYY"),
                name: "date",
                required: true,
              }}
              onChange={handleDateChange}
            />
          </FormControl>
        </GridItem>
      </GridContainer>
      <div style={{ height: 300 }}></div>
    </>
  );
}

Form.propTypes = {
  payment: PropTypes.object,
  setPayment: PropTypes.func,
  userPackage: PropTypes.object,
  setUserPackage: PropTypes.func,
};

export default Form;
