import React, { useState, useEffect, useCallback } from "react";
import { Container, makeStyles, Box, Divider } from "@material-ui/core";
import Page from "components/Page";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import ActionButton from "components/CustomButtons/ActionButton";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useParams } from "react-router";
import ClassTypeService from "services/classType.service";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Muted from "components/Typography/Muted.js";
import Results from "./Results";
import PropTypes from "prop-types";
import ScheduleService from "services/schedule.service";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
}));

function ClassTypeDetailsView(props) {
  const classes = useStyles();
  const [classType, setClassType] = useState(undefined);
  const [schedules, setSchedules] = useState([]);

  const [openSnack, setOpenSnack] = React.useState(false);

  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });
  const { id: classTypeId } = useParams();

  const fetchData = useCallback(
    async function () {
      const { data } = await ClassTypeService.getClassType(classTypeId);
      setClassType(data);
    },
    [classTypeId]
  );

  const fetchSchedules = useCallback(
    async function () {
      const {
        data: { schedules: schedulesRes },
      } = await ScheduleService.getSchedules(classTypeId);
      setSchedules(schedulesRes);
    },
    [classTypeId]
  );

  useEffect(() => {
    if (props.history.location.state) {
      setServerResponse({
        status: "success",
        message: props.history.location.state.feedback,
      });
      setOpenSnack(true);
    }
  }, [props.history.location.state]);

  useEffect(() => {
    fetchData();
    fetchSchedules();
  }, [classTypeId, fetchData, fetchSchedules]);

  const handleClose = (event, reason) => {
    setOpenSnack(false);
    if (reason === "clickaway") {
      return;
    }
  };

  return (
    <Page className={classes.root} title="Detalles de brand">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={serverResponse.status}>
            <AlertTitle>
              {serverResponse.status === "success" ? "Éxito" : "Error"}
            </AlertTitle>
            {serverResponse.message}
          </Alert>
        </Snackbar>
        {classType !== undefined && (
          <Card>
            <CardHeader title="Brand"></CardHeader>
            <CardContent>
              <h3>Detalles de brand</h3>
              <GridContainer>
                <GridItem xs={12} md={4}>
                  <Muted>
                    <h4 className={classes.cardCategory}>
                      <strong>Nombre</strong>
                    </h4>
                  </Muted>
                  <p className={classes.description}>{classType.name}</p>
                </GridItem>
                <GridItem xs={12} md={4}>
                  <Muted>
                    <h4 className={classes.cardCategory}>
                      <strong>Capacidad</strong>
                    </h4>
                  </Muted>
                  <p className={classes.description}>
                    {classType.capacity ? classType.capacity : "-"}
                  </p>
                </GridItem>
              </GridContainer>
              <Divider variant="middle" className={classes.divider} />
              <h3>Horarios</h3>
              <Box display="flex" justifyContent="flex-end">
                <ActionButton
                  action="create"
                  href={`/admin/schedules/${classType.id}`}
                />
              </Box>
              <Results schedules={schedules} fetchData={fetchSchedules} />
            </CardContent>
          </Card>
        )}
      </Container>
    </Page>
  );
}

ClassTypeDetailsView.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        feedback: PropTypes.string,
      }),
    }),
  }),
};

export default ClassTypeDetailsView;
