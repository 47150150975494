/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Switch } from "react-router";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import LoadingContext from "contexts/LoadingContext";
import SessionContext from "contexts/SessionContext";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import HomePage from "views/HomePage/HomePage.js";
import DashboardLayout from "layouts/DashboardLayout";
import { blackColor } from "assets/jss/material-kit-pro-react";

import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import SignUpPage from "views/SignupPage/SignupPage";
import LoginPage from "views/LoginPage/LoginPage";
import VerifyPage from "views/VerifyPage/VerifyPage";
import EmailSentPage from "views/EmailSentPage/EmailSentPage";
import RecoverAccountPage from "views/RecoverAccountPage/RecoverAccountPage";
import ResetPasswordPage from "views/ResetPasswordPage/ResetPasswordPage";
import CalendarPage from "views/CalendarPage/CalendarPage";
import CalistoPage from "views/CalistoPage/CalistoPage";
import ChooseBrandPage from "views/ChooseBrandPage/ChooseBrandPage";
import ProfilePage from "views/Users/ProfilePage/ProfilePage";
import EditUserView from "views/Users/EditUser";
import EditPassword from "views/Users/EditPassword/EditPassword";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import BloomPage from "views/BloomPage/BloomPage";
import CalistoMembershipPage from "views/MembershipPage/CalistoMembershipPage";
import BloomMembershipPage from "views/MembershipPage/BloomMembershipPage";
import PaymentPage from "views/PaymentPage/PaymentPage";
import PaymentProcessedPage from "views/PaymentProcessedPage/PaymentProcessedPage";
// import OldPaymentPage from "views/OldPaymentPage/OldPaymentPage";

const hist = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blackColor,
    },
    secondary: {
      main: "#e8c900",
    },
    error: {
      main: "#d50000",
    },
    warning: {
      main: "#ffab00",
    },
  },
  spacing: 5,
});

function App() {
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const requireLoggedIn = (to, from, next) => {
    const storedUser =
      JSON.parse(sessionStorage.getItem("storedUser")) ||
      JSON.parse(localStorage.getItem("storedUser"));

    if (storedUser != null) {
      next();
    } else {
      next.redirect("/login");
    }
  };

  const requireAdmin = (to, from, next) => {
    const storedUser =
      JSON.parse(sessionStorage.getItem("storedUser")) ||
      JSON.parse(localStorage.getItem("storedUser"));

    if (storedUser.dataValues.roleId === 1) {
      next();
    } else {
      next.redirect("/");
    }
  };

  useEffect(() => {
    // Recover stored user information
    const storedUser =
      JSON.parse(sessionStorage.getItem("storedUser")) ||
      JSON.parse(localStorage.getItem("storedUser"));
    if (storedUser) {
      setCurrentUser(storedUser);
      sessionStorage.setItem("clusterToken", storedUser.token);
    }

    // Initialize OpenPay
    const openPayScript = document.createElement("script");

    openPayScript.src =
      "https://resources.openpay.mx/lib/openpay-js/1.2.38/openpay.v1.min.js";
    openPayScript.async = true;

    const antiFraudScript = document.createElement("script");

    antiFraudScript.src =
      "https://resources.openpay.mx/lib/openpay-data-js/1.2.38/openpay-data.v1.min.js";
    antiFraudScript.async = true;

    document.body.appendChild(openPayScript);
    document.body.appendChild(antiFraudScript);

    return () => {
      document.body.removeChild(openPayScript);
      document.body.removeChild(antiFraudScript);
    };
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <SessionContext.Provider value={{ currentUser, setCurrentUser }}>
        <LoadingContext.Provider value={{ setLoading }}>
          {loading && <LoadingSpinner />}
          <Router history={hist}>
            <GuardProvider>
              <Switch>
                <GuardedRoute
                  path="/admin"
                  component={DashboardLayout}
                  guards={[requireLoggedIn, requireAdmin]}
                />
                <GuardedRoute path="/landing" component={HomePage} />
                <GuardedRoute path="/signup" component={SignUpPage} />
                <GuardedRoute path="/login" component={LoginPage} />
                <GuardedRoute
                  path="/email-verify/:email"
                  component={EmailSentPage}
                />
                <GuardedRoute
                  path="/email-forgot/:email"
                  component={EmailSentPage}
                />
                <GuardedRoute
                  path="/recover-account"
                  component={RecoverAccountPage}
                />
                <GuardedRoute
                  path="/reset-password/:email/:resetToken"
                  component={ResetPasswordPage}
                />
                <GuardedRoute
                  path="/verify/:id/:verificationToken"
                  component={VerifyPage}
                />
                <GuardedRoute
                  path="/calendar/:brandId"
                  component={CalendarPage}
                />
                <GuardedRoute
                  path="/pago/:brand/:packageId"
                  component={PaymentPage}
                />
                <GuardedRoute path="/calisto" component={CalistoPage} />
                <GuardedRoute path="/bloom" component={BloomPage} />
                <GuardedRoute
                  path="/membresia/calisto"
                  component={CalistoMembershipPage}
                />
                <GuardedRoute
                  path="/membresia/bloom"
                  component={BloomMembershipPage}
                />
                <GuardedRoute path="/brands" component={ChooseBrandPage} />
                <GuardedRoute
                  path="/profile/:id/edit"
                  component={EditUserView}
                  guards={[requireLoggedIn]}
                />
                <GuardedRoute
                  path="/profile/:id/edit-password"
                  component={EditPassword}
                  guards={[requireLoggedIn]}
                />
                <GuardedRoute
                  path="/profile/:id"
                  component={ProfilePage}
                  guards={[requireLoggedIn]}
                />
                <GuardedRoute
                  path="/pago-procesado"
                  component={PaymentProcessedPage}
                />
                <GuardedRoute path="/" component={HomePage} />
              </Switch>
            </GuardProvider>
          </Router>
        </LoadingContext.Provider>
      </SessionContext.Provider>
    </MuiThemeProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
