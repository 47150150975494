/*eslint-disable*/
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import PersonIcon from "@material-ui/icons/Person";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import image from "assets/img/Calisto-169.jpg";
import Logo from "components/Logo";
import userService from "services/user.service";
import LoadingContext from "contexts/LoadingContext";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Link } from "react-router-dom";
import SectionsHeader from "components/SectionsHeader";
import CustomFooter from "components/CustomFooter/CustomFooter";
import Datetime from "react-datetime";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import moment from "moment";
import Privacy from "assets/documents/AvisoPrivacidad.pdf";
import Terms from "assets/documents/Terminos.pdf";
import "moment/locale/es";

const useStyles = makeStyles(signupPageStyle);

export default function SignUpPage({}) {
  const { setLoading } = useContext(LoadingContext);
  const [checked, setChecked] = useState([0]);
  const [passwordError, setPasswordError] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [user, setUser] = useState({
    name: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirm: "",
    birthday: "",
  });
  const [openSnack, setOpenSnack] = React.useState(false);
  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });

  const passwordCheck = () => {
    const pass = document.getElementsByName("password")[0].value;
    const passConfirm = document.getElementsByName("passwordConfirm")[0].value;
    if (passConfirm && passConfirm !== pass) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    setUser({
      ...user,
      [name]: value,
    });

    if (name === "password" || name === "passwordConfirm") {
      passwordCheck();
    }
  };

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleDateChange = (event) => {
    const date =
      typeof event === "string" ? event : event.format("YYYY/MM/DD/");
    setUser({
      ...user,
      birthday: date,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await userService.registerUser(user);
      setLoading(false);
      history.push({
        pathname: `/email-verify/${user.email}`,
      });
    } catch (error) {
      setLoading(false);
      const {
        response: {
          data: { error: errorData },
        },
      } = error;
      let message = "";
      if (errorData?.includes("ya ha sido registrado")) {
        message = errorData;
      } else {
        message =
          "Ocurrió un error al registrar el usuario. Por favor inténtalo de nuevo más tarde";
      }
      setServerResponse({
        status: "error",
        message,
      });
      setOpenSnack(true);
    }
  };

  return (
    <div>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      <SectionsHeader whiteText />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer
            justify="center"
            style={{ marginTop: 50, paddingBottom: 150 }}
          >
            <GridItem xs={12} sm={10} md={10}>
              <Card className={classes.cardSignup}>
                <h2 className={classes.cardTitle}>Regístrate</h2>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem
                      xs={12}
                      sm={5}
                      md={5}
                      className={classes.iconsCol}
                    >
                      <InfoArea
                        className={classes.infoArea}
                        title="Conoce nuestro Calendario"
                        description="Entérate de nuestra oferta de clases y de los programas que tenemos disponibles para ti."
                        icon={EventIcon}
                        iconColor="primary"
                      />
                      <InfoArea
                        className={classes.infoArea}
                        title="Reserva Clases"
                        description="Elige el horario que más te acomode y aparta un lugar con tu instructor favorito."
                        icon={ScheduleIcon}
                        iconColor="primary"
                      />
                      <InfoArea
                        className={classes.infoArea}
                        title="Consulta tus Estadísticas"
                        description="Accede a tu perfil y consulta el historial de clases a las que has acudido."
                        icon={Timeline}
                        iconColor="primary"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={5} md={5}>
                      <div className={classes.textCenter}>
                        {/* <Button justIcon round color="twitter">
                          <i className={classes.socials + " fab fa-twitter"} />
                        </Button>
                        {` `}
                        <Button justIcon round color="facebook">
                          <i
                            className={classes.socials + " fab fa-facebook-f"}
                          />
                        </Button>
                        {` `}
                        <h4 className={classes.socialTitle}>or be classical</h4> */}
                        <Logo />
                      </div>
                      <form className={classes.form} onSubmit={onFormSubmit}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            required: true,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <PersonIcon
                                  className={classes.inputAdornmentIcon}
                                />
                              </InputAdornment>
                            ),
                            placeholder: "Nombre",
                            value: user.name,
                            name: "name",
                            onChange: handleInputChange,
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            required: true,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <PersonIcon
                                  className={classes.inputAdornmentIcon}
                                />
                              </InputAdornment>
                            ),
                            placeholder: "Apellidos",
                            value: user.lastName,
                            name: "lastName",
                            onChange: handleInputChange,
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            required: true,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Email",
                            type: "email",
                            value: user.email,
                            name: "email",
                            onChange: handleInputChange,
                          }}
                        />
                        <Datetime
                          closeOnSelect={true}
                          timeFormat={false}
                          renderInput={(dateInputProps, open, close) => (
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses,
                              }}
                              inputProps={{
                                onFocus: open,
                                required: true,
                                value: user.birthday
                                  ? moment(user.birthday, "YYYY-MM-DD").format(
                                      "DD/MM/YYYY"
                                    )
                                  : "",
                                placeholder: "Fecha de nacimiento",
                                required: true,
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <CalendarTodayIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                          onChange={handleDateChange}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            required: true,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            placeholder: "Contraseña",
                            type: "password",
                            value: user.password,
                            name: "password",
                            onChange: handleInputChange,
                          }}
                        />
                        <CustomInput
                          error={passwordError}
                          errorMessage="Las contraseñas no coinciden"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                            error: passwordError,
                          }}
                          inputProps={{
                            required: true,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            placeholder: "Confirmar contraseña",
                            type: "password",
                            value: user.passwordConfirm,
                            name: "passwordConfirm",
                            onChange: handleInputChange,
                          }}
                        />

                        <FormControlLabel
                          classes={{
                            label: classes.label,
                          }}
                          control={
                            <Checkbox
                              required
                              tabIndex={-1}
                              onClick={() => handleToggle(1)}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                              checked={checked.indexOf(1) !== -1 ? true : false}
                            />
                          }
                          label={
                            <span>
                              He leído el{" "}
                              <a href={Privacy} target="_blank">
                                Aviso de Privacidad
                              </a>{" "}
                              y los{" "}
                              <a href={Terms} target="_blank">
                                Términos y Condiciones
                              </a>
                              . Consiento el tratamiento y la transferencia de
                              mis datos personales en los términos ahí
                              establecidos
                            </span>
                          }
                        />
                        <div className={classes.textCenter}>
                          <Button
                            round
                            color="primary"
                            type="submit"
                            disabled={
                              checked.indexOf(1) === -1 || passwordError
                            }
                          >
                            Comenzar
                          </Button>
                        </div>
                      </form>
                    </GridItem>
                  </GridContainer>
                  <div className={classes.registerLinkContainer}>
                    <p>¿Ya tienes una cuenta?</p>{" "}
                    <Link to="/login" className={classes.registerLink}>
                      Inicia Sesión
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
