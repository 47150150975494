import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import QrReader from "react-qr-scanner";

export default function QrScanner(props) {
  const { open, handleClose, onScan, onError, ...extras } = props;
  const style = {
    height: 800,
  };
  const [selectedCamera, setSelectedCamera] = useState(1);

  useEffect(() => {
    (async function effectDetectCamera() {
      const auxDevices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = auxDevices.filter(
        (device) => device.kind === "videoinput"
      );
      const rearCamera = videoDevices.find(
        (device) =>
          device.label === "Cámara trasera" || device.label === "Back Camera"
      );
      setSelectedCamera(rearCamera?.deviceId || videoDevices[1].deviceId);
    })();
  }, []);

  const selectCamera = () => {
    return selectedCamera;
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <QrReader
        delay={100}
        onScan={onScan}
        onError={onError}
        chooseDeviceId={selectCamera}
        style={style}
        {...extras}
      />
    </Dialog>
  );
}

QrScanner.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onScan: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  extras: PropTypes.object,
};
