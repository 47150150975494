import axios from "axios";

class PackageService {
  getPackages(type, limit, page) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/packages?type=${type}&limit=${limit}&page=${page}`, {
      headers: { Authorization: token },
    });
  }

  getUserPackages(type, userId, limit, page) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(
      `/api/packages/user/${userId}?type=${type}&limit=${limit}&page=${page}`,
      {
        headers: { Authorization: token },
      }
    );
  }

  getPackage(id) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/packages/${id}`, {
      headers: { Authorization: token },
    });
  }

  createPackage(classPackageParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.post("/api/packages/create", classPackageParams, {
      headers: { Authorization: token },
    });
  }

  updatePackage(id, classPackageParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.put(`/api/packages/${id}`, classPackageParams, {
      headers: { Authorization: token },
    });
  }

  deletePackage(id) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.delete(`/api/packages/${id}`, {
      headers: { Authorization: token },
    });
  }

  deleteUserPackage(userPackageId) {
    console.log("entre");
    const token = sessionStorage.getItem("clusterToken");
    return axios.delete(`/api/packages/user/${userPackageId}`, {
      headers: { Authorization: token },
    });
  }

  registerUserPackage(packageUserParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.post("/api/packages/register-user", packageUserParams, {
      headers: { Authorization: token },
    });
  }
}

export default new PackageService();
