import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomMultiSelect from "components/CustomMultiSelect/CustomMultiSelect.js";
import ClassTypeService from "services/classType.service";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
}));

function Form({ instructor, setInstructor }) {
  const classStyles = useStyles();
  const [classTypes, setClassTypes] = useState([]);

  useEffect(() => {
    async function fetchClassTypes() {
      const {
        data: { classTypes: classTypesRes },
      } = await ClassTypeService.getClassTypes(50, 0);

      setClassTypes(
        classTypesRes.map((classType) => {
          return { value: classType.id, text: classType.name };
        })
      );
    }
    fetchClassTypes();
  }, []);

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    setInstructor({
      ...instructor,
      [name]: value,
    });
  };

  const handleMultiSelectChange = (event) => {
    event.persist();
    const { checked, value } = event.target;
    const classTypeIds = instructor.classTypeIds;

    if (checked) {
      classTypeIds.push(Number(value));
    } else {
      const index = classTypeIds.indexOf(Number(value));
      if (index > -1) {
        classTypeIds.splice(index, 1);
      }
    }

    setInstructor({
      ...instructor,
      classTypeIds,
    });
  };

  return (
    <>
      <h3>Detalles de instructor</h3>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Nombre"
            id="firstName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: instructor.firstName,
              onChange: handleInputChange,
              name: "firstName",
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Apellidos"
            id="lastName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: instructor.lastName,
              onChange: handleInputChange,
              name: "lastName",
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Teléfono"
            id="phone"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: instructor.phone,
              onChange: handleInputChange,
              name: "phone",
            }}
          />
        </GridItem>
      </GridContainer>
      <Divider variant="middle" className={classStyles.divider} />
      <h3>Brands</h3>
      <GridContainer alignContent="flex-end" alignItems="flex-end">
        <GridItem xs={12} sm={12} md={4}>
          <CustomMultiSelect
            labelText="Brands"
            id="classTypeIds-multiselect"
            value={instructor.classTypeIds}
            onChange={handleMultiSelectChange}
            options={classTypes}
          />
        </GridItem>
      </GridContainer>
      <Divider variant="middle" className={classStyles.divider} />
    </>
  );
}

Form.propTypes = {
  instructor: PropTypes.object,
  setInstructor: PropTypes.func,
};

export default Form;
