import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  dialogContentText: {
    whiteSpace: "pre-line",
  },
}));

const CustomConfirmAlert = ({
  open,
  setOpen,
  title,
  onConfirm,
  onCancel,
  successMessage,
  errorMessage,
  warningMessage,
}) => {
  const classes = useStyles();
  const [openSnack, setOpenSnack] = useState(false);
  const [severity, setSeverity] = useState("");

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      setOpen(false);
    }
  };

  const handleConfirm = async () => {
    try {
      await onConfirm();
      setSeverity("success");
    } catch (error) {
      setSeverity("error");
    }
    setOpenSnack(true);
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setOpenSnack(false);
        }}
      >
        <Alert severity={severity}>
          <AlertTitle>{severity === "success" ? "Éxito" : "Error"}</AlertTitle>
          {severity === "success" ? successMessage : errorMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogContentText}
          >
            {warningMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancelar</Button>
          <Button
            onClick={handleConfirm}
            color="secondary"
            variant="contained"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CustomConfirmAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  errorMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  warningMessage: PropTypes.string.isRequired,
};

export default CustomConfirmAlert;
