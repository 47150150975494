/*eslint-disable*/
import React from "react";
import { useParams } from "react-router";
// @material-ui/core components
// @material-ui/icons
// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";
import SectionsHeader from "components/SectionsHeader";

import image from "assets/img/Calisto-163.jpg";
import CustomFooter from "components/CustomFooter/CustomFooter";
const useStyles = makeStyles(loginPageStyle);

export default function EmailSentPage(props) {
  const classes = useStyles();
  const { email } = useParams();
  const { pathname } = props.location;

  let page = "";

  if (pathname.includes("email-verify")) {
    page = "verification";
  } else {
    page = "recover";
  }

  return (
    <div>
      <SectionsHeader whiteText />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader
                  color="primary"
                  signup
                  className={classes.cardHeader}
                >
                  <h3 className={classes.cardTitle}>
                    {" "}
                    {page === "verification"
                      ? "Activación pendiente"
                      : "Recuperar cuenta"}
                  </h3>
                </CardHeader>
                <CardBody signup style={{ textAlign: "center" }}>
                  <EmailIcon
                    style={{ fontSize: "4rem" }}
                    className={classes.emailIcon}
                  ></EmailIcon>
                  <h4>
                    Se ha enviado un correo de{" "}
                    {page === "verification" ? "confirmación" : "recuperación"}{" "}
                    a la dirección <strong>{email}</strong>.
                  </h4>
                  <h4>
                    Revisa tu bandeja de entrada y da click en el enlace para{" "}
                    {page === "verification" ? "activar" : "recuperar"} tu
                    cuenta.
                  </h4>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
