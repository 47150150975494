import axios from "axios";

class ClassTypeService {
  getClassTypes(limit, page) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/classtypes?limit=${limit}&page=${page}`, {
      headers: { Authorization: token },
    });
  }

  getClassType(id) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/classtypes/${id}`, {
      headers: { Authorization: token },
    });
  }

  createClassType(classTypeParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.post("/api/classtypes/create", classTypeParams, {
      headers: { Authorization: token },
    });
  }

  updateClassType(id, classTypeParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.put(`/api/classtypes/${id}`, classTypeParams, {
      headers: { Authorization: token },
    });
  }

  deleteClassType(id) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.delete(`/api/classtypes/${id}`, {
      headers: { Authorization: token },
    });
  }
}

export default new ClassTypeService();
