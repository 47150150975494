import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import PropTypes from "prop-types";
import MembershipPageStyle from "./MembershipPageStyle";
import MembershipCard from "components/MembershipCard/MembershipCard";

// sections for this page
import SectionsHeader from "components/SectionsHeader";
import CustomFooter from "components/CustomFooter/CustomFooter";

// images
import mensualImg from "assets/img/Calisto-174.jpg";
import secondImg from "assets/img/Calisto-171.jpg";
import thirdImg from "assets/img/Calisto-218.jpg";
import fourthImg from "assets/img/Calisto-308.jpg";
import fifthImg from "assets/img/Calisto-145.jpg";

import calistoLogo from "assets/img/CalistoLogo.png";
import packageService from "services/package.service";
import classTypeService from "services/classType.service";

const useStyles = makeStyles(MembershipPageStyle);

const CalistoMembershipPage = (props) => {
  const membershipImages = [
    mensualImg,
    secondImg,
    thirdImg,
    fourthImg,
    fifthImg,
  ];
  const [openSnack, setOpenSnack] = useState(false);
  const [serverResponse, setServerResponse] = useState({
    status: "success",
    message: "",
  });
  const [packages, setPackages] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  useEffect(() => {
    async function fetchData() {
      const {
        data: { classTypes: classTypesRes },
      } = await classTypeService.getClassTypes();

      console.log("res", classTypesRes);

      const { id } = classTypesRes.find(
        ({ name }) => name.toLowerCase() === "calisto"
      );
      const {
        data: { packages: packagesRes } = [],
      } = await packageService.getPackages(id);

      setPackages(packagesRes);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (props.history.location.state) {
      setServerResponse({
        status: "success",
        message: props.history.location.state.feedback,
      });
      setOpenSnack(true);
    }
  }, [props.history.location.state]);

  const classes = useStyles();
  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      <SectionsHeader whiteText={false} />
      <div className={classes.root}>
        {/* Calisto */}
        <img
          alt=""
          className={`${classes.brandsLogo} ${classes.calistoLogo}`}
          src={calistoLogo}
        />
        <GridContainer justify="center">
          {packages &&
            packages.map(
              (classPackage, index) =>
                classPackage.visible && (
                  <GridItem
                    key={classPackage.id}
                    className={classes.cardContainer}
                    xs={12}
                    md={4}
                  >
                    <MembershipCard
                      title={classPackage.name}
                      image={membershipImages[index % 5]}
                      price={classPackage.price}
                      lessonNumber={classPackage.lessonsNum}
                      packageId={classPackage.id}
                      brand="Calisto"
                    />
                  </GridItem>
                )
            )}
        </GridContainer>
      </div>

      <CustomFooter />
    </>
  );
};

CalistoMembershipPage.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        feedback: PropTypes.string,
      }),
    }),
  }),
};

export default CalistoMembershipPage;
