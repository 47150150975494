import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Box, Container, makeStyles } from "@material-ui/core";
import Page from "components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import { Card, CardContent } from "@material-ui/core";
import { whiteColor } from "assets/jss/material-kit-pro-react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import InstructorService from "services/instructor.service";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  appBar: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  appBarWrapper: {
    backgroundColor: whiteColor,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const InstructorsIndex = (props) => {
  const classes = useStyles();
  const [openSnack, setOpenSnack] = useState(false);
  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [instructors, setInstructors] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [totalInstructors, setTotalInstructors] = useState(0);

  const fetchData = useCallback(
    async function () {
      setLoading(true);
      const {
        data: { instructors: result, total },
      } = await InstructorService.getInstructors(search, limit, page);
      setInstructors(result);
      setTotalInstructors(total);
      setLoading(false);
    },
    [limit, page, search]
  );

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [limit, page, search, fetchData]);

  useEffect(() => {
    if (props.history.location.state) {
      setServerResponse({
        status: "success",
        message: props.history.location.state.feedback,
      });
      setOpenSnack(true);
    }
  }, [props.history.location.state]);

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Page className={classes.root} title="Instructores">
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        <Card>
          <CardContent>
            <Toolbar search={search} setSearch={setSearch} setPage={setPage} />
            <Box mt={3}>
              <Results
                fetchData={fetchData}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                instructors={instructors}
                totalInstructors={totalInstructors}
                isLoading={isLoading}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

InstructorsIndex.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        feedback: PropTypes.string,
      }),
    }),
  }),
};

export default InstructorsIndex;
