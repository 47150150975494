import React, { useEffect, useState, useContext } from "react";
import "./CreditCardForm.css";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import LoadingContext from "contexts/LoadingContext";
import { makeStyles } from "@material-ui/core/styles";
import paymentService from "services/payment.service";
import SessionContext from "contexts/SessionContext";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.js";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import ActionButton from "components/CustomButtons/ActionButton";
import PropTypes from "prop-types";

// Icons
import PersonIcon from "@material-ui/icons/Person";
import CardIcon from "@material-ui/icons/CreditCard";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import CVVIcon from "@material-ui/icons/VpnKey";

// Images
import cvvImage from "assets/img/OpenPay/cvv.png";

/* eslint-disable no-unused-expressions */

const useStyles = makeStyles(signupPageStyle);

function CreditCardForm({
  price,
  classPackageId,
  setOpenSnack,
  setServerResponse,
}) {
  const classes = useStyles();

  const { currentUser } = useContext(SessionContext);

  const [isLoading, setisLoading] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [openPayInfo, setOpenPayInfo] = useState(undefined);
  const [, setOpenPay] = useState();
  const [deviceSessionId, setDeviceSessionId] = useState("");
  const [packageIdAux, setPackageIdAux] = useState("");
  const [cardInfo, setCardInfo] = useState({
    card_number: "",
    holder_name: "",
    expiration_year: "",
    expiration_month: "",
    cvv2: "",
  });

  const [chargeInfo, setChargeInfo] = useState({
    method: "card",
    source_id: "",
    amount: "",
    description: "The Community Clases",
    currency: "MXN",
    device_session_id: "",
    use_3d_secure: true,
  });

  const { setLoading } = useContext(LoadingContext);

  const configureOpenPay = () => {
    try {
      const OpenPay = window.OpenPay;

      OpenPay?.setId(openPayInfo.merchantId);
      OpenPay?.setApiKey(openPayInfo.publicKey);
      OpenPay?.setSandboxMode(process?.env?.NODE_ENV === "development");
      const deviceSessionId = OpenPay?.deviceData?.setup("formId");
      setDeviceSessionId(deviceSessionId);
      setOpenPay(OpenPay);

      return OpenPay;
    } catch (error) {
      console.log("entre");
      console.log(error);
      window.reload();
    }
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await paymentService.getOpenPayInfo();
      setOpenPayInfo(data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    try {
      if (openPayInfo) {
        // Load OpenPay library and AntiFraud Scripts
        const openPayScript = document.createElement("script");

        openPayScript.src =
          "https://resources.openpay.mx/lib/openpay-js/1.2.38/openpay.v1.min.js";
        openPayScript.async = true;

        const antiFraudScript = document.createElement("script");

        antiFraudScript.src =
          "https://resources.openpay.mx/lib/openpay-data-js/1.2.38/openpay-data.v1.min.js";
        antiFraudScript.async = true;

        document.body.appendChild(openPayScript);
        document.body.appendChild(antiFraudScript);

        antiFraudScript.onload = () => setHasLoaded(true);

        setisLoading(false);

        return () => {
          document.body.removeChild(openPayScript);
          document.body.removeChild(antiFraudScript);
        };
      }
    } catch (error) {
      console.log("entre");
      console.log(error);
      window.reload();
    }
  }, [openPayInfo]);

  useEffect(() => {
    setChargeInfo({ ...chargeInfo, amount: price });
    // eslint-disable-next-line
  }, [price]);

  useEffect(() => {
    setPackageIdAux(classPackageId);
  }, [classPackageId]);

  useEffect(() => {
    if (hasLoaded === true) {
      configureOpenPay();
    }
    // eslint-disable-next-line
  }, [hasLoaded, openPayInfo]);

  const handleInputChange = (event) => {
    event.persist();
    const { name } = event.target;
    let { value } = event.target;

    if (
      (name === "expiration_month" || name === "expiration_year") &&
      value.length > 2
    ) {
      value = value.slice(0, 2);
    }

    if (name === "cvv2" && value.length > 4) {
      value = value.slice(0, 4);
    }

    setCardInfo({
      ...cardInfo,
      [name]: value,
    });
  };

  const getToken = (OpenPay) =>
    new Promise((resolve, reject) =>
      OpenPay.token.create(cardInfo, resolve, reject)
    );

  const onFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const OpenPay = configureOpenPay();

      const {
        data: { id },
      } = await getToken(OpenPay);

      const chargeInfoPayload = {
        ...chargeInfo,
        source_id: id,
        device_session_id: deviceSessionId,
      };

      const chargeData = {
        chargeInfoPayload,
        userId: currentUser?.dataValues?.id,
        packageId: packageIdAux,
      };

      const chargeResponse = await paymentService.createCharge(chargeData);

      console.log("charge res", chargeResponse);

      const {
        data: {
          payment_method: { url: threeDSecureUrl },
        },
      } = chargeResponse;

      window.location.replace(threeDSecureUrl);

      setLoading(false);
    } catch (error) {
      console.log("entre");
      setLoading(false);
      setOpenSnack(true);

      var desc =
        error.data?.description ||
        error.response?.data?.error ||
        "Ocurrió un error al crear el pago";

      setServerResponse({
        status: "error",
        message: desc,
      });

      window.reload();
    }
  };

  return (
    <>
      {!isLoading && (
        <form className={classes.form} onSubmit={onFormSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Nombre del Titular"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                }}
                inputProps={{
                  required: true,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <PersonIcon className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  placeholder: "Como aparece en la tarjeta",
                  value: cardInfo.holder_name,
                  name: "holder_name",
                  onChange: handleInputChange,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Número de Tarjeta"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                }}
                inputProps={{
                  required: true,
                  type: "number",
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <CardIcon className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  value: cardInfo.card_number,
                  name: "card_number",
                  onChange: handleInputChange,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Mes de Expiración"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                }}
                inputProps={{
                  required: true,
                  type: "number",
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <CalendarIcon className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  placeholder: "02",
                  value: cardInfo.expiration_month,
                  name: "expiration_month",
                  onChange: handleInputChange,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Año de Expiración"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                }}
                inputProps={{
                  required: true,
                  type: "number",
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <CalendarIcon className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  placeholder: "26",
                  value: cardInfo.expiration_year,
                  name: "expiration_year",
                  onChange: handleInputChange,
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={4}>
              <CustomInput
                labelText="Código de Seguridad"
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses,
                }}
                inputProps={{
                  required: true,
                  type: "number",
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <CVVIcon className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  value: cardInfo.cvv2,
                  name: "cvv2",
                  onChange: handleInputChange,
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={8} style={{ paddingTop: 25 }}>
              <img alt="CVV" src={cvvImage} />
            </GridItem>

            <GridItem xs={12} style={{ textAlign: "center", marginTop: 15 }}>
              <ActionButton
                buttonProps={{
                  type: "submit",
                }}
                action="confirm"
                text="Pagar"
              ></ActionButton>
            </GridItem>
          </GridContainer>
        </form>
      )}
    </>
  );
}

CreditCardForm.propTypes = {
  price: PropTypes.string,
  classPackageId: PropTypes.number,
  setOpenSnack: PropTypes.func,
  setServerResponse: PropTypes.func,
};

export default CreditCardForm;
