import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Results from "./Results";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Card, CardContent } from "@material-ui/core";
import { whiteColor } from "assets/jss/material-kit-pro-react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  appBar: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  appBarWrapper: {
    backgroundColor: whiteColor,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const UserClassesIndex = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={classes.appBarWrapper}>
        <AppBar position="static" className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Todos" />
            <Tab label="Calisto" />
            <Tab label="Bloom" />
          </Tabs>
        </AppBar>
      </div>
      <Card style={{ overflowX: "auto" }}>
        <CardContent>
          <TabPanel value={value} index={0}>
            <Results classType="all" />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Results classType={1} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Results classType={2} />
          </TabPanel>
        </CardContent>
      </Card>
    </>
  );
};

UserClassesIndex.propTypes = {
  action: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.string,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        feedback: PropTypes.string,
      }),
    }),
  }),
};

export default UserClassesIndex;
