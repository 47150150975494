import React from "react";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks.js";
import PropTypes from "prop-types";
import communityLogo from "assets/img/CommunityLogo.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  headerLogo: {
    [theme.breakpoints.up("xs")]: {
      width: "12rem",
      height: "2rem",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "-7rem",
      width: "18rem",
      height: "3.5rem",
    },
  },
  whiteLogo: {
    webkitFilter: "invert(100%)",
    filter: "invert(100%)",
  },
}));

function SectionsHeader({ whiteText }) {
  const classes = useStyles();
  return (
    <Header
      absolute
      whiteText={whiteText}
      color="transparent"
      brand={
        <img
          alt="..."
          className={`${classes.headerLogo} ${
            whiteText ? classes.whiteLogo : ""
          }`}
          src={communityLogo}
        />
      }
      links={<HeaderLinks whiteText={whiteText} dropdownHoverColor="info" />}
    />
  );
}

SectionsHeader.propTypes = {
  whiteText: PropTypes.bool,
};

export default SectionsHeader;
