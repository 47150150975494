/*eslint-disable*/
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import calistoLogo from "assets/img/CalistoLogoCropped.png";
import { Link } from "react-router-dom";
import "./calisto.css";

// nodejs library that concatenates classes
// react component for creating beautiful carousel
// @material-ui/core components

// @material-ui/icons
// core components

import calistoPageStyle from "./calistoPageStyle";

// sections for this page
import SectionsHeader from "components/SectionsHeader";
import CustomFooter from "components/CustomFooter/CustomFooter";

// images
import bgImg from "assets/img/Calisto-157.jpg";
import manImg from "assets/img/Calisto-236.png";

const useStyles = makeStyles(calistoPageStyle);

export default function CalistoPage(props) {
  const [openSnack, setOpenSnack] = useState(false);
  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  useEffect(() => {
    if (props.history.location.state) {
      setServerResponse({
        status: "success",
        message: props.history.location.state.feedback,
      });
      setOpenSnack(true);
    }
  }, [props.history.location.state]);

  const classes = useStyles();
  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      <SectionsHeader />
      <div
        className={classes.pageHeader}
        style={{ backgroundImage: `url("${bgImg}")` }}
      >
        <div className={classes.triangleLeft}></div>
        <span className={classes.letterM}>M</span>
        <div className={classes.triangleRight}></div>
      </div>

      <div className={classes.calistoLogoContainer}>
        <img className={classes.brandsLogo} src={calistoLogo} />
        <p className={classes.calistoSubtext}> cuerpo en movimiento</p>
      </div>

      <div className={classes.manContainer}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={5} className={classes.manTextContainer}>
            <p className={classes.manText}>
              Sistema de entrenamiento con ejercicio físicos que se realizan con
              el propio peso corporal
            </p>

            <Link to="/calendar/1">
              <Button className={classes.moveButton} size="lg">
                <strong>INSCRIPCIÓN</strong>
              </Button>
            </Link>
          </GridItem>
          <GridItem xs={12} sm={12} md={5}>
            <img className={classes.manImg} src={manImg} />
            <span className={classes.letterC}>c</span>
          </GridItem>
        </GridContainer>
        <div className={classes.blackShape}></div>
      </div>

      <div className={classNames(classes.main, classes.mainRaised)}></div>
      <CustomFooter />
    </>
  );
}
