import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
import { secondaryColor } from "assets/jss/material-kit-pro-react.js";
import { primaryColor } from "assets/jss/material-kit-pro-react";

const calistoPageStyle = (theme) => ({
  ...headerLinksStyle(theme),
  ...headersStyle(theme),
  blackShape: {
    backgroundColor: secondaryColor[0],
    height: "1rem",
    width: "55%",
    zIndex: 10,
    position: "absolute",
    right: 0,
    bottom: "-25px",
    visibility: "hidden",
    "@media (min-width: 960px)": {
      bottom: -155,
      height: "3rem",
      width: "42%",
      visibility: "visible",
    },
  },
  moveButton: {
    fontFamily: "AvenirNextCondensed",
    fontWeight: 400,
    fontSize: "1.2rem",
    letterSpacing: "10px !important",
    color: secondaryColor[0],
    width: "10rem",
    padding: "5px",
    backgroundColor: "#f5f5f5",
    "& a": {
      color: secondaryColor[0],
    },
    "&:hover": {
      "& a": {
        color: "white",
      },
    },
  },
  brandsLogo: {
    width: "30%",
    marginBottom: "2rem",
    "@media (min-width: 960px)": {
      width: "15%",
      marginTop: "4rem",
      marginBottom: "4rem",
      fontSize: "4rem",
    },
  },
  calistoLogoContainer: {
    textAlign: "center",
    marginTop: "2rem",
    "@media (min-width: 960px)": {
      marginTop: "1rem",
      marginBottom: "4rem",
      fontSize: "4rem",
    },
  },
  calistoSubtext: {
    fontFamily: "HugolersStylishRegular",
    fontSize: "2rem",
    "@media (min-width: 960px)": {
      fontSize: "4rem",
      wordSpacing: 10,
    },
  },
  letterC: {
    zIndex: 30,
    position: "absolute",
    left: "35%",
    top: "7rem",
    opacity: "0.2",
    fontFamily: "HugolersStylishRegular !important",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "23rem",
    color: secondaryColor[0],
    "@media (min-width: 960px)": {
      fontSize: "60rem",
      left: "-50px",
      top: "235px",
    },
  },
  triangleRight: {
    position: "absolute",
    width: 0,
    height: 0,
    borderBottom: "20vh solid #eee",
    borderLeft: "50vw solid transparent",
    right: 0,
    bottom: 0,
    "@media (min-width: 960px)": {
      borderBottom: "35vh solid #eee",
    },
  },
  triangleLeft: {
    position: "absolute",
    width: 0,
    height: 0,
    borderBottom: "20vh solid #eee",
    borderRight: "50vw solid transparent",
    left: 0,
    bottom: 0,
    "@media (min-width: 960px)": {
      borderBottom: "35vh solid #eee",
    },
  },
  letterM: {
    transform: "scaleY(0.7)",
    bottom: 20,
    zIndex: 30,
    position: "absolute",
    left: "35%",
    opacity: "0.8",
    fontFamily: "HugolersStylishRegular !important",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "10rem",
    color: primaryColor[0],
    "@media (min-width: 960px)": {
      fontSize: "23rem",
      left: "42%",
      bottom: 50,
    },
  },
  manImg: {
    zIndex: 20,
    height: "15rem",
    position: "absolute",
    left: "45%",
    top: 0,
    "@media (min-width: 960px)": {
      height: "35rem",
      left: "10%",
    },
  },
  manContainer: {
    position: "relative",
    padding: 0,
    height: "25rem",
    textAlign: "center",
    marginBottom: "8rem",
    "@media (min-width: 961px)": {
      marginBottom: "25rem",
      textAlign: "left",
    },
  },
  manTextContainer: {
    marginTop: "3rem",
    marginBottom: "3rem",
    "@media (min-width: 961px)": {
      padding: 0,
      paddingRight: "6rem",
      paddingLeft: "16rem",
      marginTop: "10rem",
      textAlign: "right",
    },
  },
  manText: {
    fontFamily: "Avenir",
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: "center",
    fontSize: "1.2rem",
    "@media (min-width: 961px)": {
      fontSize: "2rem",
      textAlign: "right",
    },
  },
});

export default calistoPageStyle;
