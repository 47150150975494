/*eslint-disable*/
import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import calistoLogo from "assets/img/CalistoLogo.png";
import bloomLogo from "assets/img/BloomLogo.png";
// nodejs library that concatenates classes
// react component for creating beautiful carousel
// @material-ui/core components

// @material-ui/icons
// core components

import chooseBrandPageStyle from "./chooseBrandPageStyle";

// sections for this page
import SectionsHeader from "components/SectionsHeader";
import CustomFooter from "components/CustomFooter/CustomFooter";

// images
import bgImg from "assets/img/Calisto-320.jpg";
import calistoImg from "assets/img/Calisto-157.jpg";
import bloomImg from "assets/img/BloomImg.jpeg";

const useStyles = makeStyles(chooseBrandPageStyle);

export default function ChooseBrandPage(props) {
  const [openSnack, setOpenSnack] = useState(false);
  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  useEffect(() => {
    if (props.history.location.state) {
      setServerResponse({
        status: "success",
        message: props.history.location.state.feedback,
      });
      setOpenSnack(true);
    }
  }, [props.history.location.state]);

  const classes = useStyles();
  return (
    <>
      <SectionsHeader />
      <div
        className={classes.brandsHeader}
        style={{ backgroundImage: `url("${bgImg}")` }}
      >
        <div className={classes.brandsContainer}>
          <GridContainer alignItems="center" justify="center">
            <GridItem xs={12} md={6} style={{ textAlign: "center" }}>
              <Card blog>
                <CardHeader image>
                  <Link to="/calendar/1">
                    <img
                      src={calistoImg}
                      alt="..."
                      className={classes.brandImg}
                    />
                  </Link>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${calistoImg})`,
                      opacity: "1",
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <img className={classes.brandsLogo} src={calistoLogo} />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} md={6} style={{ textAlign: "center" }}>
              <Card blog>
                <CardHeader image>
                  <Link to="/calendar/2">
                    <img
                      src={bloomImg}
                      alt="..."
                      className={classes.brandImg}
                      style={{ objectPosition: "50% 30%" }}
                    />
                  </Link>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${bloomImg})`,
                      opacity: "1",
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <img className={classes.brandsLogo} src={bloomLogo} />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>

      <div className={classNames(classes.main, classes.mainRaised)}></div>
      <CustomFooter />
    </>
  );
}
