import React, { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ActionButton from "components/CustomButtons/ActionButton";
import ClassService from "services/class.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import CustomConfirmAlert from "components/CustomConfirmAlert/CustomConfirmAlert";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "white",
  },
  tableResponsive: {
    minHeight: "0.1%",
    overflowX: "auto",
    display: "block",
    whiteSpace: "nowrap",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  circularProgress: {
    color: theme.palette.secondary.main,
  },
}));

const Results = ({ className, classType, ...rest }) => {
  const styleClasses = useStyles();
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [classes, setClasses] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [totalClasses, setTotalClasses] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [deletingClass, setDeletingClass] = useState({});

  const fetchData = useCallback(
    async function () {
      setLoading(true);
      const {
        data: { classes: result, total },
      } = await ClassService.getClasses(classType, limit, page);
      setClasses(result);
      setTotalClasses(total);
      setLoading(false);
    },
    [classType, limit, page]
  );

  useEffect(() => {
    fetchData();
  }, [classType, limit, page, fetchData]);

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleDeleteItem = (lesson) => {
    setDeletingClass(lesson);
    setOpenDialog(true);
  };

  return (
    <Card
      className={clsx(styleClasses.root, className)}
      {...rest}
      style={{ overflowX: "scroll" }}
    >
      <CustomConfirmAlert
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={async () => {
          await ClassService.deleteClass(deletingClass.id);
          await fetchData();
        }}
        title={"Eliminar clase"}
        errorMessage={"Error al eliminar clase"}
        successMessage={"Clase eliminada"}
        warningMessage={
          "¿Estás seguro de que deseas eliminar la clase? Esta acción no puede deshacerse."
        }
      />

      <Box minWidth={1050}>
        <Table>
          <TableHead>
            <TableRow>
              {classType === "all" && <TableCell>Brand</TableCell>}
              <TableCell>Fecha</TableCell>
              <TableCell>Horario</TableCell>
              <TableCell>Instructor</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classes &&
              classes.map((lesson) => (
                <TableRow hover key={lesson.id}>
                  {classType === "all" && (
                    <TableCell>
                      {lesson.classType && lesson.classType.name}
                    </TableCell>
                  )}
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Typography color="textPrimary" variant="body1">
                        <Link to={`/admin/classes/${lesson.id}`}>
                          {moment(lesson.date).utc().format("DD/MM/YYYY")}
                        </Link>
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {lesson.start &&
                      lesson.end &&
                      `${lesson.start} - ${lesson.end}`}
                  </TableCell>
                  <TableCell>
                    {lesson.instructor &&
                      `${lesson.instructor.firstName} ${lesson.instructor.lastName}`}
                  </TableCell>
                  <TableCell>{lesson.subtype && `${lesson.subtype}`}</TableCell>
                  <TableCell>
                    <ActionButton
                      action="edit"
                      href={`/admin/classes/edit/${lesson.id}`}
                    />
                    <ActionButton
                      action="delete"
                      func={() => handleDeleteItem(lesson)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>

      {isLoading && (
        <div className={styleClasses.progressContainer}>
          <CircularProgress
            className={styleClasses.circularProgress}
            size="100px"
          />
        </div>
      )}
      <TablePagination
        component="div"
        count={totalClasses}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  classType: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default Results;
