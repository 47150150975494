/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import image from "assets/img/Calisto-163.jpg";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import LoadingContext from "contexts/LoadingContext";
import userService from "services/user.service";
import SectionsHeader from "components/SectionsHeader";
import CustomFooter from "components/CustomFooter/CustomFooter";

export default function RecoverAccountPage() {
  const useStyles = makeStyles(loginPageStyle);
  const history = useHistory();
  const { email, resetToken } = useParams();
  const { setLoading } = useContext(LoadingContext);
  const [openSnack, setOpenSnack] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [resetPasswordData, setResetPasswordData] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await userService.resetPassword(
        email,
        resetPasswordData.password,
        resetToken
      );
      setLoading(false);
      history.push({
        pathname: "/login",
        state: { feedback: "Se ha actualizado tu contraseña correctamente" },
      });
    } catch (error) {
      setLoading(false);
      let message =
        "Ocurrió un error al reestablecer tu contraseña. Por favor inténtalo de nuevo más tarde";

      setServerResponse({
        status: "error",
        message,
      });
      setOpenSnack(true);
    }
  };

  const passwordCheck = () => {
    const pass = document.getElementsByName("password")[0].value;
    const passConfirm = document.getElementsByName("passwordConfirm")[0].value;
    if (passConfirm && passConfirm !== pass) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    passwordCheck();

    setResetPasswordData({
      ...resetPasswordData,
      [name]: value,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      <SectionsHeader whiteText />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <form className={classes.form} onSubmit={onFormSubmit}>
                  <CardHeader
                    color="primary"
                    signup
                    className={classes.cardHeader}
                  >
                    <h3 className={classes.cardTitle}>
                      Reestablecer contraseña
                    </h3>
                  </CardHeader>
                  <CardBody signup style={{ textAlign: "center" }}>
                    <br></br>
                    <h4>Ingresa tu nueva contraseña</h4>
                    <CustomInput
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Contraseña nueva",
                        type: "password",
                        required: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon className={classes.inputIconsColor}>
                              lock_utline
                            </Icon>
                          </InputAdornment>
                        ),
                        value: resetPasswordData.password,
                        name: "password",
                        onChange: handleInputChange,
                      }}
                    />
                    <CustomInput
                      id="passwordConfirm"
                      error={passwordError}
                      errorMessage="Las contraseñas no coinciden"
                      formControlProps={{
                        fullWidth: true,
                        error: passwordError,
                      }}
                      inputProps={{
                        placeholder: "Confirma contraseña",
                        type: "password",
                        required: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon className={classes.inputIconsColor}>
                              lock_utline
                            </Icon>
                          </InputAdornment>
                        ),
                        value: resetPasswordData.passwordConfirm,
                        name: "passwordConfirm",
                        onChange: handleInputChange,
                      }}
                    />
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button
                      simple
                      color="primary"
                      size="lg"
                      type="submit"
                      disabled={passwordError}
                    >
                      Enviar
                    </Button>
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
