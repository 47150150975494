import React, { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Typography,
  Tooltip,
} from "@material-ui/core";
import ActionButton from "components/CustomButtons/ActionButton";
import PackageService from "services/package.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomConfirmAlert from "components/CustomConfirmAlert/CustomConfirmAlert";
import CustomCurrency from "components/CustomCurrency/CustomCurrency";

const useStyles = makeStyles((theme) => ({
  root: {},
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "white",
  },
  tableResponsive: {
    minHeight: "0.1%",
    overflowX: "auto",
    display: "block",
    whiteSpace: "nowrap",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  circularProgress: {
    color: theme.palette.secondary.main,
  },
}));

const Results = ({ className, classType, ...rest }) => {
  const stylePackages = useStyles();
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [packages, setPackages] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [totalPackages, setTotalPackages] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [deletingPackage, setDeletingPackage] = useState({});

  const fetchData = useCallback(
    async function () {
      setLoading(true);
      const {
        data: { packages: result, total },
      } = await PackageService.getPackages(classType, limit, page);

      console.log("packages", result);
      setPackages(result);
      setTotalPackages(total);
      setLoading(false);
    },
    [classType, limit, page]
  );

  useEffect(() => {
    fetchData();
  }, [classType, limit, page, fetchData]);

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleDeleteItem = (classPackage) => {
    setDeletingPackage(classPackage);
    setOpenDialog(true);
  };

  return (
    <Card
      className={clsx(stylePackages.root, className)}
      {...rest}
      style={{ overflowX: "scroll" }}
    >
      <CustomConfirmAlert
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={async () => {
          await PackageService.deletePackage(deletingPackage.id);
          await fetchData();
        }}
        title={"Eliminar paquete"}
        errorMessage={"Error al eliminar paquete"}
        successMessage={"Paquete eliminado"}
        warningMessage={
          "¿Estás seguro de que deseas eliminar el paquete? Esta acción no puede deshacerse."
        }
      />

      <Box minWidth={1050}>
        <Table>
          <TableHead>
            <TableRow>
              {classType === "all" && <TableCell>Brand</TableCell>}
              <TableCell>Nombre</TableCell>
              <TableCell>Número de clases</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packages &&
              packages.map((classPackage) => (
                <TableRow hover key={classPackage.id}>
                  {classType === "all" && (
                    <TableCell>
                      {classPackage.classType && classPackage.classType.name}
                    </TableCell>
                  )}
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Typography color="textPrimary" variant="body1">
                        {classPackage.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{classPackage.lessonsNum}</TableCell>
                  <TableCell>
                    <CustomCurrency quantity={classPackage.price} />
                  </TableCell>
                  <TableCell>
                    <ActionButton
                      action="edit"
                      href={`/admin/packages/edit/${classPackage.id}`}
                    />
                    <Tooltip
                      title={
                        classPackage?.user?.length > 0
                          ? "Ya existen usarios inscritos en este paquete"
                          : ""
                      }
                    >
                      <span>
                        <ActionButton
                          action="delete"
                          func={() => handleDeleteItem(classPackage)}
                          buttonProps={{
                            disabled: classPackage?.user?.length > 0,
                          }}
                        />
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>

      {isLoading && (
        <div className={stylePackages.progressContainer}>
          <CircularProgress
            className={stylePackages.circularProgress}
            size="100px"
          />
        </div>
      )}
      <TablePagination
        component="div"
        count={totalPackages}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  classType: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default Results;
