import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Container, makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Page from "components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Card, CardContent } from "@material-ui/core";
import { whiteColor } from "assets/jss/material-kit-pro-react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import ClassTypeService from "services/classType.service";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  appBar: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  appBarWrapper: {
    backgroundColor: whiteColor,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const PackagesIndex = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [openSnack, setOpenSnack] = useState(false);
  const [classTypes, setClassTypes] = useState([]);
  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (props.history.location.state) {
      setServerResponse({
        status: "success",
        message: props.history.location.state.feedback,
      });
      setOpenSnack(true);
    }
  }, [props.history.location.state]);

  useEffect(() => {
    async function fetchClassTypes() {
      const {
        data: { classTypes: classTypesRes },
      } = await ClassTypeService.getClassTypes(50, 0);

      setClassTypes(classTypesRes);
    }
    fetchClassTypes();
  }, []);

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Page className={classes.root} title="Paquetes">
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        <div className={classes.appBarWrapper}>
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Todos" />
              {classTypes.length > 0 &&
                classTypes.map((classType) => (
                  <Tab key={classType.id} label={classType.name} />
                ))}
            </Tabs>
          </AppBar>
        </div>
        <Card>
          <CardContent>
            <Toolbar />
            <Box mt={3}>
              <TabPanel value={value} index={0}>
                <Results classType="all" />
              </TabPanel>
              {classTypes.length > 0 &&
                classTypes.map((classType, i) => (
                  <TabPanel key={classType.id} value={value} index={i + 1}>
                    <Results classType={classType.id} />
                  </TabPanel>
                ))}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

PackagesIndex.propTypes = {
  action: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.string,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        feedback: PropTypes.string,
      }),
    }),
  }),
};

export default PackagesIndex;
