import React from "react";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  spinnerBackground: {
    position: "fixed",
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.6)",
    zIndex: "100",
  },
  loadingSpinner: {
    position: "fixed",
    left: "50%",
    top: "35%",
    zIndex: "101",
  },
}));

const LoadingSpinner = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.spinnerBackground}>
        <CircularProgress className={classes.loadingSpinner} size="100px" />
      </div>
    </>
  );
};

export default LoadingSpinner;
