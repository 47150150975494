import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Box, Container, makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Page from "components/Page";
import Results from "./Results";
import Toolbar from "./Toolbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Card, CardContent } from "@material-ui/core";
import { whiteColor } from "assets/jss/material-kit-pro-react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import ClassTypeService from "services/classType.service";
import PaymentService from "services/payment.service";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  appBar: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  appBarWrapper: {
    backgroundColor: whiteColor,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const PaymentsIndex = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0); // tab value
  const [openSnack, setOpenSnack] = useState(false);
  const [classTypes, setClassTypes] = useState([]);
  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });

  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [payments, setPayments] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [totalPayments, setTotalPayments] = useState(0);
  const [query, setQuery] = useState({
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().endOf("month").format("YYYY-MM-DD"),
    method: "",
  });

  const fetchData = useCallback(
    async function (classType) {
      setLoading(true);
      const {
        data: { payments: result, total },
      } = await PaymentService.getPayments(
        classType,
        limit,
        page,
        query.startDate,
        query.endDate,
        query.method
      ).catch((e) => {
        setPayments([]);
        setServerResponse({ status: "error", message: e.message });
        setLoading(false);
        setOpenSnack(true);
      });
      setPayments(result);
      setTotalPayments(total);
      setLoading(false);
    },
    [limit, page, query]
  );

  const handleTabChange = (_event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (props.history.location.state) {
      setServerResponse({
        status: "success",
        message: props.history.location.state.feedback,
      });
      setOpenSnack(true);
    }
  }, [props.history.location.state]);

  useEffect(() => {
    async function fetchClassTypes() {
      const {
        data: { classTypes: classTypesRes },
      } = await ClassTypeService.getClassTypes(50, 0);

      setClassTypes(classTypesRes);
    }
    fetchClassTypes();
  }, []);

  const handleSnackClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Page className={classes.root} title="Pagos">
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={serverResponse.status}>
          <AlertTitle>
            {serverResponse.status === "success" ? "Éxito" : "Error"}
          </AlertTitle>
          {serverResponse.message}
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        <div className={classes.appBarWrapper}>
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              <Tab label="Todos" />
              {classTypes.length > 0 &&
                classTypes.map((classType) => (
                  <Tab key={classType.id} label={classType.name} />
                ))}
            </Tabs>
          </AppBar>
        </div>
        <Card>
          <CardContent>
            <Toolbar query={query} setQuery={setQuery} />
            <Box mt={3}>
              <TabPanel value={value} index={0}>
                <Results
                  classType="all"
                  limit={limit}
                  setLimit={setLimit}
                  page={page}
                  setPage={setPage}
                  payments={payments}
                  isLoading={isLoading}
                  fetchData={fetchData}
                  totalPayments={totalPayments}
                />
              </TabPanel>
              {classTypes.length > 0 &&
                classTypes.map((classType, i) => (
                  <TabPanel key={classType.id} value={value} index={i + 1}>
                    <Results
                      classType={classType.id}
                      limit={limit}
                      setLimit={setLimit}
                      page={page}
                      setPage={setPage}
                      payments={payments}
                      isLoading={isLoading}
                      fetchData={fetchData}
                      totalPayments={totalPayments}
                    />
                  </TabPanel>
                ))}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

PaymentsIndex.propTypes = {
  action: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.string,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        feedback: PropTypes.string,
      }),
    }),
  }),
};

export default PaymentsIndex;
