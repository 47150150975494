import React, { useState, useContext, useEffect } from "react";
import { Container, makeStyles } from "@material-ui/core";
import Page from "components/Page";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import ActionButton from "components/CustomButtons/ActionButton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Form from "../Form";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import LoadingContext from "contexts/LoadingContext";
import InstructorService from "services/instructor.service";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  selectGridItem: {
    marginTop: theme.spacing(1.25),
  },
  dateGridItem: { marginTop: theme.spacing(2.5) },
}));

function EditInstructorView() {
  const { setLoading } = useContext(LoadingContext);
  const history = useHistory();
  const classes = useStyles();
  const [instructor, setInstructor] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    classTypeIds: [],
  });

  const [openSnack, setOpenSnack] = React.useState(false);

  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });

  const { id: instructorId } = useParams();

  useEffect(() => {
    async function fetchData() {
      const { data } = await InstructorService.getInstructor(instructorId);
      const classTypeIds = data.ClassTypes.map((classType) => classType.id);
      setInstructor({
        ...data,
        classTypeIds,
      });
    }
    fetchData();
  }, [instructorId]);

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await InstructorService.updateInstructor(instructorId, instructor);
      setLoading(false);
      history.push({
        pathname: "/admin/instructors",
        state: { feedback: "Instructor actualizado correctamente" },
      });
    } catch (error) {
      setLoading(false);
      setServerResponse({
        status: "error",
        message: "Ocurrió un error al editar el instructor",
      });
      setOpenSnack(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Page className={classes.root} title="Editar instructor">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={serverResponse.status}>
            <AlertTitle>
              {serverResponse.status === "success" ? "Éxito" : "Error"}
            </AlertTitle>
            {serverResponse.message}
          </Alert>
        </Snackbar>
        <Card>
          <CardHeader title="Editar Instructor"></CardHeader>
          <CardContent>
            <Form instructor={instructor} setInstructor={setInstructor} />
            <GridContainer justify="center">
              <GridItem xs={12}>
                <div style={{ textAlign: "center" }}>
                  <ActionButton
                    action="cancel"
                    text="Cancelar"
                    href="/admin/instructors"
                  ></ActionButton>
                  <ActionButton
                    action="confirm"
                    text="Enviar"
                    func={submitForm}
                  ></ActionButton>
                </div>
              </GridItem>
            </GridContainer>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default EditInstructorView;
