import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { orangeChartColor } from "assets/jss/material-kit-pro-react.js";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  avatar: {
    backgroundColor: orangeChartColor,
    height: 56,
    width: 56,
  },
}));

const AttendancePercentage = ({ className, attendancePercentage, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              ASISTENCIA
            </Typography>
            <Typography color="textPrimary" variant="h5">
              {attendancePercentage}%
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AssignmentIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={3}>
          <LinearProgress
            value={attendancePercentage}
            variant="determinate"
            color="secondary"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

AttendancePercentage.propTypes = {
  className: PropTypes.string,
  attendancePercentage: PropTypes.number,
};

export default AttendancePercentage;
