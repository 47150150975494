import axios from "axios";

class ScheduleService {
  getSchedules(classTypeId) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/schedules/${classTypeId}`, {
      headers: { Authorization: token },
    });
  }

  createSchedule(scheduleParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.post("/api/schedules/create", scheduleParams, {
      headers: { Authorization: token },
    });
  }

  deleteSchedules(ids) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.delete(`/api/schedules/${ids}`, {
      headers: { Authorization: token },
    });
  }
}

export default new ScheduleService();
