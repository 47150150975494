import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import {
  container,
  mrAuto,
  title,
  blackColor,
  whiteColor,
  hexToRgb,
  primaryColor,
  secondaryColor,
} from "assets/jss/material-kit-pro-react.js";

const headersSection = (theme) => ({
  ...headerLinksStyle(theme),
  yellowShapeGradient: {
    backgroundColor: primaryColor[0],
    opacity: "50%",
    height: "4rem",
    width: "50%",
    position: "absolute",
    left: 0,
    top: "45%",
  },
  yellowShape: {
    backgroundColor: primaryColor[0],
    height: "3rem",
    width: "40%",
    zIndex: 100,
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  instructorsImgContainer: {
    position: "relative",
    marginBottom: 40,
    "@media (min-width: 961px)": {
      marginTop: 70,
      marginBottom: 60,
      marginRight: 100,
      marginLeft: 100,
    },
  },
  instructorsImg: {
    maxHeight: "80vh",
    width: "100%",
    objectFit: "cover",
  },
  floatingLogo: {
    visibility: "hidden",
    position: "absolute",
    bottom: -32,
    left: 20,
    zIndex: 200,
    width: "42rem",
    "@media (min-width: 960px)": {
      visibility: "visible",
    },
  },
  letterC: {
    position: "absolute",
    left: "40%",
    top: "2rem",
    opacity: "0.8",
    fontFamily: "HugolersStylishRegular",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "15rem",
    color: primaryColor[0],
    "@media (min-width: 960px)": {
      fontSize: "35rem",
      left: "66%",
      top: "100px",
    },
  },
  letterG: {
    position: "absolute",
    right: "10%",
    top: "2rem",
    opacity: "0.6",
    fontFamily: "HugolersStylishRegular",
    fontWeight: 400,
    fontStyle: "normal",
    color: secondaryColor[0],
    visibility: "hidden",
    "@media (min-width: 960px)": {
      visibility: "visible",
      fontSize: "25rem",
      right: "-90px",
      top: "-80px",
    },
  },
  womanImg: {
    height: "10rem",
    position: "absolute",
    left: "40%",
    "@media (min-width: 960px)": {
      height: "25rem",
      left: "65%",
    },
  },
  womanContainer: {
    padding: 0,
    textAlign: "center",
    "@media (min-width: 961px)": {
      marginBottom: "25rem",
      textAlign: "right",
      marginTop: "5rem",
    },
  },
  womanTextContainer: {
    fontFamily: "Avenir",
    fontWeight: 400,
    padding: 0,
    marginTop: "12rem",
    textAlign: "center",
    "@media (min-width: 961px)": {
      paddingLeft: "3rem",
      marginTop: "5rem",
      textAlign: "left",
    },
  },
  brandsImg: {
    width: "100%",
    height: "20vh",
    objectFit: "cover",
    "@media (min-width: 800px)": {
      height: "350px",
    },
  },
  brandsLogo: {
    width: "50%",
    height: "150px",
    objectFit: "contain",
    marginTop: "1rem",
    "@media (min-width: 800px)": {
      marginTop: "4rem",
    },
  },
  joinButton: {
    fontFamily: "Avenir",
    fontWeight: 700,
    fontSize: "1.4rem",
    wordSpacing: "10px",
    color: secondaryColor[0],
    marginBottom: "2rem",
    width: "14rem",
    padding: "5px",
    marginTop: "1rem",
    "@media (min-width: 800px)": {
      marginTop: "3rem",
    },
  },
  moveButton: {
    fontFamily: "Avenir",
    fontWeight: 700,
    fontSize: "1.2rem",
    wordSpacing: "10px",
    color: secondaryColor[0],
    width: "10rem",
    padding: "5px",
    backgroundColor: "#f5f5f5",
  },
  brandsContainer: {
    borderColor: primaryColor[0],
    borderStyle: "solid",
    marginBottom: "2.5rem",
    "@media (min-width: 800px)": {
      marginRight: 200,
      marginLeft: 200,
    },
  },
  footerImgContainer: {
    "@media (min-width: 991px)": {
      marginTop: "7rem",
      marginBottom: "15vh",
    },
  },
  footerImg: {
    width: "100%",
    objectFit: "cover",
    maxHeight: "20vh",
    objectPosition: "0px 80%",
    "@media (min-width: 800px)": {
      maxHeight: "350px",
      transform: "scale(1.2)",
      objectPosition: "50px 80%",
    },
  },
  sectionBlank: {
    height: "70px",
    display: "block",
  },
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h4, & h6": {
      color: whiteColor,
    },
  },
  conatinerHeader2: {
    ...container,
    zIndex: "2",
    position: "relative",
    "& h1, & h4, & h6": {
      color: whiteColor,
    },
    paddingTop: "25vh",
  },
  title,
  pageTitle: {
    fontFamily: "Hugolers",
    paddingTop: "90px",
  },
  pageHeader: {
    position: "relative",
    height: "60vh",
    maxHeight: "1600px",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    "@media (min-width: 960px)": {
      height: "100vh",
    },

    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
    },
  },
  iframeContainer: {
    "& > iframe": {
      width: "100%",
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)",
    },
  },
  mrAuto,
  textCenter: {
    textAlign: "center",
  },
  card: {
    marginTop: "60px",
  },
  formControl: {
    margin: "0",
    padding: "8px 0 0 0",
  },
  textRight: {
    textAlign: "right",
  },
  button: {
    margin: "0 !important",
  },
});

export default headersSection;
