import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
import {
  secondaryColor,
  bloomLogoColor,
} from "assets/jss/material-kit-pro-react";

const BloomPageStyle = (theme) => ({
  ...headerLinksStyle(theme),
  ...headersStyle(theme),
  headerImgContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  pageHeader: {
    ...headersStyle(theme).pageHeader,
    borderRadius: "0 0 50% 50%",
    "@media (min-width: 960px)": {
      height: "70vh",
    },
    height: "40vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    position: "initial",
  },
  signUpButton: {
    fontFamily: "AvenirNextCondensed",
    fontWeight: 400,
    fontSize: "1.2rem",
    letterSpacing: `${theme.spacing(2)}px !important`,
    color: secondaryColor[0],
    width: "10rem",
    padding: `${theme.spacing(1)}px`,
    backgroundColor: "#f5f5f5",
    zIndex: "40",
    "& a": {
      color: secondaryColor[0],
    },
    "&:hover": {
      "& a": {
        color: "white",
      },
    },
  },
  womanImg: {
    height: "50%",
    position: "absolute",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    top: "28vh",
    "@media (min-width: 960px)": {
      top: "40vh",
      height: "80%",
    },
  },
  letterB: {
    transform: "scaleY(0.7) translateX(-50%)",
    zIndex: 30,
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "16rem",
    fontFamily: "HugolersStylishRegular !important",
    opacity: "0.8",
    color: bloomLogoColor,
    position: "absolute",
    top: "40vh",
    left: "50%",
    fontStyle: "normal",
    "@media (min-width: 960px)": {
      fontSize: "50rem",
      top: "76vh",
    },
  },
  bloomTextLogoContainer: {
    height: "60vh",
    marginTop: "8vh",
    "@media (min-width: 960px)": {
      height: "30vh",
      margin: "27vh 0 10vh 0",
    },
  },
  bloomLogoContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  bloomLogo: {
    height: theme.spacing(30),
    alignSelf: "center",
    "@media (min-width: 961px)": {
      height: theme.spacing(40),
    },
  },
  bloomTextcontainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  bloomText: {
    fontFamily: "Avenir",
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: "center",
    fontSize: "1.2rem",
    "@media (min-width: 961px)": {
      textAlign: "left",
    },
    margin: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
  },
  img2Container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: `${theme.spacing(5)}px`,
    marginTop: `${theme.spacing(6)}px`,
    "@media (min-width: 960px)": {
      marginBottom: `${theme.spacing(30)}px`,
    },
  },
  image2: {
    ...headersStyle(theme).pageHeader,
    "@media (min-width: 960px)": {
      height: "90vh",
    },
    height: "90vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    flexDirection: "column",
    justifyContent: "space-between",
    alignContent: "center",
  },
  bloomSubtitle: {
    fontFamily: "Avenir",
    fontSize: "2rem",
    "@media (min-width: 960px)": {
      letterSpacing: `${theme.spacing(2)}px !important`,
    },
    color: "white",
  },
  mission: {
    backgroundColor: bloomLogoColor,
    width: theme.spacing(53),
    lineHeight: "1.2",
    padding: theme.spacing(4),
    borderRadius: "10% 50% 50% 10%",
    "@media (min-width: 960px)": {
      position: "absolute",
      left: "40%",
      top: "40%",
    },
  },
  vision: {
    backgroundColor: bloomLogoColor,
    width: theme.spacing(53),
    lineHeight: "1.2",
    padding: `${theme.spacing(4)}px ${theme.spacing(5)}px`,
    borderRadius: "10% 50% 50% 10%",
    "@media (min-width: 960px)": {
      position: "absolute",
      left: "50%",
      top: "70%",
    },
  },
  missionVisionText: {
    fontFamily: "Avenir",
    textAlign: "left",
    "@media (min-width: 961px)": {
      textAlign: "left",
    },
  },
});

export default BloomPageStyle;
