import React, { useState, useContext } from "react";
import { Container, makeStyles } from "@material-ui/core";
import Page from "components/Page";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import ActionButton from "components/CustomButtons/ActionButton";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PaymentForm from "../Form";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import LoadingContext from "contexts/LoadingContext";
import PaymentService from "services/payment.service";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  selectGridItem: {
    marginTop: theme.spacing(1.25),
  },
  dateGridItem: { marginTop: theme.spacing(2.5) },
}));

function NewPaymentView() {
  const { setLoading } = useContext(LoadingContext);
  const history = useHistory();
  const payments = useStyles();
  const [userPackage, setUserPackage] = useState({
    classTypeId: 1,
    packageId: "",
    userId: "",
  });
  const [payment, setPayment] = useState({
    concept: "",
    amount: 0,
    method: "",
    date: moment().format("YYYY/MM/DD"),
  });

  const [openSnack, setOpenSnack] = React.useState(false);

  const [serverResponse, setServerResponse] = React.useState({
    status: "success",
    message: "",
  });

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const paymentData = {
        paymentInfo: payment,
        userPackageInfo: userPackage,
      };

      console.log("payment data", paymentData);
      await PaymentService.createPayment(paymentData);
      setLoading(false);
      history.push({
        pathname: "/admin/payments",
        state: { feedback: "Pago creado correctamente" },
      });
    } catch (error) {
      console.log("un error", error);
      setLoading(false);
      setServerResponse({
        status: "error",
        message: error.response.data.error,
      });
      setOpenSnack(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <Page className={payments.root} title="Nuevo Pago">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={serverResponse.status}>
            <AlertTitle>
              {serverResponse.status === "success" ? "Éxito" : "Error"}
            </AlertTitle>
            {serverResponse.message}
          </Alert>
        </Snackbar>
        <Card>
          <CardHeader title="Crear Pago"></CardHeader>
          <CardContent>
            <PaymentForm
              payment={payment}
              setPayment={setPayment}
              userPackage={userPackage}
              setUserPackage={setUserPackage}
            />
            <GridContainer justify="center">
              <GridItem xs={12}>
                <div style={{ textAlign: "center" }}>
                  <ActionButton
                    action="cancel"
                    text="Cancelar"
                    href="/admin/payments"
                  ></ActionButton>
                  <ActionButton
                    action="confirm"
                    text="Enviar"
                    func={submitForm}
                  ></ActionButton>
                </div>
              </GridItem>
            </GridContainer>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default NewPaymentView;
