import axios from "axios";

class ClassService {
  getClasses(type, limit, page) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/classes?type=${type}&limit=${limit}&page=${page}`, {
      headers: { Authorization: token },
    });
  }

  createClass(classParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.post("/api/classes/create", classParams, {
      headers: { Authorization: token },
    });
  }

  updateClass(id, classParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.put(`/api/classes/${id}`, classParams, {
      headers: { Authorization: token },
    });
  }

  getClass(id) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/classes/${id}`, {
      headers: { Authorization: token },
    });
  }

  getCalendarClasses(startDate, endDate, brand) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(
      `/api/classes/calendar/weekly?brand=${brand}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: { Authorization: token },
      }
    );
  }

  deleteClass(id) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.delete(`/api/classes/${id}`, {
      headers: { Authorization: token },
    });
  }

  signUp(lessonId, userId) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.post(
      `/api/classes/signup/${lessonId}`,
      { userId },
      {
        headers: { Authorization: token },
      }
    );
  }

  dropOut(lessonId, userId) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.post(
      `/api/classes/dropout/${lessonId}`,
      { userId },
      {
        headers: { Authorization: token },
      }
    );
  }

  attendance(lessonId, userId) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.post(
      `/api/classes/attendance/${lessonId}`,
      { qrToken: userId },
      {
        headers: { Authorization: token },
      }
    );
  }

  updateStatus(lessonId, userId, status) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.put(
      `/api/classes/status/${lessonId}`,
      { userId, status },
      {
        headers: { Authorization: token },
      }
    );
  }
}

export default new ClassService();
