import React from "react";
/*eslint-disable*/
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";
import "./CustomFooter.css";
import { List, ListItem } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  ...styles,
  footerDocLink: {
    marginLeft: "40px",
  },
  customFooter: {
    height: "12vh",
    "@media (min-width: 992px)": {
      clear: "both",
      position: "relative",
      marginTop: "-12vh",
      "& div": {
        marginRight: "3rem",
      },
    },
    backgroundColor: "black",
  },
}));

function CustomFooter() {
  const classes = useStyles();
  return (
    <Footer
      className={`${classes.customFooter} custom-footer`}
      content={
        <>
          {/* <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="http://blog.creative-tim.com/?ref=mkpr-footer-components"
                  target="_blank"
                  className={classes.footerDocLink}
                >
                  Aviso de Privacidad
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://www.creative-tim.com/presentation?ref=mkpr-footer-components"
                  target="_blank"
                  className={classes.footerDocLink}
                >
                  Términos y Condiciones
                </a>
              </ListItem>
            </List>
          </div> */}
          <div className={classes.rightLinks}>
            <ul>
              <li>
                <Button
                  href="https://youtube.com"
                  target="_blank"
                  color="primary"
                  justIcon
                  simple
                >
                  <i className="fab fa-youtube"></i>
                </Button>
              </li>
              <li>
                <Button
                  href="https://www.instagram.com/thecommunitymx/"
                  target="_blank"
                  color="primary"
                  justIcon
                  simple
                >
                  <i className="fab fa-instagram" />
                </Button>
              </li>
              <li>
                <Button
                  href="https://www.facebook.com/Calisto-100595488580541"
                  target="_blank"
                  color="primary"
                  justIcon
                  simple
                >
                  <i className="fab fa-facebook-f"></i>
                </Button>
              </li>
              <li>
                <Button
                  href="https://wa.me/5214422707194"
                  target="_blank"
                  color="primary"
                  justIcon
                  simple
                >
                  <i className="fab fa-whatsapp"></i>
                </Button>
              </li>
            </ul>
          </div>
        </>
      }
    />
  );
}

export default CustomFooter;
