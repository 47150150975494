import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  actionButton: { margin: "2px", color: "white" },
  danger: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.dark,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      background: theme.palette.warning.dark,
    },
    color: "white",
  },
  success: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      background: theme.palette.success.dark,
    },
  },
  info: {
    backgroundColor: theme.palette.info.main,
    "&:hover": {
      background: theme.palette.info.dark,
    },
  },
  formButton: {
    width: "120px",
    margin: "15px",
  },
  confirm: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      background: theme.palette.secondary.dark,
    },
  },
  cancel: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
}));

const ActionButton = ({ action, href, text, func, icon, buttonProps }) => {
  const classes = useStyles();
  return (
    <>
      {action === "delete" && (
        <Button
          variant="contained"
          className={`${classes.danger} ${classes.actionButton}`}
          onClick={func}
          {...buttonProps}
        >
          <DeleteIcon />
        </Button>
      )}
      {action === "edit" && (
        <Button
          variant="contained"
          className={`${classes.warning} ${classes.actionButton}`}
          component={RouterLink}
          to={href}
          {...buttonProps}
        >
          <EditIcon />
        </Button>
      )}
      {action === "create" && (
        <Button
          variant="contained"
          className={`${classes.success} ${classes.actionButton}`}
          component={RouterLink}
          to={href}
          {...buttonProps}
        >
          <AddIcon />
        </Button>
      )}
      {action === "confirm" && (
        <Button
          variant="contained"
          className={`${classes.formButton} ${classes.confirm} ${classes.actionButton}`}
          onClick={func}
          {...buttonProps}
        >
          {text}
        </Button>
      )}

      {action === "cancel" && (
        <Button
          variant="contained"
          className={`${classes.formButton} ${classes.cancel} ${classes.actionButton}`}
          component={RouterLink}
          to={href}
          {...buttonProps}
        >
          {text}
        </Button>
      )}
      {action === "trigger" && (
        <Button
          variant="contained"
          className={`${classes.info} ${classes.actionButton}`}
          onClick={func}
          {...buttonProps}
        >
          {icon}
        </Button>
      )}
      {action === "dismiss" && (
        <Button
          variant="contained"
          className={`${classes.cancel} ${classes.actionButton}`}
          onClick={func}
          {...buttonProps}
        >
          {text}
        </Button>
      )}
    </>
  );
};

ActionButton.propTypes = {
  action: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.string,
  func: PropTypes.func,
  icon: PropTypes.element,
  buttonProps: PropTypes.object,
};

export default ActionButton;
