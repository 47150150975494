import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import javascriptStyles from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import Grow from "@material-ui/core/Grow";
import ActionButton from "components/CustomButtons/ActionButton";
moment.locale("es");

const useStyles = makeStyles(javascriptStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

function ScanResultModal({
  scanModal,
  setScanModal,
  reservationStatus,
  scanSuccessful,
  lesson,
  user,
}) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={scanModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setScanModal(false)}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
    >
      <DialogTitle
        id="notice-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          simple
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          onClick={() => setScanModal(false)}
        >
          {" "}
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>
          {lesson?.classType?.name} -{" "}
          {moment(lesson?.date).format("DD MMM YYYY")} {lesson?.start}
        </h4>
      </DialogTitle>
      <DialogContent
        id="notice-modal-slide-description"
        className={classes.modalBody}
      >
        {scanSuccessful && (
          <>
            <div className={classes.reservationTextContainer}>
              <h3 className={classes.reservationText}>
                Reservación encontrada
              </h3>
            </div>
            <ul>
              <li>
                Usuario: {user?.name} {user?.lastName}
              </li>
              <li>Estatus de pago: {reservationStatus}</li>
            </ul>

            <Grow in={scanModal} {...{ timeout: 1000 }}>
              <CheckCircleIcon
                className={`${classes.reservationIcon} ${classes.successIcon}`}
              />
            </Grow>
          </>
        )}
        {!scanSuccessful && (
          <>
            <div className={classes.reservationTextContainer}>
              <h3>No se encontró la reservación</h3>
            </div>
            <Grow in={scanModal} {...{ timeout: 1000 }}>
              <CancelIcon
                className={`${classes.reservationIcon} ${classes.errorIcon}`}
              />
            </Grow>
          </>
        )}
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}
      >
        <ActionButton
          action="dismiss"
          text="Cerrar"
          func={() => setScanModal(false)}
        />
      </DialogActions>
    </Dialog>
  );
}

ScanResultModal.propTypes = {
  scanModal: PropTypes.bool.isRequired,
  setScanModal: PropTypes.func.isRequired,
  reservationStatus: PropTypes.string,
  scanSuccessful: PropTypes.bool,
  lesson: PropTypes.object,
  user: PropTypes.object,
};

export default ScanResultModal;
