import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import packageService from "services/package.service";
import ActionButton from "components/CustomButtons/ActionButton";
import CustomConfirmAlert from "components/CustomConfirmAlert/CustomConfirmAlert";
import SessionContext from "contexts/SessionContext";
import { isAdmin } from "utils/session-utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: "white",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  circularProgress: {
    color: theme.palette.secondary.main,
  },
}));

function UserPackages({ classType = 1 }) {
  const stylePackages = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [userPackages, setUserPackages] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [totalPackages, setTotalUserPackages] = useState(0);
  const { id: userId } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [deletingUserPackage, setDeletingUserPackage] = useState({});
  const { currentUser } = useContext(SessionContext);

  const handleDeleteItem = (userPackage) => {
    setDeletingUserPackage(userPackage);
    setOpenDialog(true);
  };

  const fetchData = useCallback(
    async function () {
      setLoading(true);
      const {
        data: { userPackages, total },
      } = await packageService.getUserPackages(classType, userId, limit, page);

      setUserPackages(userPackages);
      setTotalUserPackages(total);
      setLoading(false);
    },
    [userId, classType, limit, page]
  );

  useEffect(() => {
    fetchData();
  }, [userId, classType, limit, page, fetchData]);

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <CustomConfirmAlert
        open={openDialog}
        setOpen={setOpenDialog}
        onConfirm={async () => {
          await packageService.deleteUserPackage(deletingUserPackage.id);
          await fetchData();
        }}
        title={"Eliminar paquete"}
        errorMessage={"Error al eliminar paquete"}
        successMessage={"Paquete eliminado"}
        warningMessage={
          "¿Estás seguro de que deseas eliminar este paquete para el usuario actual? Esta acción no puede deshacerse."
        }
      />
      <GridContainer>
        <GridItem>
          <Table>
            <TableHead>
              <TableRow>
                {classType === "all" && <TableCell>Tipo</TableCell>}
                <TableCell>Nombre</TableCell>
                <TableCell>Fecha inicio</TableCell>
                <TableCell>Fecha fin</TableCell>
                <TableCell>Estatus</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userPackages &&
                userPackages.map((userPackage) => (
                  <TableRow hover key={userPackage.id}>
                    {classType === "all" && (
                      <TableCell>
                        {userPackage.package?.classType?.name}
                      </TableCell>
                    )}
                    <TableCell>{userPackage.package?.name}</TableCell>
                    <TableCell>
                      {moment(userPackage.startDate).utc().format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {moment(userPackage.endDate).utc().format("DD/MM/YYYY")}
                    </TableCell>

                    <TableCell>
                      {userPackage.status ? userPackage.status : "-"}
                    </TableCell>
                    <TableCell>
                      {isAdmin(currentUser) && (
                        <ActionButton
                          action="delete"
                          func={() => handleDeleteItem(userPackage)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </GridItem>
      </GridContainer>
      {isLoading && (
        <div className={stylePackages.progressContainer}>
          <CircularProgress
            className={stylePackages.circularProgress}
            size="100px"
          />
        </div>
      )}
      <TablePagination
        component="div"
        count={totalPackages}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </>
  );
}

UserPackages.propTypes = {
  className: PropTypes.string,
  userId: PropTypes.number,
  classType: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default UserPackages;
