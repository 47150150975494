import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, InputLabel, makeStyles } from "@material-ui/core";
import Datetime from "react-datetime";
import ActionButton from "components/CustomButtons/ActionButton";
import moment from "moment";
import GridContainer from "components/Grid/GridContainer.js";
import CustomSelect from "components/CustomSelect/CustomSelect";
import GridItem from "components/Grid/GridItem.js";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  dateGridItem: { marginTop: theme.spacing(4.5) },
  selectGridItem: {
    marginTop: theme.spacing(2),
  },
}));

const Toolbar = ({ className, query, setQuery, ...rest }) => {
  const [tempStartDate, setTempStartDate] = useState(query.startDate);
  const [tempEndDate, setTempEndDate] = useState(query.endDate);
  const [methodSearch, setMethodSearch] = useState(query.method);

  const classes = useStyles();

  const handleStartDateChange = (event) => {
    const date = typeof event === "string" ? event : event.format("YYYY-MM-DD");
    setTempStartDate(date);
    setTempEndDate("-");
  };

  const isValidEndDate = (current) => {
    return (
      current.isSameOrAfter(tempStartDate) &&
      current.isBefore(moment(tempStartDate).add(1, "month"))
    );
  };

  const handleEndDateChange = (event) => {
    const date = typeof event === "string" ? event : event.format("YYYY-MM-DD");
    setTempEndDate(date);
  };

  const handleButtonClick = () => {
    setQuery({
      startDate: tempStartDate,
      endDate: tempEndDate,
      method: methodSearch,
    });
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3} className={classes.dateGridItem}>
          <InputLabel>Fecha inicial</InputLabel>
          <Datetime
            locale="es"
            timeFormat={false}
            value={tempStartDate}
            dateFormat="DD/MM/YYYY"
            onChange={handleStartDateChange}
            closeOnSelect={true}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} className={classes.dateGridItem}>
          <InputLabel>Fecha final</InputLabel>
          <Datetime
            locale="es"
            timeFormat={false}
            value={tempEndDate}
            dateFormat="DD/MM/YYYY"
            onChange={handleEndDateChange}
            closeOnSelect={true}
            isValidDate={(current, selected) =>
              isValidEndDate(current, selected)
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} className={classes.selectGridItem}>
          <CustomSelect
            id="method"
            labelText="Tipo"
            inputProps={{
              name: "method",
              id: "method-select",
              onChange: (e) => setMethodSearch(e.target.value),
              value: methodSearch,
            }}
            options={[
              { value: "", text: "Todos" },
              { value: "Tarjeta", text: "Tarjeta" },
              { value: "Efectivo", text: "Efectivo" },
              { value: "openpay", text: "OpenPay" },
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} className={classes.dateGridItem}>
          <Box display="flex" justifyContent="flex-end">
            <ActionButton
              action="trigger"
              func={handleButtonClick}
              icon={<SearchIcon />}
            />
            <ActionButton action="create" href="/admin/payments/new" />
          </Box>
        </GridItem>
      </GridContainer>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object,
  setQuery: PropTypes.func,
};

export default Toolbar;
