const MembershipPageStyle = (theme) => ({
  root: {
    margin: `${theme.spacing(45)}px 0`,
    "@media (min-width: 1200px)": {
      margin: `${theme.spacing(45)}px ${theme.spacing(15)}px`,
    },
    "@media (min-width: 1620px)": {
      margin: theme.spacing(45),
    },
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    margin: `${theme.spacing(5)}px 0`,
  },
  brandsLogo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  calistoLogo: {
    height: "6rem",
  },
  bloomLogo: {
    height: "7rem",
  },
});

export default MembershipPageStyle;
