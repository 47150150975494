import React, { useState, useContext } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Container, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import LoadingContext from "contexts/LoadingContext";
import userService from "services/user.service";
import { Alert, AlertTitle } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import Page from "components/Page";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import ActionButton from "components/CustomButtons/ActionButton";
import SessionContext from "contexts/SessionContext";
import { isAdmin } from "utils/session-utils";
import "moment/locale/es";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  selectGridItem: {
    marginTop: "10px",
  },
  dateGridItem: { marginTop: "20px" },
}));

function EditPassword() {
  const { setLoading } = useContext(LoadingContext);
  const classes = useStyles();
  const history = useHistory();
  const { id: userId } = useParams();
  const [openSnack, setOpenSnack] = useState(false);
  const { currentUser } = useContext(SessionContext);
  const [passwordError, setPasswordError] = useState(false);
  const [editPasswordData, setEditPasswordData] = useState({
    password: "",
    passwordConfirm: "",
  });

  const [serverResponse, setServerResponse] = useState({
    status: "success",
    message: "",
  });

  const passwordCheck = () => {
    const pass = document.getElementsByName("password")[0].value;
    const passConfirm = document.getElementsByName("passwordConfirm")[0].value;
    if (passConfirm && passConfirm !== pass) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    passwordCheck();

    setEditPasswordData({
      ...editPasswordData,
      [name]: value,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await userService.updatePassword(userId, editPasswordData.password);
      setLoading(false);
      if (isAdmin(currentUser)) {
        history.push({
          pathname: `/admin/users`,
          state: { feedback: "Se ha actualizado la contraseña correctamente" },
        });
      } else {
        history.push({
          pathname: `/profile/${currentUser?.dataValues?.id}`,
          state: { feedback: "Se ha actualizado la contraseña correctamente" },
        });
      }
    } catch (error) {
      setLoading(false);
      let message =
        "Ocurrió un error al actualizar la contraseña. Por favor inténtalo de nuevo más tarde";

      setServerResponse({
        status: "error",
        message,
      });
      setOpenSnack(true);
    }
  };

  return (
    <Page className={classes.root} title="Usuarios">
      <Container maxWidth={false}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={serverResponse.status}>
            <AlertTitle>
              {serverResponse.status === "success" ? "Éxito" : "Error"}
            </AlertTitle>
            {serverResponse.message}
          </Alert>
        </Snackbar>
        <Card>
          <CardHeader title="Editar Contraseña"></CardHeader>
          <CardContent>
            <form className={classes.root} onSubmit={onFormSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Contraseña"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      required: true,
                      value: editPasswordData.password,
                      onChange: handleInputChange,
                      name: "password",
                      type: "password",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    error={passwordError}
                    errorMessage="Las contraseñas no coinciden"
                    labelText="Confirmar contraseña"
                    id="passwordConfirm"
                    formControlProps={{
                      fullWidth: true,
                      error: passwordError,
                    }}
                    inputProps={{
                      required: true,
                      value: editPasswordData.passwordConfirm,
                      onChange: handleInputChange,
                      name: "passwordConfirm",
                      type: "password",
                    }}
                  />
                </GridItem>
                <br />
                <br />
              </GridContainer>
              <GridContainer justify="center">
                <GridItem xs={12}>
                  <div style={{ textAlign: "center" }}>
                    <ActionButton
                      action="cancel"
                      text="Cancelar"
                      href={`/admin/users/${userId}`}
                    ></ActionButton>
                    <ActionButton
                      action="confirm"
                      text="Enviar"
                      buttonProps={{
                        type: "submit",
                        disabled: passwordError,
                      }}
                    ></ActionButton>
                  </div>
                </GridItem>
              </GridContainer>
            </form>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}

export default EditPassword;
