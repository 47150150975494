import axios from "axios";

class PaymentService {
  createCharge(chargeParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.post("/api/payments/create-charge", chargeParams, {
      headers: { Authorization: token },
    });
  }

  createPayment(paymentParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.post("/api/payments/create", paymentParams, {
      headers: { Authorization: token },
    });
  }

  getCharge(chargeId) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/payments/get-charge/${chargeId}`, {
      headers: { Authorization: token },
    });
  }

  getOpenPayInfo() {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/payments/openpay-info`, {
      headers: { Authorization: token },
    });
  }

  getPayments(type, limit, page, startDate, endDate, method) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(
      `/api/payments?type=${type}&limit=${limit}&page=${page}&startDate=${startDate}&endDate=${endDate}&method=${method}`,
      {
        headers: { Authorization: token },
      }
    );
  }
}

export default new PaymentService();
