import React from "react";
import Footer from "components/Footer/Footer.js";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";

function SocialFooter() {
  const useStyles = makeStyles(loginPageStyle);
  const classes = useStyles();
  return (
    <Footer
      className={classes.footer}
      content={
        <div>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <Button
                  justIcon
                  round
                  color="twitter"
                  target="_blank"
                  href="http://www.google.com/"
                >
                  <i className={classes.socials + " fab fa-twitter"} />
                </Button>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Button
                  justIcon
                  round
                  color="facebook"
                  target="_blank"
                  href="http://www.google.com/"
                >
                  <i className={classes.socials + " fab fa-facebook"} />
                </Button>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Button
                  justIcon
                  round
                  color="instagram"
                  target="_blank"
                  href="http://www.google.com/"
                >
                  <i className={classes.socials + " fab fa-instagram"} />
                </Button>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Button
                  justIcon
                  round
                  color="whatsapp"
                  target="_blank"
                  href="http://www.google.com/"
                >
                  <i className={classes.socials + " fab fa-whatsapp"} />
                </Button>
              </ListItem>
            </List>
          </div>
          <div className={classes.right}>
            &copy; {1900 + new Date().getYear()} ,{" "}
            <a
              href="https://www.gladio.com.mx"
              rel="noopener noreferrer"
              target="_blank"
            >
              Gladio
            </a>{" "}
          </div>
        </div>
      }
    />
  );
}

export default SocialFooter;
