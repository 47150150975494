import React, { useContext, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/es";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { isAdmin } from "utils/session-utils";
import SessionContext from "contexts/SessionContext";
import ActionButton from "components/CustomButtons/ActionButton";

const useStyles = makeStyles(() => ({
  selectGridItem: {
    marginTop: "10px",
  },
  dateGridItem: { marginTop: "20px" },
}));

function Form({ user, setUser, mode, submitForm }) {
  const classes = useStyles();
  const { currentUser } = useContext(SessionContext);
  const [passwordError, setPasswordError] = useState(false);

  const passwordCheck = () => {
    const pass = document.getElementsByName("password")[0].value;
    const passConfirm = document.getElementsByName("passwordConfirm")[0].value;
    if (passConfirm && passConfirm !== pass) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    if (mode === "create") {
      passwordCheck();
    }

    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleDateChange = (event) => {
    const date =
      typeof event === "string" ? event : event.format("YYYY/MM/DD/");
    setUser({
      ...user,
      birthday: date,
    });
  };

  const handleActiveChange = (event) => {
    const { checked } = event.target;

    setUser({
      ...user,
      active: checked,
    });
  };

  const roleList = [
    { value: 1, text: "Administrador" },
    { value: 2, text: "Cliente" },
  ];

  return (
    <form className={classes.root} onSubmit={submitForm}>
      <h3>Información Básica</h3>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Nombre"
            id="float"
            formControlProps={{
              fullWidth: true,
              required: true,
            }}
            inputProps={{
              value: user.name,
              onChange: handleInputChange,
              name: "name",
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Apellido"
            id="lastName"
            formControlProps={{
              fullWidth: true,
              required: true,
            }}
            inputProps={{
              value: user.lastName,
              onChange: handleInputChange,
              name: "lastName",
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4} className={classes.selectGridItem}>
          <CustomSelect
            id="sex"
            labelText="Sexo"
            inputProps={{
              name: "sex",
              id: "sex-select",
              onChange: handleInputChange,
              value: user.sex,
            }}
            options={[
              { value: "Femenino", text: "Femenino" },
              { value: "Masculino", text: "Masculino" },
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4} className={classes.dateGridItem}>
          <InputLabel className={classes.label}>Fecha de Nacimiento</InputLabel>
          <FormControl fullWidth required={true}>
            <Datetime
              locale="es"
              timeFormat={false}
              inputProps={{
                value: moment(user.birthday).format("DD/MM/YYYY"),
                required: true,
              }}
              onChange={handleDateChange}
            />
          </FormControl>
        </GridItem>
      </GridContainer>
      <br />
      <Divider variant="middle" />
      <h3>Contacto</h3>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Email"
            id="email"
            formControlProps={{
              fullWidth: true,
              required: true,
            }}
            inputProps={{
              value: user.email,
              onChange: handleInputChange,
              name: "email",
              required: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Teléfono"
            id="phone"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: user.phone,
              onChange: handleInputChange,
              name: "phone",
            }}
          />
        </GridItem>
      </GridContainer>
      <br />
      <Divider variant="middle" />
      <h3>Información Adicional</h3>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Observaciones"
            id="observations"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              rows: 3,
              name: "observations",
              onChange: handleInputChange,
              value: user.observations,
            }}
          />
        </GridItem>
      </GridContainer>
      <br />
      {isAdmin(currentUser) && (
        <>
          <Divider variant="middle" />
          <h3>Configuración</h3>
          <GridContainer alignContent="flex-end" alignItems="flex-end">
            <GridItem xs={12} sm={12} md={4} className={classes.selectGridItem}>
              <CustomSelect
                id="roleId"
                labelText="Rol"
                inputProps={{
                  name: "roleId",
                  id: "role-select",
                  onChange: handleInputChange,
                  value: user.roleId,
                  required: true,
                }}
                options={roleList}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={1}></GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <FormControlLabel
                control={
                  <Switch
                    name="active"
                    checked={user.active}
                    onChange={handleActiveChange}
                    value={user.active}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label="Activo"
              />
            </GridItem>

            {mode === "create" && (
              <>
                <GridItem xs={12} sm={12} md={4}>
                  {" "}
                  <CustomInput
                    labelText="Contraseña"
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                    }}
                    inputProps={{
                      value: user.password,
                      onChange: handleInputChange,
                      name: "password",
                      type: "password",
                      required: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  {" "}
                  <CustomInput
                    error={passwordError}
                    errorMessage="Las contraseñas no coinciden"
                    labelText="Confirmar contraseña"
                    id="passwordConfirm"
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                      error: passwordError,
                    }}
                    inputProps={{
                      value: user.passwordConfirm,
                      onChange: handleInputChange,
                      name: "passwordConfirm",
                      type: "password",
                      required: true,
                    }}
                  />
                </GridItem>
              </>
            )}
          </GridContainer>
          <br></br>
        </>
      )}
      <GridContainer justify="center">
        <GridItem xs={12}>
          <div style={{ textAlign: "center" }}>
            <ActionButton
              action="cancel"
              text="Cancelar"
              href="/admin/users"
            ></ActionButton>
            <ActionButton
              buttonProps={{
                type: "submit",
                disabled: passwordError,
              }}
              action="confirm"
              text="Enviar"
            ></ActionButton>
          </div>
        </GridItem>
      </GridContainer>
      <br />
      <br />
    </form>
  );
}

Form.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  mode: PropTypes.string,
  submitForm: PropTypes.func,
};

export default Form;
