import axios from "axios";

class InstructorService {
  getInstructors(search, limit, page) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(
      `/api/instructors?search=${search}&limit=${limit}&page=${page}`,
      {
        headers: { Authorization: token },
      }
    );
  }

  getInstructor(id) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/instructors/${id}`, {
      headers: { Authorization: token },
    });
  }

  createInstructor(instructorParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.post("/api/instructors/create", instructorParams, {
      headers: { Authorization: token },
    });
  }

  updateInstructor(id, instructorParams) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.put(`/api/instructors/${id}`, instructorParams, {
      headers: { Authorization: token },
    });
  }

  deleteInstructor(id) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.delete(`/api/instructors/${id}`, {
      headers: { Authorization: token },
    });
  }

  getInstructorsByClassType(classTypeId) {
    const token = sessionStorage.getItem("clusterToken");
    return axios.get(`/api/instructors/classtype/${classTypeId}`, {
      headers: { Authorization: token },
    });
  }
}

export default new InstructorService();
