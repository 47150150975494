import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import {
  FormControlLabel,
  FormGroup,
  makeStyles,
  Checkbox,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({}));

export default function CustomMultiSelect(props) {
  const { onChange, value: selected, options } = props;
  const classStyles = useStyles();

  return (
    <FormControl fullWidth className={classStyles.selectFormControl}>
      <FormGroup>
        {options &&
          options.map((op) => (
            <FormControlLabel
              key={op.value}
              control={
                <Checkbox
                  checked={selected.includes(op.value)}
                  onChange={onChange}
                  value={op.value}
                />
              }
              label={op.text}
            />
          ))}
      </FormGroup>
    </FormControl>
  );
}

CustomMultiSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  options: PropTypes.array,
};
