import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/es";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ClassTypeService from "services/classType.service";
import InstructorService from "services/instructor.service";
import CustomInput from "components/CustomInput/CustomInput.js";

const useStyles = makeStyles((theme) => ({
  selectGridItem: {
    marginTop: theme.spacing(1.25),
  },
  dateGridItem: { marginTop: theme.spacing(4.5) },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
}));

function Form({ lesson, setLesson }) {
  const classStyles = useStyles();
  const [classTypes, setClassTypes] = useState([]);
  const [instructors, setInstructors] = useState([]);

  useEffect(() => {
    async function fetchClassTypes() {
      const {
        data: { classTypes: classTypesRes },
      } = await ClassTypeService.getClassTypes(50, 0);

      setClassTypes(
        classTypesRes.map((classType) => {
          return { value: classType.id, text: classType.name };
        })
      );
    }
    fetchClassTypes();
  }, []);

  useEffect(() => {
    async function fetchInstructors() {
      if (lesson.classTypeId) {
        const {
          data: { instructors: instructorsRes },
        } = await InstructorService.getInstructorsByClassType(
          lesson.classTypeId
        );

        setInstructors(
          instructorsRes.map((instructor) => {
            return {
              value: instructor.id,
              text: `${instructor.firstName} ${instructor.lastName}`,
            };
          })
        );
      }
    }

    fetchInstructors();
  }, [lesson.classTypeId]);

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    setLesson({
      ...lesson,
      [name]: value,
    });
  };

  const handleDateChange = (event) => {
    const date = typeof event === "string" ? event : event.format("YYYY/MM/DD");
    setLesson({
      ...lesson,
      date,
    });
  };

  const handleTimeChange = (event, isStart) => {
    const time = moment(event).format("HH:mm");
    if (isStart) {
      setLesson({
        ...lesson,
        start: time,
      });
    } else {
      setLesson({
        ...lesson,
        end: time,
      });
    }
  };

  return (
    <>
      <h3>Detalles de clase</h3>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3} className={classStyles.selectGridItem}>
          <CustomSelect
            id="classType"
            labelText="Brand"
            inputProps={{
              name: "classTypeId",
              id: "classType-select",
              onChange: handleInputChange,
              value: lesson.classTypeId,
            }}
            options={classTypes}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} className={classStyles.selectGridItem}>
          <CustomSelect
            id="instructor"
            labelText="Instructor"
            inputProps={{
              name: "instructorId",
              id: "instructor-select",
              disabled: lesson.classTypeId === "",
              onChange: handleInputChange,
              value: lesson.instructorId,
            }}
            options={instructors}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            labelText="Tipo"
            id="subtype"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: lesson.subtype,
              onChange: handleInputChange,
              name: "subtype",
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3} className={classStyles.dateGridItem}>
          <FormControl fullWidth>
            <Datetime
              locale="es"
              timeFormat={false}
              inputProps={{
                placeholder: "Fecha",
                value: moment(lesson.date)
                  .utcOffset("GMT+00:00")
                  .format("DD/MM/YYYY"),
              }}
              onChange={handleDateChange}
            />
          </FormControl>
        </GridItem>
      </GridContainer>
      <Divider variant="middle" className={classStyles.divider} />
      <h3>Horario</h3>
      <GridContainer alignContent="flex-end" alignItems="flex-end">
        <GridItem xs={12} sm={12} md={4} className={classStyles.dateGridItem}>
          <FormControl fullWidth>
            <Datetime
              locale="es"
              dateFormat={false}
              inputProps={{
                name: "start",
                placeholder: "Inicio",
                value: moment(lesson.start, "HH:mm").format("hh:mm A"),
              }}
              initialViewDate={moment(lesson.end, "HH:mm").format("hh:mm A")}
              timeFormat="hh:mm A"
              onChange={(event) => {
                handleTimeChange(event, true);
              }}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={4} className={classStyles.dateGridItem}>
          <FormControl fullWidth>
            <Datetime
              locale="es"
              dateFormat={false}
              inputProps={{
                name: "end",
                placeholder: "Fin",
                value: moment(lesson.end, "HH:mm").format("hh:mm A"),
              }}
              initialViewDate={moment(lesson.end, "HH:mm").format("hh:mm A")}
              timeFormat="hh:mm A"
              onChange={(event) => {
                handleTimeChange(event, false);
              }}
            />
          </FormControl>
        </GridItem>
      </GridContainer>
      <Divider variant="middle" className={classStyles.divider} />
    </>
  );
}

Form.propTypes = {
  lesson: PropTypes.object,
  setLesson: PropTypes.func,
};

export default Form;
