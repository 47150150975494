/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
// @material-ui/core components
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import image from "assets/img/Calisto-163.jpg";
import { NavLink as RouterLink } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import SectionsHeader from "components/SectionsHeader";
import CustomFooter from "components/CustomFooter/CustomFooter";
import packageService from "services/package.service";
import paymentService from "services/payment.service";

export default function PaymentProcessedPage() {
  const useStyles = makeStyles(loginPageStyle);
  const location = useLocation();

  const chargeId = new URLSearchParams(window.location.search).get("id");
  const packageId = new URLSearchParams(window.location.search).get(
    "packageId"
  );
  const userId = new URLSearchParams(window.location.search).get("userId");

  const [verifyResult, setVerifyResult] = useState("Verificando...");
  const [success, setSuccess] = useState(false);
  const [openPayInfo, setOpenPayInfo] = useState();
  const openPayDomain =
    process.env.NODE_ENV === "production"
      ? "https://dashboard.openpay.mx"
      : "https://sandbox-dashboard.openpay.mx";

  useEffect(() => {
    async function fetchData() {
      try {
        const { data: chargeData } = await paymentService.getCharge(chargeId);

        if (chargeData.status !== "completed") {
          console.log("payment error", chargeData.error_message);
          throw new Error("Ocurrió un error al procesar el pago");
        }
      } catch (error) {
        const { message } = error;
        setVerifyResult(message);
        return;
      }

      try {
        const { data } = await packageService.registerUserPackage({
          userId,
          packageId,
          chargeId,
        });
        setVerifyResult(
          "Tu pago ha sido procesado correctamente y se han abonado tus clases"
        );
        setSuccess(true);
      } catch (error) {
        let errorMessage;
        ({
          response: {
            data: { error: errorMessage },
          },
        } = error);
        console.log("error", error);
        if (errorMessage.includes("SequelizeUniqueConstraintError")) {
          errorMessage = "Este pago ya fue registrado previamente";
        }
        setVerifyResult(errorMessage);
        throw error;
      }
    }
    fetchData();
  }, [chargeId]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await paymentService.getOpenPayInfo();
      setOpenPayInfo(data);
    }
    fetchData();
  }, []);

  const classes = useStyles();

  return (
    <div>
      <SectionsHeader whiteText />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader
                  color="primary"
                  signup
                  className={classes.cardHeader}
                >
                  <h3 className={classes.cardTitle}>Confirmación de Pago</h3>
                </CardHeader>
                <CardBody signup style={{ textAlign: "center" }}>
                  {success && (
                    <CheckIcon
                      style={{ fontSize: "4rem" }}
                      className={classes.checkIcon}
                    ></CheckIcon>
                  )}
                  {!success && (
                    <ErrorIcon
                      style={{ fontSize: "4rem" }}
                      className={classes.checkIcon}
                    ></ErrorIcon>
                  )}
                  <h4>
                    {verifyResult}
                    {". "}
                    {success && (
                      <span>
                        Consulta tu recibo{" "}
                        <a
                          href={`${openPayDomain}/terminal/print-payment-conditions/${openPayInfo.merchantId}/${chargeId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          aquí
                        </a>
                      </span>
                    )}
                  </h4>
                </CardBody>
                <div className={classes.textCenter}>
                  <Button
                    simple
                    color="primary"
                    size="lg"
                    type="submit"
                    component={RouterLink}
                    to={"/brands"}
                  >
                    Ir a Reservar
                  </Button>
                </div>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
}
