import React, { useContext } from "react";
// import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import { User as UserIcon } from "react-feather";
import { Menu, ExitToApp } from "@material-ui/icons";
import Logo from "components/Logo";
import SessionContext from "contexts/SessionContext";
import { NavLink as RouterLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: { backgroundColor: "black" },
  avatar: {
    width: 60,
    height: 60,
  },
  appBar: {},
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const { currentUser, setCurrentUser } = useContext(SessionContext);

  const onLogout = () => {
    sessionStorage.removeItem("storedUser");
    sessionStorage.removeItem("clusterToken");
    localStorage.removeItem("storedUser");
    localStorage.removeItem("clusterToken");
    setCurrentUser({});
    window.location.href = "/";
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <a href="/landing">
          <Logo />
        </a>
        <Box flexGrow={1} />
        <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton
            color="inherit"
            component={RouterLink}
            to={`/admin/users/${currentUser?.dataValues?.id}`}
          >
            <UserIcon />
          </IconButton>
          <IconButton color="inherit" onClick={onLogout}>
            <ExitToApp />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
