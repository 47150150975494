import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

const paymentPageStyle = (theme) => ({
  ...signupPageStyle,
  cardItem: {
    [theme.breakpoints.up("xs")]: {
      order: 2,
    },
    [theme.breakpoints.up("sm")]: {
      order: 1,
    },
  },
  summaryItem: {
    order: 2,
    [theme.breakpoints.up("xs")]: {
      order: 1,
    },
    [theme.breakpoints.up("sm")]: {
      order: 2,
    },
  },
});

export default paymentPageStyle;
